export const en = {
  brandName: "giaogiao",
  generic: {
    totalItem: "Results",
    totalPage: "Pages",
    add: "Add",
    cash: "Cash",
    card: "Card",
    thirdParty: "Third Party",
    cancel: "Cancel",
    clear: "Clear",
    continue: "Continue",
    confirm: "Confirm",
    cashier: "Cashier",
    order: "Order",
    selectPaymentMethod: "Select payment method",
    products: "Products",
    rest: "Rest",
    date: "Date",
    notApplicable: "N/A",
    takeAway: "Take away",
    exit: "Exit",
    print: "Print",
    back: "Back",
    close: "Close",
    table: "Table",
    times: "Times",
    today: "today",
    paymentThroughCashier: "PRESS HERE TO PAY AT THE CASHIER",
    paymentLater: "Eat now, pay later",
    paymentThroughWeChatPay: "PRESS HERE TO PAY WITH WECHAT PAY",
    invalidDate: "Invalid date",
    hasMissingChoices:
      "1 more dish to choose | {choices} more dishes to choose",
    press: "Press",
    toShowAllergenTab: "to see allergens",
    popular: "Popular",
    update: "Update",
    unavailable: "Unavailable",
    chooseScene: "Choose area",
    allScene: "All area",
    save: "Save",
    cover: "N. Cover",
    paid: "Paid",
    openFileBrowser: "Open the file browser",
    nickname: "Nickname",
    rescan: "Session timeout please rescan QR code",
    guests: "No. of Guests",
    payFirst:
      "If you need to place multiple orders, please do not pay in advance.",
  },
  notification: {
    networkError: "Network error, check your Internet connection.",
    serverError: "Internal server error.",
    productAddedSuccess: "Product added with success.",
    productRemoveSuccess: "Product removed with success.",
    productRemoveFail:
      "Product removal failed beacause it has been ordered once.",
    productArchivingSuccess: "Product archived with success.",
    productUnarchivingSuccess: "Product unarchived with success.",
    productUpdatedSuccess: "Product updated with success.",
    productStockChangedSuccess: "Product stock changed with success.",
    restaurantOpenSuccess: "Restaurant open with success.",
    restaurantCloseSuccess: "Restaurant close with success.",
    websiteOrderEnabledSuccess: "Website order enabled with success.",
    websiteOrderDisabledSuccess: "Website order disabled with success.",
    paymentAfterServiceEnabledSuccess:
      "Payment after service enabled with success.",
    paymentAfterServiceDisabledSuccess:
      "Payment after service disabled with success.",
    kioskEnabledSuccess: "Kiosk enabled with success.",
    kioskDisabledSuccess: "Kiosk disabled with success.",
    maxDeliveryDistanceChangeSuccess:
      "Max delivery distance changed with success.",
    coverChangeSuccess: "Cover changed with success.",
    skipDeliveryStateEnabledSuccess:
      "Skip delivery state enabled with success.",
    skipDeliveryStateDisabledSuccess:
      "Skip delivery state disabled with success.",
    customStyleChangeSuccess: "Custom style changed witch success.",
    passwordChangeSuccess: "Password changed with success.",
    passwordChangeFail: "Password change failed.",
    allergenAddedSuccess: "Allergen added with success.",
    allergenUpdatedSuccess: "Allergen updated with success.",
    allergenDeletedSuccess: "Allergen deleted with success.",
    categoryAddedSuccess: "Category added with success.",
    categoryUpdatedSuccess: "Category updated with success.",
    tableAddedSuccess: "Table added with success.",
    tableUpdatedSuccess: "Table updated with success.",
    tableRemovedSuccess: "Table removed with success.",
    variationAddedSuccess: "Variation added with success.",
    variationUpdatedSuccess: "Variation updated with success.",
    variationArchivedSuccess: "Variation archived with success.",
    variationUnarchivedSuccess: "Variation unarchived with success.",
    skipErrorOrders:
      "third party orders are failed to load. For more order information please check your console.",
  },
  error: {
    wrongPassword: "Password is incorrect.",
    fileSizeLimitExceeded: "File size limit exceeded, max { size } kb.",
  },
  kitchen: {
    noOrdersToPrepare: "No orders to prepare",
    completedOrders: "Completed Orders",
    stock: "Stock",
  },
  checkout: {
    orderReceiveNowWait:
      "You will receive your order soon, call us for any question",
    weAreWaitingForYou: "We are waiting for you!",
    whereToPay: "How would you like to pay?",
    preOrder: "Booking",
    takeAway: "Take away",
    completePaymentToCashier: "Reach the cashier to complete the payment",
    message: "Notes",
    productMessage: "Notes",
    categoryTimes: "Dishes time program",
    timeToWait: "How long would you like to wait?",
    checkoutDeliveryErrorType: {
      MISSING_STREET: "Street name is required.",
      MISSING_BUILDING_CODE: "Building code is required.",
      MISSING_POSTAL_CODE: "Postal code is required.",
      INCORRECT_POSTAL_CODE: "Postal code must be a number.",
      MISSING_FULL_NAME: "Full name is required",
      MISSING_MOBILE_PHONE: "Mobile phone is required",
      MISSING_PERSONS: "Persons number is required",
      UNSUPPORTED_ZONE: "Zone does not currently support delivery",
      MESSAGE_LENGTH_OVERFLOW: "Max 512 characters.",
    },
  },
  cashierPos: {
    activeOrders: "Active Orders",
    tables: "Tables",
    orderId: "Order Id",
    bindOrder: "Bind",
    freeTable: "Free",
    addProduct: "Add",
    select: "Select",
    toPay: "To Pay",
    ordersToPay: "To Pay",
    ordersInPreparation: "In Preparation",
    ordersInDelivery: "In Delivery",
    completedOrders: "Completed",
    confirmAfterPayment:
      "Attention! Pressing this button will emit fiscal receipt. Press only after payment confirmation.",
    maps: "Show maps",
  },
  customerBasket: {
    subtotal: "Subtotal",
    discount: "Discount",
    total: "Total",
    emptyBasket: "Your basket is empty",
  },
  productList: {
    searchBarPlaceholder: "Search",
    allergenList: "Allergen list:",
    productHasNoAllergens: "This product has no allergens.",
    description: "Description",
  },
  kiosk: {
    touchToOrder: "Touch to order",
  },
  order: {
    verifyDeliveryAddress: "Verify delivery to your address",
    suggestedSpecialRequests: ["Separate", "Take away"],
    paymentDate: "Payment date",
    deliveryDate: "Delivery date",
    origin: "Order point",
    originMap: {
      0: "Web",
      1: "Kiosk",
      2: "Cashier",
      3: "QR Table",
      4: "Third Party",
    },
    statusMap: {
      0: "To pay",
      1: "In preparation",
      2: "Delivered",
      3: "In delivery",
      4: "Closed",
    },
    paymentMethod: "Payment method",
    paymentMethodMap: {
      0: "Cash",
      1: "Card",
      2: "Third party",
      3: "Wechat Pay",
      4: "Deliveroo",
      5: "Gua Gua",
      6: "Glovo",
      7: "Uber Eats",
      8: "Phone Call",
      9: "Just Eat",
      10: "Other",
    },
    deliveryLocation: "Delivery point",
    deliveryLocationMap: {
      0: "Restaurant",
      1: "Take away",
      2: "Delivery to address",
    },
    order: "Order",
    discounts: "Discounts",
    products: "Products",
    restaurant: "Restaurant",
    total: "Total price",
    uniqueCode: "Unique Code",
    noOrdersToDeliver: "No orders to deliver",
    lastOrder: "Press here to show order details",
    stateAlert: "You will receive your order shortly.",
    orderAgain: "Order again",
    receiver: {
      address: "Address",
      name: "Name",
      mobilePhone: "Mobile Phone",
    },
    table: "Table",
    tableAbb: "Table",
    additions: "Additions",
    noOrders: "The order list is empty.",
    refresh: "Refresh",
  },
  delivery: {
    checkAddress: "Check if delivery is available for your address",
    checkLater: "Check later",
    check: "Check",
    addressTooFar:
      "We are sorry but the delivery does not reach the address indicated.",
    addressReachable: "We deliver to your area! You can order right away.",
    checkDifferentAddress: "Check another address",
    deliveryToAddress: "Delivery to address",
    pickupInRestaurant: "Pickup in restaurant",
    address: "Address",
    streetNumber: "Street N.",
    postalCode: "Postal Code",
    fullName: "Full Name",
    phoneNumber: "Phone Number",
    notes: "Notes",
    timePlaceholder: "What time will you arrive?",
    numberOfPeople: "Number of people",
    endOfDay: "We are closed today, try another date",
    pickDate: "Choose a date",
  },
  payment: {
    payNow: "Pay now",
    paidConfirmation: "I paid",
    notPaid: "You haven't paid yet",
  },
  management: {
    generic: {
      daily: "Daily",
      weekly: "Weekly",
      monthly: "Monthly",
      restaurant: "Restaurant",
      name: "Name",
      address: "Address",
      country: "Country",
      vat: "Vat",
      actions: "Actions",
      open: "Open",
      close: "Close",
      configuration: "Configuration",
      update: "Update",
      missingValue: "The value is required.",
      invalidValue: "The value is invalid.",
      origin: "Origin",
      amount: "Amount",
      total: "Total",
      unitPrice: "Unit Price",
      dailyStatistics: "Daily Statistics",
      revenue: "Revenue",
      orderOrigin: ["Website", "Kiosk", "Cashier", "QR Code", "Third Party"],
      export: "Export",
      cancel: "Cancel",
      import: "Import",
      selectAll: "Select all",
      eventManagement: "Events management",
      date: "Date",
      save: "Save",
      description: "Description",
      categories: "Categories",
      price: "Price",
      previewImages: "Photo",
      allergens: "Allergens",
      variations: "Variations",
    },
    primaryUserNavigation: {
      user: "User",
      management: "Management",
      analytics: "Analytics",
      productsAnalytics: "Products Analytics",
      orders: "Orders",
      stock: "Stock",
      content: "Content",
      products: "Products",
      media: "Media",
      categories: "Categories",
      allergens: "Allergens",
      variations: "Variations",
      tables: "Tables",
      events: "Events",
      scenes: "Areas",
    },
    userTab: {
      username: "Username",
      password: "Password",
      fullName: "Full name",
      role: "role",
      changePassword: "change password",
      oldPassword: "old password",
      newPassword: "new password",
      logout: "Log out",
      secure: "Security",
    },
    analyticsTab: {
      closedOrders: "closed orders",
      unclosedOrders: "open orders",
      revenue: "Revenue",
      totRevenue: "Tot. revenue",
      revenueByDay: "Revenue by day",
      averageOrders: "Average orders",
      averageDailyOrders: "AVG. daily orders",
      averageOrderTotal: "AVG. order total",
      averageDailyProfit: "AVERAGE DAILY PROFIT",
      averageDailyRevenue: "AVG. DAILY REVENUE",
      ordersByDay: "Orders by day",
      ordersByOrigin: "Orders by origin",
      ordersByPaymentMethod: "Orders by payment method",
      profitByPaymentMethod: "Profit by payment method",
      originChartOptions: JSON.stringify({
        labels: [
          "Website",
          "Kiosk",
          "Cashier",
          "Deliveroo",
          "Guagua",
          "Glovo",
          "Uber Eats",
          "Phone call",
          "WeChat",
          "Just Eats",
          "Other",
        ],
      }),
      paymentMethodChartOptions: JSON.stringify({
        labels: [
          "Cash",
          "Card",
          "Deliveroo",
          "Guagua",
          "Glovo",
          "Uber eats",
          "Phone call",
          "WeChat Pay",
          "Just eat",
          "Other",
        ],
      }),
    },
    restaurantManagementTab: {
      websiteOrder: "Website order",
      kioskEnabled: "Kiosk enabled",
      paymentAfterService: "Payment after service",
      skipDeliveryState: "Skip delivery state",
      maxDeliveryDistance: "Max delivery distance",
      pointOfWork: "Point of work",
      customCSS: "Custom CSS",
      saveDistance: "Save distance",
      cover: "Cover",
      saveCover: "Save cover",
    },
    restaurantVariationsTab: {
      newVariation: "New variation",
      noVariations: "This restaurant has no variations.",
      active: "Active variations",
      archived: "Archived variations",
    },
    restaurantTableManagementTab: {
      newTable: "New table",
      noTables: "This restaurant has no tables.",
      update: "Update",
      remove: "Remove",
      seats: "Seats (n. person)",
      name: "Code",
    },
    restaurantAllergenManagementTab: {
      newAllergen: "New allergen",
      noAllergens: "This restaurant has no allergens.",
    },
    restaurantCategoryManagementTab: {
      newCategory: "New category",
      noCategories: "This restaurant has no categories.",
      modifyActiveCategory: "Modify active category.",
    },
    restaurantProductManagementTab: {
      newProduct: "New product",
      noProducts: "This restaurant has no products.",
      active: "Active products",
      archived: "Archived products",
      visible: "Visible",
      indexRule:
        "Higher the number, higher the priority, the default value is in the tail of the category",
      index: "Index",
      visibility: "VISIBILITY",
      excludeFromTopSeller: "Exclude from the top seller",
      modify: "MODIFY",
      archive: "ARCHIVE",
      unarchive: "UNARCHIVE",
    },
    restaurantProductsStockManagementTab: {
      availableVolume: "Available volume",
      unavailable: "Unavailable",
    },
    restaurantOrderListTab: {
      noOrders: "This restaurant has no orders.",
    },
    restaurantProductsAnalyticsTab: {
      noOrders: "This restaurant has no orders.",
    },
    restaurantSceneManagementTab: {
      title: "Area manager",
      list: "List",
      newScene: "New area",
      name: "Name",
      configuration: "Configuration",
      category: "Category",
      product: "Product",
      fixedMenu: "Fixed menu",
      customProduct: "Custom product",
      table: "Table",
      tableMap: "Table map",
      noMaps: "This area has no map.",
      active: "Active",
      disable: "Disable",
      noScenes: "This restaurant has no areas.",
      noProducts: "This restaurant has no products.",
      noCustomProducts: "This restaurant has no custom products.",
      noFixedMenus: "This restaurant has no fixed menus.",
      modify: "Modify",
    },
  },
  homepage: {
    home: "Home",
    ourIdea: "Our idea",
    orderNow: "Order now",
    logIn: "Log in",
    findOutMore: "find out more",
    slogan: "Our products are tailored for you",
    firstSectionTitle: "Modern look. Traditional taste.",
    firstSectionParagraph:
      "GiaoGiao is a new expression of chinese fast food. We are commited in improving our customer experience through self-developed advanced technologies, while providing exceptional traditional chinese food.",
    firstSectionParagraph_1:
      'The predecessor of the giaogiao in via dei Servi was the oldest Chinese restaurant in Florence and even in the entire Tuscany region. It was founded in 1961 under the name of "荷香园" and "Fior di Loto".',
    firstSectionParagraph_2:
      "Today, 60 years later, giaogiao, a new type of Chinese food, sticks to the traditional delicious recipe of Chinese food, improves the production and delivery process, and uses more intelligent equipment and processes to rejuvenate Chinese food.",
    firstSectionParagraph_3:
      "Our vision is: To bring delicious new (smart) Chinese food all over the world.",
    firstSectionParagraph_4:
      "Our mission is: To redefine Chinese food overseas, empower Chinese food with technology, and make Chinese food a real modern and convenient food.",
    firstSectionParagraph_5: "Slogan: Every cultural city have giaogiao",
    secondSectionTitle: "Why is it called giaogiao",
    secondSectionParagraph_1:
      "We want to be the leader in overseas Chinese food!",
    secondSectionParagraph_2:
      "The Younger (Giovani) Chinese food culture communicator.",
    secondSectionParagraph_3:
      "The abbreviation of Giao comes from the following four English words",
    secondSectionParagraph_4: "Good",
    secondSectionParagraph_5: "Innovative",
    secondSectionParagraph_6: "Authentic",
    secondSectionParagraph_7: "Original",
    serviceTimesTitle: "Service times",
    serviceTimes: "11:00 - 22:00 MON-SUN",
    homeDeliveryTitle: "Home delivery",
    homeDelivery: "Up to 5km, order now to check",
    deliveryChargesTitle: "Delivery charges",
    deliveryCharges: "1€/km",
    contact: "Contact",
    addressesTitle: "Come and visit us",
    history: "History",
    present: "Present",
    future: "Future",
    vision: "Vision",
    restaurantSectionTitle: "+3 RESTAURANTS",
    restaurantSectionParagraph: "and more coming soon...",
    orderSectionTitle: "+20k ORDERS",
    orderSectionParagraph: "received through our automatic system",
  },
  restaurantList: {
    chooseRestaurant: "Choose a restaurant",
  },
  categoryTimes: {
    0: "Immediate",
    10: "10 Minutes",
    20: "20 Minutes",
    30: "30 Minutes",
  },
};
