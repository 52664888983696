<template>
    <datepicker
        :input-format="inputFormat"
        :locale="locale"
        :disabled-dates="disabledDates"
        v-model:model-value="modelValueCopy"
    />
</template>

<script>
import Datepicker from "vue3-datepicker";

export default {
    name: "V3DatePicker",
    emits: [ "update:modelValue", "input", ],
    components: {
        Datepicker,
    },
    props: {
        inputFormat: {
            type: String,
            default: "dd/MM/yyyy",
        },
        locale: {
            type: undefined,
        },
        modelValue: {
            type: Date,
            default: new Date,
        },
        disabledDates: {
            type: undefined,
        }
    },
    data () {
        return {
            modelValueCopy: this.modelValue,
        };
    },
    watch: {
        modelValueCopy (newValue) {
            this.$emit("update:modelValue", newValue);
            this.$emit("input");
        },
    },
}
</script>

<style scoped>

</style>
