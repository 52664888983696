<template>
    <div class="flex flex--100 add-product-modal-box">
        <modal-box ref="window" :close-on-background-click="false">
            <form class="flex flex--100 add-product-form">
                <div class="flex flex--100">
                    <simple-textbox placeholder="Price" v-model:model-value="price" ref="priceTextbox"/>
                </div>
                <div class="flex flex--100">
                    <simple-textbox :placeholder="$t(`management.generic.vat`)" v-model:model-value="vat" ref="vatTextbox"/>
                </div>
                <div class="flex flex--100 flex--direction-column">
                    <span class="input-descriptor-text">Preview Image</span>
                    <simple-file-upload @upload="onPreviewImageUpload" :files-limit="1"/>
                </div>
                <div class="flex flex--100">
                    <span class="input-descriptor-text">Categories</span>
                    <category-selector :categories="categories" v-model:selected="selectedCategories" :max="100"/>
                </div>
                <div class="flex flex--100">
                    <span class="input-descriptor-text">Allergens</span>
                    <allergen-selector :allergens="allergens" v-model:selected="selectedAllergens" :max="100"/>
                    <span class="input-descriptor-text">Variations</span>
                    <variation-selector :variations="variations" v-model:selected="selectedVariations" :max="100"/>
                </div>
                <!--
                <div class="flex flex--100">
                    <span class="input-descriptor-text">Images</span>
                    <media-selector :medias="images" :selected="selectedImages" v-model="selectedImages" :max="30"/>
                </div>

                <div class="flex flex--100">
                    <simple-textbox placeholder="Available Quantity" v-model="availableVolume" ref="availableVolumeTextbox"/>
                </div>
                -->
                <div class="flex flex--100" v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso">
                    <hr class="separator"/>
                    <h2>{{ requiredLocalization.name }}</h2>
                    <div class="flex flex--100">
                        <simple-textbox placeholder="Name" v-model:model-value="localizations[requiredLocalization.iso].name" ref="enNameTextbox"/>
                    </div>
                    <div class="flex flex--100">
                        <simple-textbox placeholder="Description" :is-multiline="true" v-model:model-value="localizations[requiredLocalization.iso].description"/>
                    </div>
                </div>
                <hr class="separator"/>
                <div class="flex flex--100">
                    <simple-button text="Add" @click="addProduct" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import CategorySelector from "@/components/inputs/CategorySelector";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleFileUpload from "@/components/inputs/SimpleFileUpload";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import AllergenSelector from "@/components/inputs/AllergenSelector";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";
import VariationSelector from "@/components/inputs/VariationSelector";

export default {
    name: "AddProductWindow",
    props: {
        categories: {
            type: Array,
            default: () => [],
        },
        allergens: {
            type: Array,
            default: () => [],
        },
        variations: {
            type: Array,
            default: () => [],
        },
        images: {
            type: Array,
            default: () => [],
        },
        restaurantId: {
            type: String,
        },
        closeOnProductAdd: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "product-add" ],
    components: {
        SimpleFileUpload,
        CategorySelector,
        VariationSelector,
        SimpleButton,
        SimpleTextbox,
        ModalBox,
        AllergenSelector,
    },
    data () {
        return {
            requiredLocalizations: [{
                name: "English",
                iso: "en",
            }, {
                name: "Chinese",
                iso: "zh",
            }, {
                name: "Italian",
                iso: "it",
            },],
            price: "",
            vat: "",
            availableVolume: "",
            selectedPreviewImages: [],
            selectedImages: [],
            selectedCategories: [],
            selectedAllergens: [],
            selectedVariations: [],
            localizations: {
                "en": {},
                "zh": {},
                "it": {},
            },
            isWaitingServerResponse: false,
        };
    },
    methods: {
        validateAddProductForm () {
            const priceTextbox = this.$refs.priceTextbox;
            const vatTextbox = this.$refs.vatTextbox;

            priceTextbox.clearErrors();
            vatTextbox.clearErrors();

            if (priceTextbox.validate(this.ProductAddErrorType.MISSING_PRICE, this.price.length > 0)) {
                priceTextbox.validate(this.ProductAddErrorType.INVALID_PRICE, Number.parseFloat(this.price) >= 0);
            }

            if (vatTextbox.validate(this.ProductAddErrorType.MISSING_VAT, this.vat.length > 0)) {
              vatTextbox.validate(this.ProductAddErrorType.INVALID_VAT, Number.parseFloat(this.vat) >= 0);
            }

            return (priceTextbox.errors.length + vatTextbox.errors.length) === 0;
        },

        clearProductForm () {
            this.price = "";
            this.availableVolume = "";
            this.selectedCategories = [];
            this.selectedAllergens = [];
            this.selectedVariations = [];
            this.selectedPreviewImages = [];
            this.localizations = {
                "en": {},
                "zh": {},
                "it": {},
            };
        },

        onPreviewImageUpload (id) {
            this.selectedPreviewImages.push(id);
        },

        async addProduct () {
            if (!this.validateAddProductForm() || this.isWaitingServerResponse) {
                return;
            }

            if (this.selectedCategories.length === 0) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const product = await user.addProduct({
                    price: this.price,
                    valueAddedTax: this.vat,
                    restaurantId: this.restaurantId,
                    availableVolume: this.availableVolume,
                    previewImageId: this.selectedPreviewImages[0] ?? null,
                    images: this.selectedImages,
                    categories: this.selectedCategories,
                    allergens: this.selectedAllergens,
                    variations: this.selectedVariations,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                });

                if (product) {
                    this.$emit("product-add", product);


                    if (this.closeOnProductAdd) {
                        this.$refs.window.hide();
                    }

                    this.clearProductForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.producAddedSuccess`), });
                }
                else{
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },

        ProductAddErrorType () {
            return {
              MISSING_PRICE: {
                  id: 1,
                  text: this.$t(`management.generic.missingValue`),
              },
              INVALID_PRICE: {
                  id: 2,
                  text: this.$t(`management.generic.invalidValue`),
              },
              MISSING_VAT: {
                  id: 3,
                  text: this.$t(`management.generic.missingValue`),
              },
              INVALID_VAT: {
                  id: 4,
                  text: this.$t(`management.generic.invalidValue`),
              },
            };
        },
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.add-product-modal-box {}

.add-product-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.input-descriptor-text {
    margin-bottom: 8px;

    font-weight: 400;
    font-size: 14px;
}
</style>

<style lang="scss">
.update-product-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .add-product-form .simple-textbox {
        margin: 17px 0;
        width: 40%;
    }

    .add-product-form .simple-file-upload {
        margin: 17px 0;
        width: 40%;
    }
}
</style>
