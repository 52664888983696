<template>
    <div class="flex flex--100 scene-category-modal-box">
        <modal-box ref="window" @hide="clearCategoryForm">
            <form class="flex flex--100 scene-category-form">
                <div class="flex flex--100 flex--x-align-center flex--y-align-center category-list">
                    <span class="no-categories-text" v-show="categories.length === 0">This restaurant has no categories.</span>
                    <restaurant-category
                        v-for="category in categories"
                        :key="category.id"
                        :category="category"
                        :selectable="true"
                        :is-active="activatedCategories[category.id]"
                        :selected="selectedCategories[category.id]"
                        @update:selected="onSelect(category.id)"
                    />
                </div>
                <hr class="separator"/>
                <div class="flex flex--100">
                    <h2>Index</h2>
                    <div class="flex flex--100">
                        <simple-textbox placeholder="Index" v-model:model-value="index"/>
                    </div>
                </div>
                <hr class="separator"/>
                <div class="flex flex--100">
                    <div class="flex flex--50" v-show="active">
                        <simple-button text="Activate" @click="onActivate" :is-loading="isWaitingServerResponse"/>
                    </div>
                    <div class="flex flex--50" v-show="deactivate">
                        <simple-button text="Modify" @click="onModify" :is-loading="isWaitingServerResponse"/>
                    </div>
                    <div class="flex flex--50" v-show="deactivate">
                        <simple-button text="Deactivate" @click="onDeactivate" :is-loading="isWaitingServerResponse"/>
                    </div>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import RestaurantCategory from "@/components/RestaurantCategory";
import { user } from "@/user";

/*
const ProductAddErrorType = {
    MISSING_INDEX: {
        id: 1,
        text: "Index is required.",
    },
    NEGATIVE_INDEX: {
        id: 2,
        text: "Index cannot be negative.",
    },
};
*/

export default {
    name: "SceneCategoryModalBox",
    props: {
        scene: {
            type: Object,
        },
        categories: {
            type: Array,
        },
        restaurantId: {
            type: String,
        }
    },
    emits: [ "update:scene", ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
        RestaurantCategory,
    },
    data () {
        return {
            active: false,
            deactivate: false,
            activeCategories: [],
            selectedCategories: {},
            index: "",
            isWaitingServerResponse: false,
        };
    },
    methods: {
        async load (scene) {
            this.clearCategoryForm();
            this.activeCategories = scene.categories;
        },

        validateAddCategoryForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearCategoryForm () {
            this.index = "";
            this.selectedCategories = {};
            this.active = false;
            this.deactivate = false;
        },

        onSelect (categoryId) {
            this.clearCategoryForm();
            this.selectedCategories = {};
            this.selectedCategories[categoryId] = true;

            const selected = this.activeCategories.find((category) => String(category.id) === String(categoryId));

            if (selected === undefined) {
                this.deactivate = false;
                this.active = true;
                this.index = "";
            }
            else {
                this.active = false;
                this.deactivate = true;
                this.index = selected.index.toString();
            }
        },

        async onDeactivate () {
            if (!this.activatedCategories[this.selectedCategory]) {
                return;
            }
            console.log(this.activeCategories, this.selectedCategory);
            this.activeCategories = this.activeCategories.filter((active) => String(active.id) !== String(this.selectedCategory));
            console.log(this.activeCategories, this.selectedCategory);
            await this.setActiveCategory();

            this.clearCategoryForm();
        },

        async onActivate () {
            if (this.index === "") {
                return;
            }

            const normalizedIndex = Number.parseInt(this.index);
            this.activeCategories.push({ id: this.selectedCategory, index: normalizedIndex, });

            await this.setActiveCategory();

            this.clearCategoryForm();
        },

        async onModify () {
            const normalizedIndex = Number.parseInt(this.index);

            if (!Number.isFinite(normalizedIndex)) {
                return;
            }

            const active = this.activeCategories.find((category) => String(category.id) === String(this.selectedCategory));
            active.index = normalizedIndex;

            await this.setActiveCategory();
        },

        async setActiveCategory () {
            if (!this.validateAddCategoryForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const response = await user.updateScene({
                    ...this.scene,
                    categories: this.activeCategories,
                });

                if (response) {
                    this.activeCategories = response.categories;
                    this.$emit("update:scene", response);
                }
            }
            catch (error) {
                console.log(error);
            }
            this.isWaitingServerResponse = false;
        },
    },
    computed: {
        activatedCategories () {
            const activated = [];

            for (const activeCategory of this.activeCategories) {
                activated[activeCategory.id] = true;
            }

            return activated;
        },

        window () {
            return this.$refs.window;
        },

        selectedCategory () {
            return Object.keys(this.selectedCategories)[0];
        },
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.scene-category-modal-box {}

.scene-category-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.scene-category-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .scene-category-form .simple-textbox {
        margin: 17px 0;
        width: 40%;
    }
}

.no-categories-text {
  font-size: 20px;
  font-weight: 500;
  color: rgb(80, 80, 80);
}

.category-list {
  margin: 0;
  padding: 24px;

  background-color: rgb(245, 245, 245);
  border-radius: 12px;
  box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.restaurant-category {
  margin: 12px 18px;
}
</style>
