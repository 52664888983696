<template>
    <div class="flex flex--100 restaurant-products-analytics-tab">
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center common-timeframes-box">
                <simple-button
                    :text="$t(`management.generic.daily`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="getDailyOrders()"
                />
                <simple-button
                    :text="$t(`management.generic.weekly`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="getWeeklyOrders()"
                />
                <simple-button
                    :text="$t(`management.generic.monthly`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="getMonthlyOrders()"
                />
            </div>
            <div class="flex flex--100 flex--x-align-center date-picker-box">
                <v3-date-picker
                    class="flex flex--x-align-center date-picker-input"
                    input-format="dd/MM/yyyy"
                    :locale="calendarLanguage"
                    v-model:model-value="fromDate"
                    @input="updateOrders"
                />
                <span class="title">-</span>
                <v3-date-picker
                    class="flex flex--x-align-center date-picker-input"
                    input-format="dd/MM/yyyy"
                    :locale="calendarLanguage"
                    :disabled-dates="calendarToDisabledDates"
                    v-model:model-value="toDate"
                    @input="updateOrders"
                />
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center product-list">
                <span class="no-orders-text" v-if="completedOrders.length === 0">{{$t(`management.RestaurantProductsAnalyticsTab.noOrders`)}}</span>
                <div class="restaurant-product-analytics-box" v-for="product in orderedNormalizedProductsByDailyCompletedVolume" :key="product.id">
                    <restaurant-product-analytics
                        :product="product"
                        :daily-revenue="product.dailyRevenue"
                        :origins="product.origins"
                        :daily-completed-volume="product.dailyCompletedVolume"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {server} from "@/server";
import RestaurantProductAnalytics from "@/components/RestaurantProductAnalytics";
import {OrderStatus} from "@/orders/RestaurantOrder";
import SimpleButton from "@/components/inputs/SimpleButton";
import {enUS, zhCN, it} from "date-fns/locale";
import V3DatePicker from "@/components/utilities/V3DatePicker";

const DAY = 86400000;

function getMidnightDate () {
    const date = new Date();

    date.setUTCHours(0, 0, 0, 0);

    return date;
}

export default {
    name: "RestaurantProductsAnalyticsTab",
    components: {
        RestaurantProductAnalytics,
        SimpleButton,
        V3DatePicker,
    },
    props: {
        loadOnRestaurantIdChange: {
            type: Boolean,
            default: true,
        },
    },
    data () {
        return {
            restaurantId: undefined,
            products: [],
            categories: [],
            allergens: [],
            variations: [],
            orders: [],
            restaurantPresentation: undefined,
            isWaitingServerResponseForOrders: false,
            calendarLanguageEN: enUS,
            calendarLanguageZH: zhCN,
            calendarLanguageIT: it,
            toDate: new Date(getMidnightDate().getTime() + DAY),
            fromDate: getMidnightDate(),
        }
    },
    computed: {
        normalizedProducts () {
            const normalizedProducts = [];
            const dailyProducts = this.completedOrders.flatMap((order) => order.products);
            for (const dailyProduct of dailyProducts) {
                if (!normalizedProducts.find((normalizedProduct) => normalizedProduct.id === dailyProduct.id)) {
                    const product = this.products.find((product) => product.id === dailyProduct.id);
                    if (product) {
                        product.origins = this.getProductOriginsByProductId(product.id);
                        product.orders = this.getOrderProductsByProductId(product.id);
                        product.dailyRevenue = JSON.parse(product.orders.reduce((p1, p2) =>
                            (p2.unitPrice === 0 ? 1 : p2.completedVolume)
                            * (p2.unitPrice === 0 ? this.getCustomProductPrice(p2) : p2.unitPrice)
                            + p1, 0).toFixed(2));
                        product.dailyCompletedVolume = product.orders.reduce((p1, p2) => p2.completedVolume + p1, 0);
                        product.isCustomProduct = product.orders.every((p) => p.unitPrice === 0),
                        normalizedProducts.push(product);
                    }
                }
            }
            return normalizedProducts;
        },

        completedOrders () {
            return this.orders.filter((order) => order.status === OrderStatus.CLOSED);
        },

        orderedNormalizedProductsByDailyCompletedVolume () {
            const ordered =  [...this.normalizedProducts].sort((product1, product2) => product2.dailyCompletedVolume - product1.dailyCompletedVolume);
            return ordered;
        },

        calendarToDisabledDates () {
            return {
                predicate: (date) => date < this.fromDate,
            }
        },

        calendarLanguage () {
            return this[`calendarLanguage${this.$i18n.locale.toLocaleUpperCase()}`];
        },
    },
    methods: {
        async load (restaurantId) {
            this.restaurantId = restaurantId;
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId);
            this.orders = await server.getRestaurantOrders(restaurantId);
            this.products = this.restaurantPresentation.products;
            this.categories = this.restaurantPresentation.categories;
            this.allergens = this.restaurantPresentation.allergens;
            this.variations = this.restaurantPresentation.variations;
        },

        getOrderProductsByProductId (productId) {
            return this.completedOrders.flatMap((order) => order.products).filter((product) => product.id === productId);
        },

        getOrdersByProductId (productId) {
            return this.completedOrders.filter((order) => order.products.find((product) => product.id === productId));
        },

        getProductOriginsByProductId (productId) {
            const origins = {};
            const orders = this.getOrdersByProductId(productId);
            orders.forEach((order) => {
                const product = order.products.find((product) => product.id === productId);
                if (!origins[order.origin]) {
                    origins[order.origin] = product.completedVolume;
                }
                else {
                    origins[order.origin] += product.completedVolume;
                }
            });
            return origins;
        },

        async getDailyOrders () {
            this.fromDate = getMidnightDate();
            this.toDate = new Date(this.fromDate.getTime() + 86400000);

            await this.updateOrders();
        },

        async getWeeklyOrders () {
            const date = new Date();

            date.setUTCHours(0, 0, 0, 0);

            this.fromDate = new Date(date.setUTCDate(date.getUTCDate() - date.getUTCDay() + (date.getUTCDay() === 0 ? -6 : 1)));
            this.toDate = new Date(date.setUTCDate(date.getUTCDate() - date.getUTCDay() + 7));

            await this.updateOrders();
        },

        async getMonthlyOrders () {
            const date = new Date();

            date.setUTCHours(0, 0, 0, 0);

            this.fromDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
            this.toDate = new Date((new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)).getTime() + 86400000);

            await this.updateOrders();
        },

        async updateOrders () {
            if (this.isWaitingServerResponseForOrders) {
                return false;
            }

            this.isWaitingServerResponseForOrders = true;

            this.orders = await server.getRestaurantOrdersByTimestamp({
                id: this.restaurantId,
                fromTimestamp: Date.parse(this.fromDate.toString()),
                toTimestamp: Date.parse(this.toDate.toString()),
            });

            this.isWaitingServerResponseForOrders = false;
        },

        getCustomProductPrice (product) {
            let totalPrice = 0;

            product.requestedMenus.forEach((menu) => {
                menu.products.forEach((product) => {
                    totalPrice += this.products.find((productToFind) => productToFind.id === product.id).price;
                })
            });

            return totalPrice;
        }
    },
}
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.product-list {
    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.no-orders-text {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.common-timeframes-box {
    :deep(.simple-button) {
        margin: 0;

        border-radius: 0;
        border-left: 1px solid rgb(48, 48, 48);
    }
    :deep(.simple-button:first-child) {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border-left: none;
    }
    :deep(.simple-button:last-child) {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

.date-picker-box {
    margin-top: 28px;
    padding: 0;
}

.restaurant-product-analytics-box {
    width: 40%;
    margin: 30px;
}

.title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.06rem;
    color: rgb(33, 33, 33);
}

</style>
