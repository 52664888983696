<template>
    <div class="flex flex--100 customer-basket" :class="getModifiers()">
        <span class="empty-basket-text" v-show="basketProcessor.isEmpty">{{ $t("customerBasket.emptyBasket") }}</span>
        <div class="flex flex--100 basket" v-show="!basketProcessor.isEmpty">
            <div class="flex flex--100 selected-product-list">
                <!-- <fixed-menu-list> -->
                <template v-for="fixedMenu in basketProcessor.selectedFixedMenus" :key="fixedMenu.id">
                <template v-for="(selectedProducts, i) in getFixedMenuProducts(fixedMenu.id)" :key="i">
                <div class="flex flex--100 selected-fixed-menu">
                    <div class="flex flex--100 flex--y-align-center selected-product">
                        <div class="flex flex--70 flex--y-align-center">
                            <span class="flex flex--x-align-center flex--y-align-center minus-button" @click="decreaseFixedMenuVolume(fixedMenu.id, i)">
                                <svg viewBox="0 0 100 11" xmlns="http://www.w3.org/2000/svg" class="flex minus-button__icon">
                                    <g stroke="none" stroke-width="1" fill-rule="evenodd">
                                        <rect x="0" y="0" width="100" height="11" rx="5.5"/>
                                    </g>
                                </svg>
                            </span>
                            <span class="selected-product__volume">x1</span>
                            <span class="flex flex--x-align-center flex--y-align-center edit-button" @click="changeFixedMenuChoices(fixedMenu, i)">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="flex plus-button__icon">
                                    <path d="M 19.171875 2 C 18.448125 2 17.724375 2.275625 17.171875 2.828125 L 16 4 L 20 8 L 21.171875 6.828125 C 22.275875 5.724125 22.275875 3.933125 21.171875 2.828125 C 20.619375 2.275625 19.895625 2 19.171875 2 z M 14.5 5.5 L 3 17 L 3 21 L 7 21 L 18.5 9.5 L 14.5 5.5 z"/>
                                </svg>
                            </span>
                            <!--
                            <span class="flex flex--x-align-center flex--y-align-center special-request-button">
                                <svg class="flex special-request-button__icon" viewBox="0 0 28.027 28.028">
                                    <g>
                                        <path d="M17.146,13.426l10.77-5.383c-0.4-1.682-1.91-2.947-3.71-2.947H3.823c-1.799,0-3.311,1.265-3.71,2.947l10.769,5.383C12.502,14.237,15.526,14.237,17.146,13.426z"/>
                                        <path d="M17.717,14.565c-0.996,0.499-2.311,0.772-3.703,0.772s-2.707-0.274-3.703-0.772l-2.978-1.489L0,16.743v2.367c0,2.102,1.72,3.821,3.822,3.821h20.383c2.102,0,3.822-1.72,3.822-3.821v-2.367l-7.333-3.666L17.717,14.565z"/>
                                        <polygon points="22.119,12.365 28.027,15.319 28.027,9.411"/>
                                        <polygon points="0.001,9.41 0.001,15.319 5.909,12.365"/>
                                    </g>
                                </svg>
                            </span>
                            -->
                            <span class="selected-product__name">{{ restaurantProcessor.getProductLocalizedName(fixedMenu.id) }}</span>
                        </div>
                        <div class="flex flex--30 flex--x-align-end">
                            <span class="selected-product__price">{{ normalizePriceToDisplay(fixedMenu.price) }} €</span>
                        </div>
                    </div>
                    <!-- <fixed-menu-product-list> -->
                    <div
                        class="flex flex--100 flex--y-align-center selected-product selected-product--in-fixed-menu"
                        v-for="product in selectedProducts"
                        :key="product.id"
                    >
                        <div class="flex flex--70 flex--y-align-center">
                            <!--
                            <span class="flex flex--x-align-center flex--y-align-center edit-button" @click="changeFixedMenuChoices(fixedMenu, i)">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="flex plus-button__icon">
                                    <path d="M 19.171875 2 C 18.448125 2 17.724375 2.275625 17.171875 2.828125 L 16 4 L 20 8 L 21.171875 6.828125 C 22.275875 5.724125 22.275875 3.933125 21.171875 2.828125 C 20.619375 2.275625 19.895625 2 19.171875 2 z M 14.5 5.5 L 3 17 L 3 21 L 7 21 L 18.5 9.5 L 14.5 5.5 z"/>
                                </svg>
                            </span>
                            -->
                            <span class="selected-product__volume">x1</span>
                            <!--
                            <span class="flex flex--x-align-center flex--y-align-center special-request-button special-request-button--no-margin-left">
                                <svg class="flex special-request-button__icon" viewBox="0 0 28.027 28.028">
                                    <g>
                                        <path d="M17.146,13.426l10.77-5.383c-0.4-1.682-1.91-2.947-3.71-2.947H3.823c-1.799,0-3.311,1.265-3.71,2.947l10.769,5.383C12.502,14.237,15.526,14.237,17.146,13.426z"/>
                                        <path d="M17.717,14.565c-0.996,0.499-2.311,0.772-3.703,0.772s-2.707-0.274-3.703-0.772l-2.978-1.489L0,16.743v2.367c0,2.102,1.72,3.821,3.822,3.821h20.383c2.102,0,3.822-1.72,3.822-3.821v-2.367l-7.333-3.666L17.717,14.565z"/>
                                        <polygon points="22.119,12.365 28.027,15.319 28.027,9.411"/>
                                        <polygon points="0.001,9.41 0.001,15.319 5.909,12.365"/>
                                    </g>
                                </svg>
                            </span>
                            -->
                            <span class="selected-product__name">
                                {{ restaurantProcessor.getProductLocalizedName(Number.parseInt(Object.keys(product)[0])) }}
                            </span>
                        </div>
                    </div>
                    <!-- </fixed-menu-product-list> -->
                </div>
                </template>
                </template>
                <!-- </fixed-menu-list> -->
                <!-- <custom-product-list> -->
                <template v-for="customProduct in basketProcessor.selectedCustomProducts" :key="customProduct.id">
                    <template v-for="(selectedProducts, i) in getCustomProductProducts(customProduct.id)" :key="i">
                        <div class="flex flex--100 selected-fixed-menu">
                            <div class="flex flex--100 flex--y-align-center selected-product">
                                <div class="flex flex--70 flex--y-align-center">
                            <span class="flex flex--x-align-center flex--y-align-center minus-button" @click="decreaseCustomProductVolume(customProduct.id, i)">
                                <svg viewBox="0 0 100 11" xmlns="http://www.w3.org/2000/svg" class="flex minus-button__icon">
                                    <g stroke="none" stroke-width="1" fill-rule="evenodd">
                                        <rect x="0" y="0" width="100" height="11" rx="5.5"/>
                                    </g>
                                </svg>
                            </span>
                                    <span class="selected-product__volume">x1</span>
                                    <span class="flex flex--x-align-center flex--y-align-center edit-button" @click="changeCustomProductChoices(customProduct, i)">
                                <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="flex plus-button__icon">
                                    <path d="M 19.171875 2 C 18.448125 2 17.724375 2.275625 17.171875 2.828125 L 16 4 L 20 8 L 21.171875 6.828125 C 22.275875 5.724125 22.275875 3.933125 21.171875 2.828125 C 20.619375 2.275625 19.895625 2 19.171875 2 z M 14.5 5.5 L 3 17 L 3 21 L 7 21 L 18.5 9.5 L 14.5 5.5 z"/>
                                </svg>
                            </span>
                                    <!--
                                    <span class="flex flex--x-align-center flex--y-align-center special-request-button">
                                        <svg class="flex special-request-button__icon" viewBox="0 0 28.027 28.028">
                                            <g>
                                                <path d="M17.146,13.426l10.77-5.383c-0.4-1.682-1.91-2.947-3.71-2.947H3.823c-1.799,0-3.311,1.265-3.71,2.947l10.769,5.383C12.502,14.237,15.526,14.237,17.146,13.426z"/>
                                                <path d="M17.717,14.565c-0.996,0.499-2.311,0.772-3.703,0.772s-2.707-0.274-3.703-0.772l-2.978-1.489L0,16.743v2.367c0,2.102,1.72,3.821,3.822,3.821h20.383c2.102,0,3.822-1.72,3.822-3.821v-2.367l-7.333-3.666L17.717,14.565z"/>
                                                <polygon points="22.119,12.365 28.027,15.319 28.027,9.411"/>
                                                <polygon points="0.001,9.41 0.001,15.319 5.909,12.365"/>
                                            </g>
                                        </svg>
                                    </span>
                                    -->
                                    <span class="selected-product__name">{{ restaurantProcessor.getProductLocalizedName(customProduct.id) }}</span>
                                </div>
                                <div class="flex flex--30 flex--x-align-end">
                                    <span class="selected-product__price">{{ normalizePriceToDisplay(customProductPrice(selectedProducts)) }} €</span>
                                </div>
                            </div>
                            <!-- <custom-product-product-list> -->
                            <template
                                v-for="choice in selectedProducts"
                            >
                                <div
                                    class="flex flex--100 flex--y-align-center selected-product selected-product--in-fixed-menu"
                                    v-for="product in Object.entries(choice)"
                                    :key="product[0]"
                                >
                                    <div class="flex flex--70 flex--y-align-center">
                                        <!--
                                        <span class="flex flex--x-align-center flex--y-align-center edit-button" @click="changeFixedMenuChoices(fixedMenu, i)">
                                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="flex plus-button__icon">
                                                <path d="M 19.171875 2 C 18.448125 2 17.724375 2.275625 17.171875 2.828125 L 16 4 L 20 8 L 21.171875 6.828125 C 22.275875 5.724125 22.275875 3.933125 21.171875 2.828125 C 20.619375 2.275625 19.895625 2 19.171875 2 z M 14.5 5.5 L 3 17 L 3 21 L 7 21 L 18.5 9.5 L 14.5 5.5 z"/>
                                            </svg>
                                        </span>
                                        -->
                                        <span class="selected-product__volume">x1</span>
                                        <!--
                                        <span class="flex flex--x-align-center flex--y-align-center special-request-button special-request-button--no-margin-left">
                                            <svg class="flex special-request-button__icon" viewBox="0 0 28.027 28.028">
                                                <g>
                                                    <path d="M17.146,13.426l10.77-5.383c-0.4-1.682-1.91-2.947-3.71-2.947H3.823c-1.799,0-3.311,1.265-3.71,2.947l10.769,5.383C12.502,14.237,15.526,14.237,17.146,13.426z"/>
                                                    <path d="M17.717,14.565c-0.996,0.499-2.311,0.772-3.703,0.772s-2.707-0.274-3.703-0.772l-2.978-1.489L0,16.743v2.367c0,2.102,1.72,3.821,3.822,3.821h20.383c2.102,0,3.822-1.72,3.822-3.821v-2.367l-7.333-3.666L17.717,14.565z"/>
                                                    <polygon points="22.119,12.365 28.027,15.319 28.027,9.411"/>
                                                    <polygon points="0.001,9.41 0.001,15.319 5.909,12.365"/>
                                                </g>
                                            </svg>
                                        </span>
                                        -->
                                        <span class="selected-product__name">
                                            {{ restaurantProcessor.getProductLocalizedName(Number.parseInt(product[0])) }}
                                        </span>
                                    </div>
                                    <div class="flex flex--30 flex--x-align-end">
                                        <span class="selected-product__price">
                                            {{ normalizePriceToDisplay(product[1].selectedVolume * product[1].price) }} €
                                        </span>
                                    </div>
                                </div>
                                <!-- </custom-product-product-list> -->
                            </template>
                        </div>
                    </template>
                </template>
                <!-- </custom-product-list> -->
                <!-- <base-product-list> -->
                <div class="flex flex--100 flex--y-align-center selected-product" v-for="product in basketProcessor.selectedBaseProducts" :key="product.id">
                    <div class="flex flex--70 flex--y-align-center">
                        <span class="flex flex--x-align-center flex--y-align-center minus-button" @click="decreaseProductVolume(product.id)">
                            <svg viewBox="0 0 100 11" xmlns="http://www.w3.org/2000/svg" class="flex minus-button__icon">
                                <g stroke="none" stroke-width="1" fill-rule="evenodd">
                                    <rect x="0" y="0" width="100" height="11" rx="5.5"/>
                                </g>
                            </svg>
                        </span>
                        <span class="selected-product__volume">x{{ basketProcessor.getProductSelectedVolume(product.id) }}</span>
                        <span class="flex flex--x-align-center flex--y-align-center plus-button" @click="increaseProductVolume(product.id)">
                            <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex plus-button__icon">
                                <g stroke="none" stroke-width="1" fill-rule="evenodd">
                                    <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                                    <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                                </g>
                            </svg>
                        </span>
                        <template v-if="restaurantProcessor.productHasVariations(product.id)">
                        <span class="flex flex--x-align-center flex--y-align-center edit-button" @click="showProductVariationModalBox(product)">
                            <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" class="flex plus-button__icon">
                                <path d="M 19.171875 2 C 18.448125 2 17.724375 2.275625 17.171875 2.828125 L 16 4 L 20 8 L 21.171875 6.828125 C 22.275875 5.724125 22.275875 3.933125 21.171875 2.828125 C 20.619375 2.275625 19.895625 2 19.171875 2 z M 14.5 5.5 L 3 17 L 3 21 L 7 21 L 18.5 9.5 L 14.5 5.5 z"/>
                            </svg>
                        </span>
                        </template>
                        <span class="flex flex--x-align-center flex--y-align-center special-request-button" @click="showSpecialRequestModalBox(product)">
                            <svg class="flex special-request-button__icon" viewBox="0 0 28.027 28.028">
                                <g>
                                    <path d="M17.146,13.426l10.77-5.383c-0.4-1.682-1.91-2.947-3.71-2.947H3.823c-1.799,0-3.311,1.265-3.71,2.947l10.769,5.383C12.502,14.237,15.526,14.237,17.146,13.426z"/>
                                    <path d="M17.717,14.565c-0.996,0.499-2.311,0.772-3.703,0.772s-2.707-0.274-3.703-0.772l-2.978-1.489L0,16.743v2.367c0,2.102,1.72,3.821,3.822,3.821h20.383c2.102,0,3.822-1.72,3.822-3.821v-2.367l-7.333-3.666L17.717,14.565z"/>
                                    <polygon points="22.119,12.365 28.027,15.319 28.027,9.411"/>
                                    <polygon points="0.001,9.41 0.001,15.319 5.909,12.365"/>
                                </g>
                            </svg>
                        </span>
                        <span class="selected-product__name">
                            {{ restaurantProcessor.getProductLocalizedName(product.id) }} <br> {{ this.basket.selectedProductsMessages[product.id] ? `*${this.basket.selectedProductsMessages[product.id]}` : '' }}
                        </span>
                    </div>
                    <div class="flex flex--30 flex--x-align-end">
                        <span class="selected-product__price" :class="getSelectedProductPriceModifiers(product)">
                            {{ normalizePriceToDisplay(basketProcessor.getProductSelectedVolume(product.id) * product.price) }} €
                        </span>
                        <span class="selected-product__price-variation" v-show="basketProcessor.productHasVariationsAffectingPrice(product.id)">
                            {{ normalizePriceToDisplay(basketProcessor.getSelectedProductPriceAfterVariations(product.id)) }} €
                        </span>
                    </div>
                </div>
                <!-- </base-product-list> -->
                <!-- <additions> -->
                <div class="flex flex--100 flex--y-align-center applied-addition" v-for="(addition, i) in appliedAdditions" :key="i">
                    <div class="flex flex--70">
                        <span class="applied-addition__description">
                            <template v-if="addition.description">{{ addition.description }}</template>
                            <template v-else>Addition</template>
                        </span>
                    </div>
                    <div class="flex flex--30 flex--x-align-end">
                        <span class="applied-addition__value">
                            {{ normalizePriceToDisplay(addition.value) }} €
                        </span>
                    </div>
                </div>
                <!-- </additions> -->
                <!-- <deductions> -->
                <div class="flex flex--100 flex--y-align-center applied-deduction" v-for="(deduction, i) in appliedDeductions" :key="i">
                    <div class="flex flex--70">
                        <span class="applied-deduction__description">
                            <template v-if="deduction.description">{{ deduction.description }}</template>
                            <template v-else>Deduction</template>
                        </span>
                    </div>
                    <div class="flex flex--30 flex--x-align-end">
                        <span class="applied-deduction__value">
                            {{ normalizePriceToDisplay(deduction.value) }} €
                        </span>
                    </div>
                </div>
                <!-- </deductions> -->
            </div>
            <div class="flex flex--100 summary">
                <div class="flex flex--100 flex--y-align-center subtotal">
                    <span class="flex flex--50 subtotal__text">{{ $t("customerBasket.subtotal") }}</span>
                    <span class="flex flex--50 flex--x-align-end subtotal__price">{{ normalizePriceToDisplay(basketProcessor.subtotal) }} €</span>
                </div>
                <div class="flex flex--100 flex--y-align-center discount" v-show="basketProcessor.isDiscounted">
                    <span class="flex flex--50 discount__text">{{ $t("customerBasket.discount") }}</span>
                    <span class="flex flex--50 flex--x-align-end discount__price">
                        {{ basketProcessor.discountPercentage }}% &bull; {{ normalizePriceToDisplay(basketProcessor.discount) }} €
                    </span>
                </div>
                <div class="flex flex--100 flex--y-align-center total">
                    <span class="flex flex--50 total__text">{{ $t("customerBasket.total") }}</span>
                    <div class="flex flex--50 flex--y-align-center flex--x-align-end">
                        <template v-if="showCashierControls">
                            <span class="addition-button" @click="showAdditionModalBox()">&oplus;</span>
                            <span class="deduction-button" @click="showDeductionModalBox()">&ominus;</span>
                            <span class="discount-button" @click="showDiscountModalBox()">%</span>
                        </template>
                        <span class="total__price">{{ normalizePriceToDisplay(basketProcessor.total) }} €</span>
                    </div>
                </div>
            </div>
        </div>
        <apply-discount-modal-box @confirm="setDiscounts" ref="discountModalBox"/>
        <order-addition-modal-box @confirm="setAdditions" ref="additionModalBox"/>
        <order-deduction-modal-box @confirm="setDeductions" ref="deductionModalBox"/>
        <product-variation-modal-box @confirm="setProductVariations" ref="productVariationModalBox" :variations="restaurantProcessor.variations"/>
        <add-product-special-request-modal-box @confirm="addProductSpecialRequest" ref="specialRequestModalBox"/>
        <category-times-modal-box :restaurant-presentation="restaurantPresentation" :basket="basket" @confirm="categoryTimesConfirm" ref="categoryTimesModalBox"/>
        <fixed-menu-selector-modal-box
            :restaurant-presentation="restaurantPresentation"
            @confirm="onFixedMenuInputConfirm"
            @cancel="onFixedMenuInputCancel"
            ref="fixedMenuSelectorModalBox"
        />
        <custom-product-selector-modal-box
            :restaurant-presentation="restaurantPresentation"
            @confirm="onCustomProductInputConfirm"
            @cancel="onCustomProductInputCancel"
            ref="customProductSelectorModalBox"
        />
    </div>
</template>

<script>
import FixedMenuSelectorModalBox from "@/components/modals/FixedMenuSelectorModalBox";
import OrderAdditionModalBox from "@/components/modals/OrderAdditionModalBox";
import OrderDeductionModalBox from "@/components/modals/OrderDeductionModalBox";
import ProductVariationModalBox from "@/components/modals/ProductVariationModalBox";
import AddProductSpecialRequestModalBox from "@/components/windows/AddProductSpecialRequestModalBox";
import ApplyDiscountModalBox from "@/components/modals/ApplyDiscountModalBox";
import CategoryTimesModalBox from "@/components/windows/CategoryTimesModalBox";
import { RestaurantProcessor, BasketProcessor, } from "@/utilities";
import { cloneDeep, tap, set, } from "lodash";
import CustomProductSelectorModalBox from "@/components/modals/CustomProductSelectorModalBox";

export default {
    name: "CustomerBasket",
    props: {
        restaurantPresentation: {
            type: Object,
        },
        basket: {
            type: Object,
        },
        showCashierControls: {
            type: Boolean,
            default: false,
        },
        scene: {
            type: Object,
        },
        coverPrice: {
            type: Number,
        },
    },
    emits: [ "update:basket", ],
    components: {
        OrderDeductionModalBox,
        OrderAdditionModalBox,
        ProductVariationModalBox,
        AddProductSpecialRequestModalBox,
        FixedMenuSelectorModalBox,
        ApplyDiscountModalBox,
        CustomProductSelectorModalBox,
        CategoryTimesModalBox,
    },
    data () {
        return {
            isChangingFixedMenuChoices: false,
            selectedFixedMenuId: undefined,
            changingFixedMenuChoicesIndex: undefined,

            isChangingCustomProductChoices: false,
            selectedCustomProductId: undefined,
            changingCustomProductChoicesIndex: undefined,
        };
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, })
        },

        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                basket: this.basket,
                restaurantProcessor: this.restaurantProcessor,
            });
        },

        configuration () {
            return this.restaurantProcessor.restaurantConfiguration;
        },

        appliedAdditions () {
            return this.basketProcessor.appliedAdditions;
        },

        appliedDeductions () {
            return this.basketProcessor.appliedDeductions;
        },
        // </basket>
    },
    methods: {
        customProductPrice (selectedProducts) {
            let totalPrice = 0;

            selectedProducts.forEach((choice) => {
                Object.entries(choice).forEach((e) => {
                    totalPrice += e[1].price;
                });
            });

            return totalPrice;
        },

        update (key, value) {
            this.$emit("update:basket", tap(cloneDeep(this.basket), input => set(input, key, value)));
        },

        getModifiers () {
            return {
                "customer-basket--empty": this.basketProcessor.isEmpty,
            };
        },

        getSelectedProductPriceModifiers (product) {
            return {
                "selected-product__price--variation": this.basketProcessor.productHasVariationsAffectingPrice(product.id),
            };
        },

        showDiscountModalBox () {
            this.$refs.discountModalBox.show({ appliedDiscounts: this.basket.discounts, });
        },

        showAdditionModalBox () {
            this.$refs.additionModalBox.show({ appliedAdditions: this.basket.additions, });
        },

        showDeductionModalBox () {
            this.$refs.deductionModalBox.show({ appliedDeductions: this.basket.deductions, });
        },

        showProductVariationModalBox (product) {
            this.$refs.productVariationModalBox.show({
                product,
                selectedVolume: this.basketProcessor.getProductSelectedVolume(product.id),
                selectedVariations: this.basket.selectedProductsVariations[product.id],
            });
        },

        showSpecialRequestModalBox (product) {
            this.$refs.specialRequestModalBox.show({
                product,
                specialRequest: this.basket.selectedProductsMessages[product.id] ?? "",
            });
        },

        increaseProductVolume (id) {
            this.update(`selectedProducts.${id}`, {
                ...this.basket.selectedProducts[id],
                selectedVolume: this.basketProcessor.getProductSelectedVolume(id) + 1,
            });
        },

        decreaseProductVolume (id) {
            this.update(`selectedProducts.${id}`, {
                ...this.basket.selectedProducts[id],
                selectedVolume: this.basketProcessor.getProductSelectedVolume(id) - 1,
            });
        },

        // askChoices (fixedMenu) {
        //     this.selectedFixedMenuId = fixedMenu.id;
        //
        //     this.$refs.fixedMenuSelectorModalBox.show({
        //         fixedMenu: this.restaurantProcessor.getFixedMenuById(fixedMenu.id),
        //     });
        // },

        changeFixedMenuChoices (fixedMenu, i) {
            this.isChangingFixedMenuChoices = true;
            this.selectedFixedMenuId = fixedMenu.id;
            this.changingFixedMenuChoicesIndex = i;

            this.$refs.fixedMenuSelectorModalBox.show({
                fixedMenu: this.restaurantProcessor.getFixedMenuById(fixedMenu.id),
                value: {
                    choiceIndex: 0,
                    selectedProducts: this.basket.selectedProducts[fixedMenu.id].selectedProducts[i],
                },
            });
        },

        onFixedMenuInputConfirm (value) {
            const fixedMenuId = this.selectedFixedMenuId;
            const selectedProducts = cloneDeep(this.basket.selectedProducts[fixedMenuId].selectedProducts);
            let volumeToIncrease;

            if (this.isChangingFixedMenuChoices) {
                selectedProducts[this.changingFixedMenuChoicesIndex] = value;

                this.isChangingFixedMenuChoices = false;
                this.changingFixedMenuChoicesIndex = undefined;

                volumeToIncrease = 0;
            }
            else {
                selectedProducts.push(value);

                volumeToIncrease = 1;
            }

            this.selectedFixedMenuId = undefined;

            this.update(`selectedProducts.${fixedMenuId}`, {
                selectedVolume: this.basket.selectedProducts[fixedMenuId].selectedVolume + volumeToIncrease,
                selectedProducts,
            });
        },

        onFixedMenuInputCancel () {
            if (this.isChangingFixedMenuChoices) {
                this.isChangingFixedMenuChoices = false;
                this.changingFixedMenuChoicesIndex = undefined;
            }

            this.selectedFixedMenuId = undefined;
        },

        decreaseFixedMenuVolume (fixedMenuId, i) {
            const selectedProducts = cloneDeep(this.basket.selectedProducts[fixedMenuId].selectedProducts);

            selectedProducts.splice(i, 1);

            this.update(`selectedProducts.${fixedMenuId}`, {
                selectedVolume: this.basket.selectedProducts[fixedMenuId].selectedVolume - 1,
                selectedProducts,
            });
        },

        getFixedMenuProducts (fixedMenuId) {
            return this.basket.selectedProducts[fixedMenuId].selectedProducts;
        },

        getFixedMenuProductVolume (fixedMenu, i, productId) {
            return this.basket.selectedProducts[fixedMenu.id].selectedProducts[i].find((product) => product.id === productId).selectedVolume;
        },

        changeCustomProductChoices (customProduct, i) {
            this.isChangingCustomProductChoices = true;
            this.selectedCustomProductId = customProduct.id;
            this.changingCustomProductChoicesIndex = i;

            this.$refs.customProductSelectorModalBox.show({
                customProduct: this.restaurantProcessor.getCustomProductById(customProduct.id),
                value: {
                    choiceIndex: 0,
                    selectedProducts: this.basket.selectedProducts[customProduct.id].selectedProducts[i],
                },
            });
        },

        onCustomProductInputConfirm (value) {
            const customProductId = this.selectedCustomProductId;
            const selectedProducts = cloneDeep(this.basket.selectedProducts[customProductId].selectedProducts);
            let volumeToIncrease;

            if (this.isChangingCustomProductChoices) {
                selectedProducts[this.changingCustomProductChoicesIndex] = value;

                this.isChangingCustomProductChoices = false;
                this.changingCustomProductChoicesIndex = undefined;

                volumeToIncrease = 0;
            }
            else {
                selectedProducts.push(value);

                volumeToIncrease = 1;
            }

            this.selectedCustomProductId = undefined;

            this.update(`selectedProducts.${customProductId}`, {
                selectedVolume: this.basket.selectedProducts[customProductId].selectedVolume + volumeToIncrease,
                selectedProducts,
            });
        },

        onCustomProductInputCancel () {
            if (this.isChangingCustomProductChoices) {
                this.isChangingCustomProductChoices = false;
                this.changingCustomProductChoicesIndex = undefined;
            }

            this.selectedCustomProductId = undefined;
        },

        decreaseCustomProductVolume (customProductId, i) {
            const selectedProducts = cloneDeep(this.basket.selectedProducts[customProductId].selectedProducts);

            selectedProducts.splice(i, 1);

            this.update(`selectedProducts.${customProductId}`, {
                selectedVolume: this.basket.selectedProducts[customProductId].selectedVolume - 1,
                selectedProducts,
            });
        },

        getCustomProductProducts (customProductId) {
            return this.basket.selectedProducts[customProductId].selectedProducts;
        },

        getCustomProductProductVolume (customProduct, i, productId) {
            return this.basket.selectedProducts[customProduct.id].selectedProducts[i].find((product) => product.id === productId).selectedVolume;
        },

        setDiscounts ({ appliedDiscounts, }) {
            this.update("discounts", appliedDiscounts);
        },

        setAdditions ({ appliedAdditions, }) {           
            this.update("additions", appliedAdditions);
        },

        setDeductions ({ appliedDeductions, }) {
            this.update("deductions", appliedDeductions);
        },

        setProductVariations ({ product, selectedVariations, }) {
            this.update(`selectedProductsVariations.${product.id}`, selectedVariations);
        },

        addProductSpecialRequest ({ product, specialRequest, }) {
            this.update(`selectedProductsMessages.${product.id}`, specialRequest);
        },

        updateAddition (cover) {
            console.log("update addition", cover);
            if (Number.isFinite(Number(cover))) {
                const userInputCover = Number.parseInt(cover, 10);
                console.log("update addition", userInputCover);
                let additions = this.basketProcessor.appliedAdditions;
                if (cover.length === 0) {
                    additions = additions.filter((a) => a.description !== "Coperto");
                }
                if (cover.length > 0) {
                    const coverAddition = additions.find((a) => a.description === "Coperto");
                    if (coverAddition) {
                        coverAddition.value = userInputCover * this.coverPrice;
                    }
                    else {
                        additions.push({
                            description: "Coperto",
                            value: userInputCover * this.coverPrice,
                        });
                    }
                }
                this.update("additions", additions);
            }
        },

        openCategoryTimesModalBox () {
            this.$refs.categoryTimesModalBox.show();
        },

        categoryTimesConfirm (categoryTimes) {
            this.update(`categoryTimes`, categoryTimes);
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.customer-basket {
    position: relative;

    margin: 0;
    padding: 25px;

    background-color: rgb(255, 255, 255);
}
.customer-basket.customer-basket--empty {
    @extend .flex--y-align-center;
}

.empty-basket-text {
    @extend .--unselectable;

    position: absolute;
    left: 50%;
    top: 50%;

    margin: 20px 0;
    padding: 0;

    font-size: 17px;
    font-weight: 600;
    color: rgb(160, 160, 160);

    transform: translate(-50%, -50%);
}

.basket {
    margin: 0 auto;
    padding: 0;
}

.selected-product-list {}

.selected-fixed-menu {
    & + &,
    & + .selected-product,
    & + .applied-addition,
    & + .applied-deduction {
        margin-top: 10px;
    }
}

.selected-product {
    margin: 0;
    padding: 12px 15px;

    background-color: rgb(245, 245, 245);
    border-radius: 6px;

    & + &,
    & + .applied-addition,
    & + .applied-deduction {
        margin-top: 10px;
    }

    &__volume {
        min-width: 42px;

        font-size: 16px;
        font-weight: 500;
        text-align: center;
        color: rgb(33, 33, 33);
    }

    &__name {
        margin-left: 20px;

        font-size: 16px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }

    &__price {
        position: relative;

        font-size: 16px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }
    &__price--variation {
        color: rgb(93, 93, 93);
    }
    &__price--variation::before {
        content: "";

        position: absolute;
        left: 50%;
        top: 50%;

        width: calc(100% + 10px);
        height: 2px;

        background-color: rgb(83, 83, 83);
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.012), 0 0 16px 0 rgba(0, 0, 0, 0.024);

        transform: translate(-50%, 0);
    }

    &__price-variation {
        margin-left: 12px;

        font-size: 16px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }

    &--in-fixed-menu {
        margin-left: 24px;
    }
}

.applied-addition,
.applied-deduction {
    margin: 0;
    padding: 12px 15px;

    background-color: rgb(245, 245, 245);
    border-radius: 6px;

    & + & {
        margin-top: 10px;
    }

    &__description {
        font-size: 16px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }

    &__value {
        font-size: 16px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }
}

.minus-button {
    cursor: pointer;

    width: 26px;
    height: 26px;
    background-color: rgb(87, 61, 28);
    border-radius: 50%;

    &__icon {
        width: 14px;
        fill: rgb(255, 255, 255);
    }
}

.plus-button {
    cursor: pointer;

    width: 26px;
    height: 26px;

    background-color: rgb(87, 61, 28);
    border-radius: 50%;

    &__icon {
        width: 14px;
        fill: rgb(255, 255, 255);
    }

    & + .edit-button {
        margin-left: 12px;
    }
}

.edit-button {
    cursor: pointer;

    width: 26px;
    height: 26px;

    background-color: rgb(87, 61, 28);
    border-radius: 50%;

    &__icon {
        width: 14px;
        fill: rgb(255, 255, 255);
    }
}

.special-request-button {
    cursor: pointer;

    width: 26px;
    height: 26px;
    margin-left: 12px;

    background-color: rgb(87, 61, 28);
    border-radius: 50%;

    &__icon {
        width: 14px;
        fill: rgb(255, 255, 255);
    }

    &--no-margin-left {
        margin-left: 0;
    }
}

.summary {}

.subtotal {
    margin: 16px 0 0 0;
    padding: 12px 15px;

    border-radius: 6px;

    &__text {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.03rem;
        text-transform: uppercase;
        color: rgb(33, 33, 33);
    }

    &__price {
        font-size: 16px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }
}

.discount {
    margin: 16px 0 0 0;
    padding: 12px 15px;

    border-radius: 6px;

    &__text {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.03rem;
        text-transform: uppercase;
        color: rgb(33, 33, 33);
    }

    &__price {
        font-size: 16px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }
}

.total {
    margin: 0;
    padding: 12px 15px;

    border-radius: 6px;

    &__text {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.03rem;
        text-transform: uppercase;
        color: rgb(33, 33, 33);
    }

    &__price {
        font-size: 16px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }
}

.discount-button {
    cursor: pointer;

    margin: 0 8px 0 0;
    padding: 0;

    font-size: 16px;
    font-weight: 800;
    color: rgb(3, 86, 252);
}

.addition-button {
    cursor: pointer;

    margin: 0 8px 0 0;
    padding: 0;

    font-size: 16px;
    font-weight: 800;
    color: rgb(3, 86, 252);
}

.deduction-button {
    cursor: pointer;

    margin: 0 8px 0 0;
    padding: 0;

    font-size: 16px;
    font-weight: 800;
    color: rgb(3, 86, 252);
}


</style>
