<template>
    <div class="flex restaurant-fixed-menu" @click="onClick" :class="getModifiers()">
        <div class="flex flex--100 flex--x-align-end editor-controls" v-if="showEditor">
            <simple-button text="DELETE"/>
            <simple-button text="MODIFY"/>
        </div>
        <div class="flex flex--100 flex--y-align-center restaurant-fixed-menu__box" @click="askChoices">
            <div class="flex flex--100 restaurant-fixed-menu__card">
                <div class="flex flex--100">
                    <h2 class="restaurant-fixed-menu__name">{{ localizedName }}</h2>
                </div>
                <div class="flex flex--100 flex--x-align-center flex--y-align-center preview" v-if="!lite">
                    <img class="preview__image" :src="choicesPreviewImagesUris[0]" alt=""/>
                    <img class="preview__image" :src="choicesPreviewImagesUris[1]" alt=""/>
                    <img class="preview__image" :src="choicesPreviewImagesUris[2]" alt=""/>
                </div>
                <div class="flex flex--100">
                    <span class="restaurant-fixed-menu__price">€ {{ normalizePriceToDisplay(product.price) }}</span>
                </div>
            </div>
        </div>
        <div class="flex flex--x-align-center flex--y-align-center selection-controls" v-show="isSelected && maxSelectableVolume > 1">
            <span class="selection-controls__volume">{{ selectedVolume }}</span>
            <span class="flex flex--x-align-center flex--y-align-center selection-controls__plus" @click="askChoices">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex">
                    <g stroke="none" stroke-width="1" fill-rule="evenodd">
                        <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                        <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                    </g>
                </svg>
            </span>
        </div>
        <div class="flex flex--100 analytics" v-if="showAnalytics">
            <span class="analytics__total-orders-count">Ordered {{ product.totalOrdersCount }} times.</span>
        </div>
        <fixed-menu-selector-modal-box
            :restaurant-presentation="restaurantPresentation"
            @confirm="onInputConfirm"
            @cancel="onInputCancel"
            ref="choicesModalBox"
        />
    </div>
</template>

<script>
import SimpleButton from "@/components/inputs/SimpleButton";
import FixedMenuSelectorModalBox from "@/components/modals/FixedMenuSelectorModalBox";
import { cloneDeep, tap, set, } from "lodash";

export const RestaurantProductLayout = {
    HORIZONTAL: "horizontal",
    VERTICAL: "vertical",
};

export default {
    name: "RestaurantFixedMenu",
    components: {
        FixedMenuSelectorModalBox,
        SimpleButton,
    },
    props: {
        restaurantPresentation: {
            type: Object,
        },
        product: {
            type: Object,
        },
        isSelectable: {
            type: Boolean,
            default: true,
        },
        maxSelectableVolume: {
            type: Number,
            default: 99,
        },
        modelValue: {
            type: Object,
            default: () => ({}),
        },
        lite: {
            type: Boolean,
            default: false,
        },
        showAnalytics: {
            type: Boolean,
            default: false,
        },
        showEditor: {
            type: Boolean,
            default: false,
        },
    },
    emits: [
        "update:modelValue",
        "click",
    ],
    data () {
        return {
            RestaurantProductLayout,
            isChangingChoices: false,
            changingChoicesIndex: undefined,
        };
    },
    methods: {
        getModifiers () {
            return {
                "restaurant-fixed-menu--selectable": this.isSelectable,
                "restaurant-fixed-menu--selected": this.isSelected,
            };
        },

        getLocalization (languageIso) {
            return this.product.localizations.find((localization) => localization.languageIso === languageIso);
        },

        update (key, value) {
            this.$emit("update:modelValue", tap(cloneDeep(this.modelValue), input => set(input, key, value)));
        },

        onClick (event) {
            this.$emit("click", event);
        },

        askChoices () {
            if (this.isAvailable && this.isSelectable) {
                this.$refs.choicesModalBox.show({ fixedMenu: this.product, });
            }
        },

        changeChoices (i) {
            this.isChangingChoices = true;
            this.changingChoicesIndex = i;

            this.$refs.choicesModalBox.show({
                fixedMenu: this.product,
                value: {
                    choiceIndex: 0,
                    selectedProducts: this.modelValue[i],
                },
            });
        },

        onInputConfirm (value) {
            if (this.isChangingChoices) {
                this.update(this.changingChoicesIndex, value);

                this.isChangingChoices = false;
                this.changingChoicesIndex = undefined;
            }

            const newProducts = [ ...cloneDeep(this.modelValue?.selectedProducts ?? []), value, ];

            this.$emit("update:modelValue", {
                selectedVolume: newProducts.length,
                selectedProducts: newProducts,
            });
        },

        onInputCancel () {
            if (this.isChangingChoices) {
                this.isChangingChoices = false;
                this.changingChoicesIndex = undefined;
            }
        },
    },
    computed: {
        localizedName () {
            return this.getLocalization(this.$i18n.locale)?.name ?? "";
        },

        selectedVolume () {
            return this.modelValue?.selectedProducts?.length ?? 0;
        },

        isAvailable () {
            return this.product.availableVolume === -1 || this.product.availableVolume > 0;
        },

        isSelected () {
            return this.selectedVolume > 0;
        },

        choicesPreviewImagesUris () {
            return this.product.choices.map((choice) => choice.products[0].product.previewImage.uri);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.restaurant-fixed-menu {
    position: relative;

    &__box {
        position: relative;
        margin: 0;
        padding: 0;

        background-color: rgb(254, 254, 254);
        border-radius: 6px;
        border: 3px solid rgba(0, 0, 0, 0);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    }
    &--selectable &__box {
        cursor: pointer;
    }
    &--not-available &__box {
        cursor: initial;
    }
    &--selected &__box {
        border-color: rgba(236, 186, 71, 0.8);
    }

    &__card {
        margin: 0;
        padding: 25px;
    }
    &--selected &__card {
        border-color: rgba(236, 186, 71, 0.8);
    }

    &__name {
        @extend .--unselectable;

        min-height: 40px;

        color: rgb(20, 20, 20);
        font-size: 16px;
        font-weight: 600;
    }

    &__description {
        @extend .--unselectable;

        margin-top: 5px;
        color: rgb(60, 60, 60);
        font-size: 15px;
        font-weight: 300;
    }

    &__price {
        @extend .--unselectable;

        margin-top: 8px;
        color: rgb(60, 60, 60);
        font-size: 16px;
        font-weight: 500;
    }

    &__popular {
        margin: 8px 0 0 10px;
        color: rgb(217, 118, 43);
        font-size: 16px;
        font-weight: 500;
    }
    &__popular > svg {
        width: 16px;
        margin: 0 3px 0 0;
        padding: 0;
        fill: rgb(217, 118, 43);
    }
}

.editor-controls {
    transition: 256ms opacity;

    margin: 0 0 10px 0;

    opacity: 0;
}
.restaurant-fixed-menu:hover > .editor-controls {
    opacity: 1;
}

.analytics {
    margin: 10px 0 0 0;
    padding: 0;

    &__total-orders-count {
        font-size: 12px;
        font-weight: 500;
        color: rgb(126, 126, 126);
    }
}

.editor-controls {
    :deep(.simple-button) {
        min-width: initial;
        padding: 5px 12px;

        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    }
    :deep(.simple-button .simple-button__text) {
        padding: 0;
        font-size: 11px;
    }
    :deep(.simple-button .simple-button__loading-spinner) {
        transform: translate(-50%, -50%) scale(0.14);
    }
    :deep(.simple-button + .simple-button) {
        margin-left: 6px;
    }
}

.preview {
    &__image {
        width: 24%;

        border-radius: 6px;
        box-shadow: 0 0 26px 0 rgba(0, 0, 0, 0.042), 0 0 32px 0 rgba(0, 0, 0, 0.054);

        z-index: 2;
    }
    &__image:nth-child(1) {
        position: relative;
        right: -10px;
    }
    &__image:nth-child(2) {
        width: 31%;

        z-index: 3;
    }
    &__image:nth-child(3) {
        position: relative;
        left: -10px;
    }
}

.selection-controls {
    position: absolute;
    left: 50%;
    bottom: 0;

    width: 139px; // 139px = minus button width + volume text width + plus button width
    height: 43px; // 43px = button height + 1px

    background-color: rgb(236, 186, 71);
    border-radius: 1000px;

    transform: translateX(-50%) translateY(50%);
    z-index: 10;

    &__volume {
        @extend .--unselectable;

        width: 55px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }

    &__plus {
        @extend .--unselectable;

        cursor: pointer;
        color: rgb(255, 255, 255);
        width: 42px;
        height: 42px;
        background-color: rgb(236, 186, 71);
        border-radius: 50%;
    }
    &__plus svg {
        width: 18px;
        fill: rgb(255, 255, 255);
    }
}
</style>
