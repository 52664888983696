<template>
    <div class="notification-view">
        <notification-box
            v-for="(notification, i) in notifications"
            :key="i"
            :text="notification.text"
            :appearance="notification.appearance"
        />
    </div>
</template>

<script>
import NotificationBox, {
    NotificationBoxAppearance,
} from "@/components/utilities/NotificationBox";

export let notificationCenter = null;

export default {
    name: "NotificationCenter",
    components: {
        NotificationBox,
    },
    data () {
        return {
            notifications: [],
        };
    },
    methods: {
        sendNotification (notification) {
            this.notifications.push(notification);
        },

        sendSuccessNotification (notification) {
            this.sendNotification({
                ...notification,
                appearance: NotificationBoxAppearance.SUCCESS,
            });
        },

        sendFailureNotification (notification) {
            this.sendNotification({
                ...notification,
                appearance: NotificationBoxAppearance.FAILURE,
            });
        },
    },
    created () {
        notificationCenter = this;
    },
};
</script>

<style lang="scss" scoped>
.notification-view {
    position: absolute;

    left: 50px;
    top: 50px;

    z-index: 99999999;
}
</style>

<style lang="scss">
.notification-view {
    & > .notification-box + .notification-box {
        margin-top: 25px;
    }
}
</style>
