<template>
    <div class="flex flex--100 pagination">
        <div class="flex flex--60 flex--y-align-center pagination__row">
            <div class="flex flex--100 pagination__buttons">
                <div class="pagination__button pagination__arrow" :class="{ 'disabled': modelValue === 0 }" @click="gotoPage(modelValue-1)">
                    <span>&laquo;</span>
                </div>
                <div class="pagination__button" :class="{ 'active': modelValue === 0 }" v-if="!pageRange.includes(0)" @click="gotoPage(0)">
                    <span>1</span>
                </div>
                <div class="pagination__button" v-if="!pageRange.includes(0)">
                    <span>...</span>
                </div>
                <div class="pagination__button"
                    :class="{ 'active': index === modelValue }"
                    v-for="index in pageRange"
                    :key="index"
                    @click="gotoPage(index)"
                >
                    <span>{{ index + 1 }}</span>
                </div>
                <div class="pagination__button" v-if="(!pageRange.includes(totalPage) && totalPage >= 0)">
                    <span>...</span>
                </div>
                <div
                    class="pagination__button"
                    v-if="(!pageRange.includes(totalPage) && totalPage >= 0)"
                    @click="gotoPage(totalPage)"
                    :class="{ 'active': totalPage === modelValue }"
                >
                    <span>{{ totalPage + 1 }}</span>
                </div>
                <div class="pagination__button pagination__arrow" :class="{ 'disabled': modelValue === totalPage || totalPage <= 0 }" @click="gotoPage(modelValue+1)">
                    <span>&raquo;</span>
                </div>
            </div>
        </div>
        <div class="flex flex--40 flex--x-align-end">
            <span class="pagination__text">{{ $t("generic.totalItem") }}: {{ totalItem }}</span>
            <span class="pagination__text">{{ $t("generic.totalPage") }}: {{ totalPage + 1 }}</span>
        </div>
    </div>
</template>

<script>

export default {
    name: "ManagementPagination",
    props: {
        modelValue: {
            type: Number,
            default: 0,
        },
        totalPage: {
            type: Number,
            default: 0,
        },
        totalItem: {
            type: Number,
            default: 0,
        },
    },
    emits: [ "update:modelValue" ],
    methods: {
        gotoPage (goto) {
            if (goto > this.totalPage || goto < 0) {
                return;
            }
            this.$emit("update:modelValue", goto);
        }
    },
    computed: {
        pageRange () {
            const range = [];
                let start = this.modelValue - 1 >= 0 ? this.modelValue - 1 : 0;
                const end = this.modelValue + 1 < this.totalPage ? this.modelValue + 1 : this.totalPage;
                if (end <= start) {
                    range.push(0);
                }
                else {
                    while (start <= end) {
                        range.push(start);
                        start++;
                    }
                }
            return range;
        }

    },
};
</script>

<style lang="scss" scoped>
.pagination {
    span {
        font-size: 20px;
        font-weight: 700;
    }
    &__button {
        color: black;
        float: left;
        padding: 8px 16px;
        text-decoration: none;
        border-radius: 5px;
        cursor: pointer;
    }

    &__buttons {
        margin-left: auto;
    }

    &__arrow {
        font-weight: 800;
    }

    &__button:hover:not(.active) {
        background-color: #ddd;
    }

    &__text {
        font-size: 20px;
        margin-right: 10px;
    }
}

.active {
    background-color: #3F3F3F;
    color: white;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

</style>
