<template>
    <div class="flex flex--100 update-variation-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 update-variation-form">
                <div class="flex flex--100">
                    <simple-textbox placeholder="Price" v-model:model-value="price" ref="priceTextbox" :is-disabled="variation.archived"/>
                </div>
                <hr class="separator"/>
                <div class="flex flex--100" v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso">
                    <h2>{{ requiredLocalization.name }}</h2>
                    <div class="flex flex--100">
                        <simple-textbox placeholder="Name" v-model:model-value="localizations[requiredLocalization.iso].name" ref="enNameTextbox" :is-disabled="variation.archived"/>
                    </div>
                    <hr class="separator"/>
                </div>
                <div class="flex flex--100 update-variation">
                    <simple-button v-if="!variation.archived" text="Modify" @click="updateVariation" :is-disabled="!dataChanged" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import {user} from "@/user";
import {notificationCenter} from "@/components/utilities/NotificationCenter";
// import {server} from "@/server";
// import { user } from "@/user";

/*
const ProductAddErrorType = {
    MISSING_INDEX: {
        id: 1,
        text: "Index is required.",
    },
    NEGATIVE_INDEX: {
        id: 2,
        text: "Index cannot be negative.",
    },
};
*/

export default {
    name: "UpdateVariationModalBox",
    props: {
        variation: {
            type: Object,
        },
        restaurantId: {
            type: String,
        },
        closeOnVariationUpdate: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "variation-update" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
    },
    data () {
        return {
            requiredLocalizations: [{
                name: "English",
                iso: "en",
            }, {
                name: "Chinese",
                iso: "zh",
            }, {
                name: "Italian",
                iso: "it",
            }],
            localizations: {
                "en": {},
                "zh": {},
                "it": {},
            },
            price: "",
            saved: {},
            isWaitingServerResponse: false,
        };
    },
    methods: {
        async load () {
            this.variation.localizations.forEach((localization) => {
                this.localizations[localization.languageIso] = {
                    name: localization.name,
                }
            });
            this.saved.localizations = JSON.parse(JSON.stringify(this.localizations));
            this.price = this.variation.price.toString();
            this.saved.price = this.price;
        },

        validateUpdateVariationForm () {/*
            const nameTextbox = this.$refs.nameTextbox;

            nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);

            return nameTextbox.errors.length === 0;*/return true;
        },

        clearVariationForm () {
            this.price = "";
            this.localizations = {
                "en": {},
                "zh": {},
                "it": {},
            };
        },

        async updateVariation () {
            if (!this.validateUpdateVariationForm() || this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const variation = await user.updateVariation({
                    id: this.variation.id,
                    price: this.price,
                    restaurantId: this.restaurantId,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                });

                if (variation) {
                    this.$emit("variation-update", variation);

                    if (this.closeOnVariationUpdate) {
                        this.window.hide();
                    }

                    this.clearVariationForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.variationUpdatesSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },

        dataChanged () {
            if (this.saved.price !== this.price) {
                return true;
            }
            for (const languageIso of Object.keys(this.localizations)) {
                if (this.localizations[languageIso].name !== this.saved.localizations[languageIso].name) {
                    return true;
                }
            }
            return false;
        }
    },

    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>
<style lang="scss" scoped>
.update-variation-modal-box {}

.update-variation-form {
    padding: 50px;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.update-variation-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .simple-button {
        margin: 0 50px 0 0;
    }
}
</style>
