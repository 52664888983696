<template>
    <div class="flex flex--100 order-status-view">
        <div class="table-number" v-if="tableNumber">
            <span>{{ $t("order.tableAbb") }}: {{ tableNumber }}</span>
        </div>
        <language-selector />
        <div class="flex flex--100 flex--y-align-center header" :class="getHeaderModifiers">
            <div class="flex flex--100 flex--x-align-center">
                <primary-logo :class="getPrimaryLogoModifiers"/>
            </div>
            <div class="flex flex--100 flex--x-align-center" v-show="!isUserScrolling">
                <p class="description">
                    {{
                        ordersGroupByUser.length > 0 ? $t("order.stateAlert") : $t("order.noOrders")
                    }}
                </p>
            </div>
        </div>
        <div class="flex flex--100 flex--x-align-center main" :class="getMainBoxModifiers" ref="mainBox">
            <template v-for="userOrders, uIndex in ordersGroupByUser" :key="userOrders.user">
                <span class="username">{{ userOrders.user }}</span>
                    <div class="flex flex--100 order">
                        <div class="flex flex--100">
                            <template v-if="getOrderProductsInPreparation(userOrders).length > 0">
                                <div class="flex flex--100">
                                    <ul class="flex flex--100 item-list">
                                        <li class="flex flex--100 item-list__item" v-for="(orderProduct, i) in getOrderProductsInPreparation(userOrders)"
                                            :key="orderProduct.id">
                                            <!-- <fixed-menu-list> -->
                                            <template v-if="restaurantProcessor.productIsFixedMenu(orderProduct.id)">
                                                <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus"
                                                    :key="menu.id">
                                                    <div class="flex flex--100 flex--y-align-center">
                                                        <div class="flex flex--70">
                                                            <span class="product-name">
                                                                x1 {{
                                                                restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                                }}

                                                                {{ showPrice ? `(€
                                                                ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                                + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                                i))})` : '' }}
                                                            </span>
                                                        </div>
                                                        <div class="flex flex--30 flex--x-align-end">
                                                            <span class="product-status product-status--in-preparation">
                                                                {{ $t("order.statusMap.1").toUpperCase() }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                        <div class="flex flex--100 fixed-menu-product"
                                                            v-for="product in menu.products" :key="product.id">
                                                            <div class="flex flex--100 flex--y-align-center">
                                                                <div class="flex flex--70">
                                                                    <span class="fixed-menu-product">
                                                                        x{{ product.requestedVolume }} {{
                                                                        restaurantProcessor.getProductLocalizedName(product.id)
                                                                        }}
                                                                    </span>
                                                                </div>
                                                                <div class="flex flex--30 flex--x-align-end">
                                                                    <span class="product-status"
                                                                        :class="getOrderProductStatusModifiers(product)">
                                                                        <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                            {{ $t("order.statusMap.0").toUpperCase() }}
                                                                        </template>
                                                                        <template
                                                                            v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                            {{ $t("order.statusMap.2").toUpperCase() }}
                                                                        </template>
                                                                        <template
                                                                            v-else-if="product.completedVolume === product.requestedVolume">
                                                                            {{ $t("order.statusMap.3").toUpperCase() }}
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ $t("order.statusMap.1").toUpperCase() }}
                                                                        </template>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <!-- <fixed-menu-list> -->
                                            <!-- <custom-product-list> -->
                                            <template v-else-if="restaurantProcessor.productIsCustomProduct(orderProduct.id)">
                                                <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus" :key="menu.id">
                                                    <div class="flex flex--70">
                                                        <span class="product-name">
                                                            x1 {{ restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                            (€ {{ normalizePriceToDisplay(getCustomProductMenuPrice(menu)) }})
                                                        </span>
                                                    </div>
                                                    <div class="flex flex--30 flex--x-align-end">
                                                        <span class="product-status product-status--in-preparation">
                                                            {{ $t("order.statusMap.1").toUpperCase() }}
                                                        </span>
                                                    </div>
                                                    <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                        <div
                                                            class="flex flex--100 fixed-menu-product"
                                                            v-for="product in menu.products"
                                                            :key="product.id"
                                                        >
                                                            <div class="flex flex--70">
                                                                <span class="fixed-menu-product">
                                                                    x{{ product.requestedVolume }} {{ restaurantProcessor.getProductLocalizedName(product.id) }}
                                                                    (€ {{ normalizePriceToDisplay(restaurantProcessor.getProductById(product.id).price) }})
                                                                </span>
                                                            </div>
                                                            <div class="flex flex--30 flex--x-align-end">
                                                                <span class="product-status" :class="getOrderProductStatusModifiers(product)">
                                                                    <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                        {{ $t("order.statusMap.0").toUpperCase() }}
                                                                    </template>
                                                                    <template v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                        {{ $t("order.statusMap.2").toUpperCase() }}
                                                                    </template>
                                                                    <template v-else-if="product.completedVolume === product.requestedVolume">
                                                                        {{ $t("order.statusMap.3").toUpperCase() }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ $t("order.statusMap.1").toUpperCase() }}
                                                                    </template>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <!-- <custom-product-list> -->
                                            <!-- <base-product-list> -->
                                            <template v-else-if="orderProduct.displayVolume > 0">
                                                <div class="flex flex--100 flex--y-align-center">
                                                    <div class="flex flex--70">
                                                        <span class="product-name"
                                                            v-show="orderProduct.displayVolume - getProductsNoVariations(orderProduct) > 0">
                                                            x{{ orderProduct.displayVolume - getProductsNoVariations(orderProduct) }} {{
                                                            restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                            {{ showPrice ? `(€
                                                            ${normalizePriceToDisplay(orderProduct.displayVolume - getProductsNoVariations(orderProduct) *
                                                            restaurantProcessor.getProductById(orderProduct.id).price) })` : ''
                                                            }}
                                                        </span>
                                                        <template v-for="(item, i) in orderProduct.requestedVolume">
                                                            <div class="product-name" :key="i"
                                                                v-if="orderProductVolumeIndexHasVariations(orderProduct, i)">
                                                                <span class="product-name">
                                                                    x1 {{
                                                                    restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                                    }}
                                                                    {{ getOrderProductVariationsTextByVolumeIndex(orderProduct,
                                                                    i)
                                                                    }}
                                                                    {{ showPrice ? `(€
                                                                    ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                                    + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                                    i))})` : '' }}
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <div class="flex flex--30 flex--x-align-end">
                                                        <span class="product-status product-status--in-preparation">
                                                            {{ $t("order.statusMap.1").toUpperCase() }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>
                                            <!-- </base-product-list> -->
                                        </li>
                                    </ul>
                                </div>
                            </template>
                            <template v-if="getOrderProductsInDelivery(userOrders).length > 0">
                                <div class="flex flex--100">
                                    <ul class="flex flex--100 item-list">
                                        <li class="flex flex--100 item-list__item" v-for="(orderProduct, i) in getOrderProductsInDelivery(userOrders)"
                                            :key="orderProduct.id">
                                            <!-- <fixed-menu-list> -->
                                            <template v-if="restaurantProcessor.productIsFixedMenu(orderProduct.id)">
                                                <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus"
                                                    :key="menu.id">
                                                    <div class="flex flex--100 flex--y-align-center">
                                                        <div class="flex flex--70">
                                                            <span class="product-name">
                                                                x1 {{
                                                                restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                                }}

                                                                {{ showPrice ? `(€
                                                                ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                                + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                                i))})` : '' }}
                                                            </span>
                                                        </div>
                                                        <div class="flex flex--30 flex--x-align-end">
                                                            <span class="product-status product-status--in-delivery">
                                                            {{ $t("order.statusMap.3").toUpperCase() }}
                                                        </span>
                                                        </div>
                                                    </div>
                                                    <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                        <div class="flex flex--100 fixed-menu-product"
                                                            v-for="product in menu.products" :key="product.id">
                                                            <div class="flex flex--100 flex--y-align-center">
                                                                <div class="flex flex--70">
                                                                    <span class="fixed-menu-product">
                                                                        x{{ product.requestedVolume }} {{
                                                                        restaurantProcessor.getProductLocalizedName(product.id)
                                                                        }}
                                                                    </span>
                                                                </div>
                                                                <div class="flex flex--30 flex--x-align-end">
                                                                    <span class="product-status"
                                                                        :class="getOrderProductStatusModifiers(product)">
                                                                        <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                            {{ $t("order.statusMap.0").toUpperCase() }}
                                                                        </template>
                                                                        <template
                                                                            v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                            {{ $t("order.statusMap.2").toUpperCase() }}
                                                                        </template>
                                                                        <template
                                                                            v-else-if="product.completedVolume === product.requestedVolume">
                                                                            {{ $t("order.statusMap.3").toUpperCase() }}
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ $t("order.statusMap.1").toUpperCase() }}
                                                                        </template>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <!-- <fixed-menu-list> -->
                                            <!-- <custom-product-list> -->
                                            <template v-else-if="restaurantProcessor.productIsCustomProduct(orderProduct.id)">
                                                <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus" :key="menu.id">
                                                    <div class="flex flex--70">
                                                        <span class="product-name">
                                                            x1 {{ restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                            (€ {{ normalizePriceToDisplay(getCustomProductMenuPrice(menu)) }})
                                                        </span>
                                                    </div>
                                                    <div class="flex flex--30 flex--x-align-end">
                                                        <span class="product-status product-status--in-delivery">
                                                            {{ $t("order.statusMap.3").toUpperCase() }}
                                                        </span>
                                                    </div>
                                                    <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                        <div
                                                            class="flex flex--100 fixed-menu-product"
                                                            v-for="product in menu.products"
                                                            :key="product.id"
                                                        >
                                                            <div class="flex flex--70">
                                                                <span class="fixed-menu-product">
                                                                    x{{ product.requestedVolume }} {{ restaurantProcessor.getProductLocalizedName(product.id) }}
                                                                    (€ {{ normalizePriceToDisplay(restaurantProcessor.getProductById(product.id).price) }})
                                                                </span>
                                                            </div>
                                                            <div class="flex flex--30 flex--x-align-end">
                                                                <span class="product-status" :class="getOrderProductStatusModifiers(product)">
                                                                    <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                        {{ $t("order.statusMap.0").toUpperCase() }}
                                                                    </template>
                                                                    <template v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                        {{ $t("order.statusMap.2").toUpperCase() }}
                                                                    </template>
                                                                    <template v-else-if="product.completedVolume === product.requestedVolume">
                                                                        {{ $t("order.statusMap.3").toUpperCase() }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ $t("order.statusMap.1").toUpperCase() }}
                                                                    </template>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <!-- <custom-product-list> -->
                                            <!-- <base-product-list> -->
                                            <template v-else-if="orderProduct.displayVolume > 0">
                                                <div class="flex flex--100 flex--y-align-center">
                                                    <div class="flex flex--70">
                                                        <span class="product-name"
                                                            v-show="orderProduct.displayVolume - getProductsNoVariations(orderProduct) > 0">
                                                            x{{ orderProduct.displayVolume - getProductsNoVariations(orderProduct) }} {{
                                                            restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                            {{ showPrice ? `(€
                                                            ${normalizePriceToDisplay(orderProduct.displayVolume - getProductsNoVariations(orderProduct) *
                                                            restaurantProcessor.getProductById(orderProduct.id).price) })` : ''
                                                            }}
                                                        </span>
                                                        <template v-for="(item, i) in orderProduct.requestedVolume">
                                                            <div class="product-name" :key="i"
                                                                v-if="orderProductVolumeIndexHasVariations(orderProduct, i)">
                                                                <span class="product-name">
                                                                    x1 {{
                                                                    restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                                    }}
                                                                    {{ getOrderProductVariationsTextByVolumeIndex(orderProduct,
                                                                    i)
                                                                    }}
                                                                    {{ showPrice ? `(€
                                                                    ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                                    + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                                    i))})` : '' }}
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <div class="flex flex--30 flex--x-align-end">
                                                        <span class="product-status product-status--in-delivery">
                                                            {{ $t("order.statusMap.3").toUpperCase() }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>
                                            <!-- </base-product-list> -->
                                        </li>
                                    </ul>
                                </div>
                            </template>
                            <template v-if="getOrderProductsDelivered(userOrders).length > 0">
                                <div class="flex flex--100">
                                    <ul class="flex flex--100 item-list">
                                        <li class="flex flex--100 item-list__item" v-for="(orderProduct, i) in getOrderProductsDelivered(userOrders)"
                                            :key="orderProduct.id">
                                            <!-- <fixed-menu-list> -->
                                            <template v-if="restaurantProcessor.productIsFixedMenu(orderProduct.id)">
                                                <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus"
                                                    :key="menu.id">
                                                    <div class="flex flex--100 flex--y-align-center">
                                                        <div class="flex flex--70">
                                                            <span class="product-name">
                                                                x1 {{
                                                                restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                                }}

                                                                {{ showPrice ? `(€
                                                                ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                                + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                                i))})` : '' }}
                                                            </span>
                                                        </div>
                                                        <div class="flex flex--30 flex--x-align-end">
                                                            <span class="product-status product-status--delivered">
                                                                {{ $t("order.statusMap.2").toUpperCase() }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                        <div class="flex flex--100 fixed-menu-product"
                                                            v-for="product in menu.products" :key="product.id">
                                                            <div class="flex flex--100 flex--y-align-center">
                                                                <div class="flex flex--70">
                                                                    <span class="fixed-menu-product">
                                                                        x{{ product.requestedVolume }} {{
                                                                        restaurantProcessor.getProductLocalizedName(product.id)
                                                                        }}
                                                                    </span>
                                                                </div>
                                                                <div class="flex flex--30 flex--x-align-end">
                                                                    <span class="product-status"
                                                                        :class="getOrderProductStatusModifiers(product)">
                                                                        <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                            {{ $t("order.statusMap.0").toUpperCase() }}
                                                                        </template>
                                                                        <template
                                                                            v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                            {{ $t("order.statusMap.2").toUpperCase() }}
                                                                        </template>
                                                                        <template
                                                                            v-else-if="product.completedVolume === product.requestedVolume">
                                                                            {{ $t("order.statusMap.3").toUpperCase() }}
                                                                        </template>
                                                                        <template v-else>
                                                                            {{ $t("order.statusMap.1").toUpperCase() }}
                                                                        </template>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <!-- <fixed-menu-list> -->
                                            <!-- <custom-product-list> -->
                                            <template v-else-if="restaurantProcessor.productIsCustomProduct(orderProduct.id)">
                                                <div class="flex flex--100" v-for="menu in orderProduct.requestedMenus" :key="menu.id">
                                                    <div class="flex flex--70">
                                                        <span class="product-name">
                                                            x1 {{ restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                            (€ {{ normalizePriceToDisplay(getCustomProductMenuPrice(menu)) }})
                                                        </span>
                                                    </div>
                                                    <div class="flex flex--30 flex--x-align-end">
                                                        <span class="product-status product-status--delivered">
                                                            {{ $t("order.statusMap.2").toUpperCase() }}
                                                        </span>
                                                    </div>
                                                    <div class="flex flex--100 flex--direction-column fixed-menu-product-list">
                                                        <div
                                                            class="flex flex--100 fixed-menu-product"
                                                            v-for="product in menu.products"
                                                            :key="product.id"
                                                        >
                                                            <div class="flex flex--70">
                                                                <span class="fixed-menu-product">
                                                                    x{{ product.requestedVolume }} {{ restaurantProcessor.getProductLocalizedName(product.id) }}
                                                                    (€ {{ normalizePriceToDisplay(restaurantProcessor.getProductById(product.id).price) }})
                                                                </span>
                                                            </div>
                                                            <div class="flex flex--30 flex--x-align-end">
                                                                <span class="product-status" :class="getOrderProductStatusModifiers(product)">
                                                                    <template v-if="orderProduct.status === OrderStatus.TO_PAY">
                                                                        {{ $t("order.statusMap.0").toUpperCase() }}
                                                                    </template>
                                                                    <template v-else-if="product.deliveredVolume === product.requestedVolume">
                                                                        {{ $t("order.statusMap.2").toUpperCase() }}
                                                                    </template>
                                                                    <template v-else-if="product.completedVolume === product.requestedVolume">
                                                                        {{ $t("order.statusMap.3").toUpperCase() }}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ $t("order.statusMap.1").toUpperCase() }}
                                                                    </template>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </template>
                                            <!-- <custom-product-list> -->
                                            <!-- <base-product-list> -->
                                            <template v-else-if="orderProduct.displayVolume > 0">
                                                <div class="flex flex--100 flex--y-align-center">
                                                    <div class="flex flex--70">
                                                        <span class="product-name"
                                                            v-show="orderProduct.displayVolume - getProductsNoVariations(orderProduct) > 0">
                                                            x{{ orderProduct.displayVolume - getProductsNoVariations(orderProduct) }} {{
                                                            restaurantProcessor.getProductLocalizedName(orderProduct.id) }}
                                                            {{ showPrice ? `(€
                                                            ${normalizePriceToDisplay(orderProduct.displayVolume - getProductsNoVariations(orderProduct) *
                                                            restaurantProcessor.getProductById(orderProduct.id).price) })` : ''
                                                            }}
                                                        </span>
                                                        <template v-for="(item, i) in orderProduct.requestedVolume">
                                                            <div class="product-name" :key="i"
                                                                v-if="orderProductVolumeIndexHasVariations(orderProduct, i)">
                                                                <span class="product-name">
                                                                    x1 {{
                                                                    restaurantProcessor.getProductLocalizedName(orderProduct.id)
                                                                    }}
                                                                    {{ getOrderProductVariationsTextByVolumeIndex(orderProduct,
                                                                    i)
                                                                    }}
                                                                    {{ showPrice ? `(€
                                                                    ${normalizePriceToDisplay(restaurantProcessor.getProductById(orderProduct.id).price
                                                                    + getOrderProductVariationsPriceByVolumeIndex(orderProduct,
                                                                    i))})` : '' }}
                                                                </span>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <div class="flex flex--30 flex--x-align-end">
                                                        <span class="product-status product-status--delivered">
                                                            {{ $t("order.statusMap.2").toUpperCase() }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>
                                            <!-- </base-product-list> -->
                                        </li>
                                    </ul>
                                </div>
                            </template>
                        </div>
                    </div>
                <hr class="separator">
                <hr v-if='uIndex === ordersGroupByUser.length - 1' class="separator-end">
            </template>
        </div>
        <div class="flex flex--100 btn-refresh">
            <simple-button :is-loading="isWaitingServerResponse" @click="refresh" :text="$t(`order.refresh`)"></simple-button>
        </div>
        <div class="flex flex--100 btn-order">
            <simple-button @click="placeOrder" :text="$t(`order.orderAgain`)"></simple-button>
        </div>
    </div>
</template>

<script>
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import { server } from "@/server";
import SimpleButton from '@/components/inputs/SimpleButton.vue';
// import { isMobile } from "@/main";
import { MobileViewportSimulator, } from "@/utilities";
import debounce from "lodash/debounce";
import LanguageSelector from "@/components/inputs/LanguageSelector";
import { OrderStatus, } from "@/orders/RestaurantOrder";
import { RestaurantProcessor, } from "@/utilities";

const preloadedData = {};

let tableNumber = null;
let nickname = null;
let scene = null;

export default {
    async beforeRouteEnter (to, from, next) {
        const qr = JSON.parse(window.localStorage.getItem("FortuneRMS::QR"));
        if (qr) {
            tableNumber = qr.tableNumber;
            nickname = qr.nickname;
        }
        else {
            next({ name: "MobileHomeView", });
        }

        preloadedData.restaurantPresentation = await server.getRestaurantPresentation(6);
        const scenes = preloadedData.restaurantPresentation.scenes;
        const tables = preloadedData.restaurantPresentation.tables;
        scenes.forEach((s) => {
            s.maps.forEach((m) => {
                m.tables = m.tables.map((tableId) => {
                    return tables.find((t) => t.id === tableId);
                });
            });
        });
        scene = scenes.find((s) => s.maps.findIndex((m) => m.tables.findIndex((t) => t.name === tableNumber) !== -1) !== -1);
        if (!scene) {
            next({ name: "MobileHomeView", });
        }
        preloadedData.orders = tables.find((t) => t.name === tableNumber).orders;

        next();
    },

    components: {
        PrimaryLogo,
        SimpleButton,
        LanguageSelector,
    },
    name: "MobileUserOrdersView",
    data () {
        return {
            isWaitingServerResponse: false,
            isUserScrolling: false,
            showPrice: false,
            tableNumber,
            nickname,
            scene,
            loadOrders: [],
        };
    },
    methods: {
        async placeOrder() {
            const path = "/place-order";
            this.$router.push({ path, });
        },
        handleScroll () {
            this.isUserScrolling = this.$refs.mainBox.scrollTop > 10;
        },

        getOrderProductStatusModifiers (orderProduct) {
            if (orderProduct.status === OrderStatus.TO_PAY) {
                return {};
            }

            if (orderProduct.requestedVolume === orderProduct.deliveredVolume) {
                return {
                    "product-status--delivered": true,
                };
            }
            else if (orderProduct.requestedVolume === orderProduct.completedVolume) {
                return {
                    "product-status--in-delivery": true,
                };
            }
            else {
                return {
                    "product-status--in-preparation": true,
                };
            }
        },

        getOrderProductVariationsPriceByVolumeIndex (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).reduce(
                (total, variation) => total += this.restaurantProcessor.getVariationById(variation.id).price, 0
            );
        },

        getCustomProductPrice (product) {
            let total = 0;
            product.requestedMenus.forEach((menu) => {
                total += this.getCustomProductMenuPrice(menu);
            });

            return total;
        },

        getCustomProductMenuPrice (menu) {
            let total = 0;

            menu.products.forEach((customProductProduct) => {
                total += this.restaurantProcessor.getProductById(customProductProduct.id).price;
            });

            return total;
        },

        getProductsNoVariations (orderProduct) {
            let total = 0;
            for (let i = 0; i < orderProduct.requestedVolume; i++) {
                if (this.orderProductVolumeIndexHasVariations(orderProduct, i)) {
                    total++;
                }
            }
            return total;
        },

        orderProductVolumeIndexHasVariations (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).length > 0;
        },

        getOrderProductVariationsByVolumeIndex (orderProduct, volumeIndex) {
            return orderProduct.variations.filter(
                (variation) => variation.volumeIndex === volumeIndex
            ).map(
                (variation) => this.restaurantProcessor.getVariationById(variation.id)
            );
        },

        getOrderProductVariationsTextByVolumeIndex (orderProduct, volumeIndex) {
            return this.getOrderProductVariationsByVolumeIndex(orderProduct, volumeIndex).reduce(
                (text, variation) => text += ", " + this.restaurantProcessor.getVariationLocalizedName(variation.id).toLocaleLowerCase(), ""
            );
        },

        getOrderProductsInPreparation (userOrders) {
            const orderProducts = userOrders.orders.reduce((arr, o) => {
                o.products.forEach((pp) => {
                    const found = arr.find((a) => a.id === pp.id);
                    if (found) {
                        found.completedVolume += pp.completedVolume;
                        found.deliveredVolume += pp.deliveredVolume;
                        found.requestedVolume += pp.requestedVolume;
                        found.displayVolume += pp.requestedVolume - pp.completedVolume;
                        found.variations = [
                            ...found.variations,
                            ...pp.variations.map((v) => ({ id: v.id, volumeIndex: v.volumeIndex + found.requestedVolume, })),
                        ];
                    }
                    else {
                        if (pp.completedVolume < pp.requestedVolume) {
                            arr.push({ ...pp, displayVolume: pp.requestedVolume - pp.completedVolume, });
                        }
                    }
                });
                return arr;
            }, []);
            return orderProducts;
        },

        getOrderProductsInDelivery (userOrders) {
            const orderProducts = userOrders.orders.reduce((arr, o) => {
                o.products.forEach((pp) => {
                    const found = arr.find((a) => a.id === pp.id);
                    if (found) {
                        found.completedVolume += pp.completedVolume;
                        found.deliveredVolume += pp.deliveredVolume;
                        found.requestedVolume += pp.requestedVolume;
                        found.displayVolume += pp.completedVolume - pp.deliveredVolume;
                        found.variations = [
                            ...found.variations,
                            ...pp.variations.map((v) => ({ id: v.id, volumeIndex: v.volumeIndex + found.requestedVolume, })),
                        ];
                        arr.push({ ...pp, });
                    }
                    else {
                        if (pp.completedVolume != pp.deliveredVolume) {
                            arr.push({ ...pp, displayVolume: pp.completedVolume - pp.deliveredVolume,});
                        }
                    }
                });
                return arr;
            }, []);
            return orderProducts;
        },

        getOrderProductsDelivered (userOrders) {
            const orderProducts = userOrders.orders.reduce((arr, o) => {
                o.products.forEach((pp) => {
                    const found = arr.find((a) => a.id === pp.id);
                    if (found) {
                        found.completedVolume += pp.completedVolume;
                        found.deliveredVolume += pp.deliveredVolume;
                        found.requestedVolume += pp.requestedVolume;
                        found.displayVolume += pp.deliveredVolume;
                        found.variations = [
                            ...found.variations,
                            ...pp.variations.map((v) => ({ id: v.id, volumeIndex: v.volumeIndex + found.requestedVolume, })),
                        ];
                        arr.push({ ...pp });
                    }
                    else {
                        if (pp.deliveredVolume > 0) {
                            arr.push({ ...pp, displayVolume: pp.deliveredVolume, });
                        }
                    }
                });
                return arr;
            }, []);
            return orderProducts;
        },

        async refresh () {
            if (this.isWaitingServerResponse) {
                return;
            }
            this.isWaitingServerResponse = true;
            const qr = JSON.parse(window.localStorage.getItem("FortuneRMS::QR"));
            if (qr) {
                tableNumber = qr.tableNumber;
                nickname = qr.nickname;
            }
            else {
                return;
            }

            preloadedData.restaurantPresentation = await server.getRestaurantPresentation(6);
            const scenes = preloadedData.restaurantPresentation.scenes;
            const tables = preloadedData.restaurantPresentation.tables;
            scenes.forEach((s) => {
                s.maps.forEach((m) => {
                    m.tables = m.tables.map((tableId) => {
                        return tables.find((t) => t.id === tableId);
                    });
                });
            });
            scene = scenes.find((s) => s.maps.findIndex((m) => m.tables.findIndex((t) => t.name === tableNumber) !== -1) !== -1);
            if (!scene) {
                return;
            }
            this.loadOrders = tables.find((t) => t.name === tableNumber).orders;
            this.isWaitingServerResponse = false;
        },
    },
    computed: {
        // <restaurant>
        restaurantPresentation () {
            return preloadedData.restaurantPresentation;
        },

        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        restaurant () {
            return this.restaurantProcessor.restaurant;
        },

        restaurantId () {
            return this.restaurantProcessor.id;
        },
        // </restaurant>

        orders () {
            return this.loadOrders;
        },

        ordersGroupByUser () {
            const orders = this.orders.reduce((arr, o) => {
                const user = o.receiver ? o.receiver.fullName : "";
                const found = arr.find((a) => a.user === user);
                if (found) {
                    found.orders.push(o);
                }
                else {
                    arr.push({
                        user: user,
                        orders: [ o,],
                    });
                }
                return arr;
            }, []);

            return orders.sort((u1) => u1.user === nickname ? -1 : 1);
        },

        getMainBoxModifiers () {
            return {
                "main-scrolling": this.isUserScrolling,
            };
        },

        getHeaderModifiers () {
            return {
                "header-resize": this.isUserScrolling,
            };
        },

        getPrimaryLogoModifiers () {
            return {
                "primary-logo-resize": this.isUserScrolling,
            };
        },
    },
    mounted () {
        MobileViewportSimulator.activate();
        MobileViewportSimulator.update();
        const debouncedScroll = debounce(this.handleScroll, 10);
        this.$refs.mainBox.addEventListener("scroll", debouncedScroll);
        this.loadOrders = preloadedData.orders;
    },
    beforeUnmounted () {
        const debouncedScroll = debounce(this.handleScroll, 10);
        this.$refs.mainBox.removeEventListener("scroll", debouncedScroll);
    },
};
</script>

<style lang="scss" scoped>
.order-status-view {
    overflow: hidden;

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: rgb(251, 243, 225);
}

.header {
    padding: 60px 40px 40px 40px;
    background-color: rgb(231, 226, 208);
    height: 30vh;
    height: calc(var(--vh, 1vh) * 30);
    transition: height 300ms ease-out;
}

.header-resize {
    padding: 20px 13px 13px 13px;
    height: 10vh!important;
    height: calc(var(--vh, 1vh) * 10)!important;
}

.header--in-preparation {
    background-color: rgb(234, 130, 48);
    background-image: linear-gradient(45deg, rgb(227, 123, 32), rgb(234, 130, 48));
}
.header--completed {
    background-color: rgb(35, 187, 49);
    background-image: linear-gradient(45deg, rgb(35, 187, 49), rgb(37, 176, 44));
}

.main {
    padding-top: 15px;
    height: 62vh;
    height: calc(var(--vh, 1vh) * 62);
    padding-bottom: 8vh;
    padding-bottom: calc(var(--vh, 1vh) * 8);
    transition: height 300ms ease-out;
    overflow: auto;
}

.main-scrolling {
    height: 82vh!important;
    height: calc(var(--vh, 1vh) * 82)!important;
}

.primary-logo {
    width: 128px;
    height: 128px;
    margin: 0 20px 0px 20px;

    fill: rgb(255, 255, 255);
    transition: height 300ms width 300ms ease-out;
}

.primary-logo-resize {
    width: 32px;
    height: 32px;
    margin: 0 5px 5px 5px;
}

.title {
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 0.08rem;
    color: rgb(158, 106, 34);
}

.description {
    margin: 4px 20px 0 20px;

    font-size: 18px;
    color: rgb(97, 59, 22);
}

.separator {
    width: 100%;
    height: 1px;
    margin: 15px 0;
    padding: 0;
    // background-color: rgb(33, 33, 33);
}


.separator-end {
    width: 100%;
    height: 120px;
    padding: 0;
    // background-color: rgb(33, 33, 33);
}


.order {
    margin: 10px 10px 0 0;

    &__title {
        margin-bottom: 24px;

        font-size: 22px;
        font-weight: 800;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);
    }
}

.product {
    & + & {
        margin-top: 10px;
    }

    &__name {
        font-size: 18px;
        font-weight: 500;
        color: rgb(33, 33, 33);
    }
    &__status {
        padding: 10px 18px;

        border-radius: 1000px;

        font-size: 16px;
        font-weight: 600;
        color: rgb(255, 255, 255);
    }
    &__status--in-preparation {
        background-color: rgb(234, 130, 48);
        background-image: linear-gradient(45deg, rgb(227, 123, 32), rgb(234, 130, 48));
    }
    &__status--completed {
        background-color: rgb(35, 187, 49);
        background-image: linear-gradient(45deg, rgb(35, 187, 49), rgb(37, 176, 44));
    }
}

:deep(.restaurant-order) {
    background: transparent;
    justify-content: center;
    .restaurant-order__box {
        max-width: 650px;
        background: transparent;
        border: none;
        box-shadow: none;
        .restaurant-name {
            display: none;
        }

        .date {
            .restaurant-order__value {
                text-align: end;
            }
        }

        .restaurant-order__expanded {
            overflow: auto;
            height: auto;

            .unique-id {
                display: none;
            }

            .origin {
                display: none;
            }

            .payment-method {
                display: none;
            }

            .payment-date {
                display: none;
            }

            .delivery-address {
                display: none;
            }

            .delivery-date {
                display: none;
            }

            span.product-name {
                padding-right: 25px;
            }

            span.fixed-menu-product {
                padding-right: 25px;
            }
        }
    }
}

.btn-order {
    height: 8vh;
    height: calc(var(--vh, 1vh) * 8);
    position: fixed;
    bottom: 0;
    justify-content: center;
    .simple-button {
        border-radius: 0;
        width: 100vw;
        height: 100%;
        background-color: rgb(60, 46, 14);
    }
}

.btn-refresh {
    height: 10vh;
    width: 10vh;
    position: fixed;
    bottom: 9vh;
    right: 3px;
    :deep(.simple-button) {
        width: 100%!important;
        min-width: 0!important;
        height: 100%;
        border-radius: 50%!important;
        background-color: rgb(60, 46, 14);
        align-items: center;
        align-content: center;

        &__text {
            text-align: center;
        }
    }
}

.language-selector {
    position: absolute;
    left: 0;
    top: 0;

    background-color: rgba(0, 0, 0, 0.3);
    border-bottom-right-radius: 12px;

    z-index: 10;

    :deep(.language) {
        background-color: transparent;
        border-radius: 0;
        padding: 6px 10px;
    }

    :deep(.language.language--selected) {
        background-color: transparent;
    }

    :deep(.language .language__name) {
        display: none;
    }

    :deep(.language .language__icon) {
        margin: 0;

        opacity: 0.5;
    }

    :deep(.language.language--selected .language__icon) {
        opacity: 1;
    }

    :deep(.language+.language) {
        margin: 0;
    }
}

.table-number {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    border-bottom-left-radius: 12px;

    z-index: 10;
    span {
        padding: 10px 10px 0 0;
        font-size: 2rem;
        font-weight: 600;
        color: rgb(60, 46, 14);
    }
}

.product-status {
    padding: 4px 6px;
    background-color: transparent;
    // border-radius: 4px;

    font-size: 12px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    &--in-preparation {
        color: rgb(242, 152, 7);
        // background-color: rgb(242, 152, 7);
        // background-image: linear-gradient(45deg, rgb(242, 152, 7), rgb(250, 166, 30));
    }

    &--in-delivery {
        color: rgb(245, 191, 66);
        // background-color: rgb(245, 191, 66);
        // background-image: linear-gradient(45deg, rgb(245, 191, 66), rgb(230, 210, 76));
    }

    &--delivered {
        color: rgb(14, 161, 32);
        // background-color: rgb(14, 161, 32);
        // background-image: linear-gradient(45deg, rgb(14, 161, 32), rgb(21, 153, 38));
    }
}

.product-name {
    font-size: 15px;
    font-weight: 500;
    color: rgb(20, 20, 20);
}

.fixed-menu-product-list {
    margin: 0;
    padding: 10px 20px;
}

.fixed-menu-product {
    font-size: 15px;
    font-weight: 500;
    color: rgb(20, 20, 20);

    & + & {
        margin-top: 4px;
    }
}

.item-list {
    margin: 8px 0 0 18px;
    padding: 0;
    list-style-type: none;

    &__item {
        color: rgb(20, 20, 20);
        font-size: 15px;
        font-weight: 500;
    }
}

.item-list__item + .item-list__item {
    margin-top: 5px;
}

.username {
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    letter-spacing: 1px;
    color: rgb(97, 59, 22);
}
</style>
