<template>
    <div class="flex simple-selectbox" :class="getModifiers()">
        <label class="flex flex--100 simple-selectbox__choices-label" ref="choices"></label>
    </div>
</template>

<script>
import Choices from "choices.js";

export default {
    name: "SimpleSelectbox",
    props: {
        selectPlaceholder: {
            type: String,
            default: "SimpleSelectBox",
        },
        canSearchOptions: {
            type: Boolean,
            default: false,
        },
        searchPlaceholder: {
            type: String,
            default: "Type...",
        },
        noResultsText: {
            type: String,
            default: "No results found.",
        },
        noOptionsText: {
            type: String,
            default: "No options to choose from.",
        },
        options: {
            type: Array,
            default: () => [],
        },
        canSelectEmptyValue: {
            type: Boolean,
            default: true,
        },
        setOptionsLater: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: String,
        }
    },
    emits: [ "options-load", "update:modelValue" ],
    data () {
        return {
            choicesInstance: null,
            savedOptions: [],
        };
    },
    methods: {
        getModifiers () {
            return {
                "simple-selectbox--hidden-empty-option": !this.canSelectEmptyValue,
                "simple-selectbox--search-disabled": !this.canSearchOptions,
            };
        },

        setOptions (options) {
            this.savedOptions = options;
            const normalizedOptions = [];

            for (const option of options) {
                normalizedOptions.push({
                    value: option.value.toString(),
                    label: option.text,
                });
            }

            this.$refs.choices.innerHTML = `
            <select>
                <option value="">${this.selectPlaceholder}</option>
            </select>
            `;

            this.choicesInstance = new Choices(this.$refs.choices.getElementsByTagName("select")[0], {
                silent: true,
                removeItems: false,
                placeholder: true,
                placeholderValue: this.selectPlaceholder,
                searchPlaceholderValue: this.searchPlaceholder,
                itemSelectText: "",
                loadingText: "",
                noResultsText: this.noResultsText,
                noChoicesText: this.noOptionsText,
            });

            this.choicesInstance.setChoices(normalizedOptions);
            this.choicesInstance.passedElement.element.addEventListener("choice", (event) => {
                this.$emit("update:modelValue", event.detail.choice.value);
            });
            this.$emit("options-load");
        },

        selectOptionByValue (value) {
            this.choicesInstance?.setChoiceByValue(value.toString());
            this.$emit("update:modelValue", value.toString());
        },
    },
    watch: {
        selectPlaceholder () {
            this.setOptions(this.setOptionsLater ? this.savedOptions : this.options);
        },

        searchPlaceholder () {
            this.setOptions(this.setOptionsLater ? this.savedOptions : this.options);
        },

        noResultsText () {
            this.setOptions(this.setOptionsLater ? this.savedOptions : this.options);
        },

        noOptionsText () {
            this.setOptions(this.setOptionsLater ? this.savedOptions : this.options);
        },

        options: {
            deep: true,
            handler (value) {
                this.setOptions(value);
            },
        },
    },
    mounted () {
        this.setOptions(this.options);
    }
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.simple-selectbox {
    @extend .--unselectable;

    &__choices-label {
        margin: 0;
        padding: 0;
    }
}
</style>

<style lang="scss">
@import "~@/css/globals.scss";

.choices {
    overflow: hidden;
    position: relative;
    margin-bottom: 24px;
    font-size: 16px
}

.choices:focus{
    outline:0
}
.choices:last-child{
    margin-bottom:0
}
.choices.is-open{
    overflow:initial
}
.choices.is-disabled .choices__inner,.choices.is-disabled .choices__input{
    background-color:#eaeaea;
    cursor:not-allowed;
    -webkit-user-select:none;
    -ms-user-select:none;
    user-select:none
}
.choices.is-disabled .choices__item{cursor:not-allowed}.choices [hidden]{
    display:none!important
}
.choices[data-type*=select-one]{cursor:pointer}.choices[data-type*=select-one] .choices__inner{
    padding-bottom:7.5px
}
.choices[data-type*=select-one] .choices__input{
    display:block;
    width:100%;
    padding:10px;
    border-bottom:1px solid #ddd;
    background-color:#fff;
    margin:0
}
.choices[data-type*=select-one] .choices__button{
    background-image:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMDAwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
    padding:0;
    background-size:8px;
    position:absolute;
    top:50%;
    right:0;
    margin-top:-10px;
    margin-right:25px;
    height:20px;
    width:20px;
    border-radius:10em;
    opacity:.25
}
.choices[data-type*=select-one] .choices__button:focus,.choices[data-type*=select-one] .choices__button:hover{opacity:1}.choices[data-type*=select-one] .choices__button:focus{
    box-shadow:0 0 0 2px #00bcd4
}
.choices[data-type*=select-one] .choices__item[data-value=''] .choices__button{display:none}.choices[data-type*=select-one]:after{
    content:'';
    height:0;
    width:0;
    border-style:solid;
    border-color:#333 transparent transparent;
    border-width:5px;
    position:absolute;
    right:11.5px;
    top:50%;
    margin-top:-2.5px;
    pointer-events:none
}
.choices[data-type*=select-one].is-open:after{
    border-color:transparent transparent #333;
    margin-top:-7.5px
}
.choices[data-type*=select-one][dir=rtl]:after{
    left:11.5px;
    right:auto
}
.choices[data-type*=select-one][dir=rtl] .choices__button{
    right:auto;
    left:0;
    margin-left:25px;
    margin-right:0
}
.choices[data-type*=select-multiple] .choices__inner,.choices[data-type*=text] .choices__inner{cursor:text}.choices[data-type*=select-multiple] .choices__button,.choices[data-type*=text] .choices__button{
    position:relative;
    display:inline-block;
    margin:0 -4px 0 8px;
    padding-left:16px;
    border-left:1px solid #008fa1;
    background-image:url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjRkZGIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
    background-size:8px;
    width:8px;
    line-height:1;
    opacity:.75;
    border-radius:0
}
.choices[data-type*=select-multiple] .choices__button:focus,.choices[data-type*=select-multiple] .choices__button:hover,.choices[data-type*=text] .choices__button:focus,.choices[data-type*=text] .choices__button:hover{
    opacity:1
}
.choices__inner{
    display:inline-block;
    vertical-align:top;
    width:100%;
    background-color:#f9f9f9;
    padding:7.5px 7.5px 3.75px;
    border:1px solid #ddd;
    border-radius:2.5px;
    font-size:14px;
    min-height:44px;
    overflow:hidden
}
.is-focused .choices__inner,.is-open .choices__inner{
    border-color:#b7b7b7
}
.is-open .choices__inner{
    border-radius:2.5px 2.5px 0 0
}
.is-flipped.is-open .choices__inner{
    border-radius:0 0 2.5px 2.5px
}
.choices__list{
    margin:0;
    padding-left:0;
    list-style:none
}
.choices__list--single{
    display:inline-block;
    padding:4px 16px 4px 4px;
    width:100%
}[dir=rtl] 
.choices__list--single{
    padding-right:4px;
    padding-left:16px
}
.choices__list--single .choices__item{
    width:100%
}
.choices__list--multiple{
    display:inline
}
.choices__list--multiple .choices__item{
    display:inline-block;
    vertical-align:middle;
    border-radius:20px;
    padding:4px 10px;
    font-size:12px;
    font-weight:500;
    margin-right:3.75px;
    margin-bottom:3.75px;
    background-color:#00bcd4;
    border:1px solid #00a5bb;
    color:#fff;
    word-break:break-all;
    box-sizing:border-box
}
.choices__list--multiple .choices__item[data-deletable]{
    padding-right:5px
}[dir=rtl]
.choices__list--multiple .choices__item{
    margin-right:0;
    margin-left:3.75px
}
.choices__list--multiple .choices__item.is-highlighted{
    background-color:#00a5bb;
    border:1px solid #008fa1
}
.is-disabled .choices__list--multiple .choices__item{
    background-color:#aaa;
    border:1px solid #919191
}
.choices__list--dropdown{
    visibility:hidden;
    z-index:20;
    position:absolute;
    width:100%;
    background-color:#fff;
    border:1px solid #ddd;
    top:100%;
    margin-top:-1px;
    border-bottom-left-radius:2.5px;
    border-bottom-right-radius:2.5px;
    overflow:hidden;
    word-break:break-all;
    will-change:visibility
}
.choices__list--dropdown.is-active{
    visibility:visible
}
.is-open .choices__list--dropdown{
    border-color:#b7b7b7
}
.is-flipped .choices__list--dropdown{
    top:auto;
    bottom:100%;
    margin-top:0;
    margin-bottom:-1px;
    border-radius:.25rem .25rem 0 0
}
.choices__list--dropdown .choices__list{
    position:relative;
    max-height:200px;
    overflow:auto;
    -webkit-overflow-scrolling:touch;
    will-change:scroll-position
}
.choices__list--dropdown .choices__item{
    position:relative;
    padding:10px;
    font-size:14px
}[dir=rtl] 
.choices__list--dropdown .choices__item{
    text-align:right
}
@media (min-width:640px){
    .choices__list--dropdown .choices__item--selectable{
        padding-right:100px
    }
    .choices__list--dropdown .choices__item--selectable:after{
        content:attr(data-select-text);
        font-size:12px;
        opacity:0;
        position:absolute;
        right:10px;
        top:50%;
        transform:translateY(-50%)
    }[dir=rtl] 
    .choices__list--dropdown .choices__item--selectable{
        text-align:right;
        padding-left:100px;
        padding-right:10px
    }[dir=rtl] 
    .choices__list--dropdown 
    .choices__item--selectable:after{
        right:auto;left:10px
    }
}
.choices__list--dropdown .choices__item--selectable.is-highlighted{
    background-color:#f2f2f2
}
.choices__list--dropdown .choices__item--selectable.is-highlighted:after{
    opacity:.5
}
.choices__item{
    cursor:default
}
.choices__item--selectable{
    cursor:pointer
}
.choices__item--disabled{
    cursor:not-allowed;
    -webkit-user-select:none;
    -ms-user-select:none;
    user-select:none;
    opacity:.5
}
.choices__heading{
    font-weight:600;
    font-size:12px;
    padding:10px;
    border-bottom:1px solid #f7f7f7;color:gray
}
.choices__button{
    text-indent:-9999px;
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none;
    border:0;
    background-color:transparent;
    background-repeat:no-repeat;
    background-position:center;
    cursor:pointer
}
.choices__button:focus,.choices__input:focus{
    outline:0
}
.choices__input{
    display:inline-block;
    vertical-align:baseline;
    background-color:#f9f9f9;
    font-size:14px;
    margin-bottom:5px;
    border:0;
    border-radius:0;
    max-width:100%;
    padding:4px 0 4px 2px
}[dir=rtl]
.choices__input{
    padding-right:2px;padding-left:0
}
.choices__placeholder{
    opacity:.5
}

.choices {
    margin-top: 20px;
    width: 300px;
    font-size: 16px;
}
.choices * {
    font-size: 16px !important;
}

// Choices.js style override.
.simple-selectbox {
    &--hidden-empty-option .choices .choices__list.choices__list--dropdown .choices__list .choices__item[data-value=""] {
        display: none !important;
    }

    .choices {
        @extend .--unselectable;

        width: 100% !important;
        margin: 0 !important;
    }
    &--search-disabled .choices__input {
        display: none !important;
    }
}
</style>
