<template>
    <div class="flex primary-user-navigation">
        <div class="flex header">
            <language-selector/>
            <div class="flex flex--100 restaurant-selectbox">
                <simple-selectbox
                    :options="restaurantSelectboxOptions"
                    :can-select-empty-value="false"
                    :can-search-options="false"
                    :select-placeholder="restaurantSelectboxPlaceholder"
                    @options-load="onRestaurantSelectboxOptionsLoad"
                    ref="restaurantSelectbox"
                    v-model:model-value="selectedRestaurant"
                />
            </div>
            <div class="flex flex--100 navigation-box">
                <div class="flex flex--100 flex--direction-column separator">
                    <hr class="flex flex--100 separator__bar"/>
                    <hr class="flex flex--100 separator__bar separator__bar--two"/>
                    <span class="separator__text">{{$t("management.primaryUserNavigation.user")}}</span>
                </div>
                <div class="flex flex--100 navigation">
                    <span
                        class="flex flex--100 navigation__tab"
                        :class="getNavigationTabModifiers(NavigationTabType.USER)"
                        @click="selectNavigationTab(NavigationTabType.USER)"
                    >
                        {{ user.descriptor.fullName }}
                    </span>
                    <!--
                    <span
                        class="flex flex--100 navigation__tab"
                        :class="getNavigationTabModifiers(NavigationTabType.USER_ORDERS)"
                        @click="selectedNavigationTab = NavigationTabType.USER_ORDERS"
                    >
                        My Orders
                    </span>
                    -->
                </div>
                <div class="flex flex--100 flex--direction-column separator">
                    <hr class="flex flex--100 separator__bar"/>
                    <hr class="flex flex--100 separator__bar separator__bar--two"/>
                    <span class="separator__text">{{$t("management.generic.restaurant")}}</span>
                </div>
                <div class="flex flex--100 navigation">
                    <span
                        class="flex flex--100 navigation__tab"
                        :class="getNavigationTabModifiers(NavigationTabType.RESTAURANT_MANAGEMENT)"
                        @click="selectedNavigationTab = NavigationTabType.RESTAURANT_MANAGEMENT"
                    >
                        {{$t("management.primaryUserNavigation.management")}}
                    </span>
                </div>
                <div class="flex flex--100 navigation">
                    <span
                        class="flex flex--100 navigation__tab"
                        :class="getNavigationTabModifiers(NavigationTabType.RESTAURANT_ANALYTICS)"
                        @click="selectedNavigationTab = NavigationTabType.RESTAURANT_ANALYTICS"
                    >
                        {{$t("management.primaryUserNavigation.analytics")}}
                    </span>
                </div>
                <div class="flex flex--100 navigation">
                    <span
                        class="flex flex--100 navigation__tab"
                        :class="getNavigationTabModifiers(NavigationTabType.RESTAURANT_PRODUCTS_ANALYTICS)"
                        @click="selectedNavigationTab = NavigationTabType.RESTAURANT_PRODUCTS_ANALYTICS"
                    >
                        {{$t("management.primaryUserNavigation.productsAnalytics")}}
                    </span>
                </div>
                <div class="flex flex--100 navigation">
                    <span
                        class="flex flex--100 navigation__tab"
                        :class="getNavigationTabModifiers(NavigationTabType.RESTAURANT_ORDER_LIST)"
                        @click="selectedNavigationTab = NavigationTabType.RESTAURANT_ORDER_LIST"
                    >
                        {{$t("management.primaryUserNavigation.orders")}}
                    </span>
                </div>
                <div class="flex flex--100 navigation">
                    <span
                        class="flex flex--100 navigation__tab"
                        :class="getNavigationTabModifiers(NavigationTabType.RESTAURANT_PRODUCT_STOCK_MANAGEMENT)"
                        @click="selectedNavigationTab = NavigationTabType.RESTAURANT_PRODUCT_STOCK_MANAGEMENT"
                    >
                        {{$t("management.primaryUserNavigation.stock")}}
                    </span>
                </div>
                <div class="flex flex--100 navigation">
                    <span
                        class="flex flex--100 navigation__tab"
                        :class="getNavigationTabModifiers(NavigationTabType.RESTAURANT_EVENTS_MANAGEMENT)"
                        @click="selectedNavigationTab = NavigationTabType.RESTAURANT_EVENTS_MANAGEMENT"
                    >
                        {{$t("management.primaryUserNavigation.events")}}
                    </span>
                </div>
                <div class="flex flex--100 navigation">
                    <span
                        class="flex flex--100 navigation__tab"
                        :class="getNavigationTabModifiers(NavigationTabType.RESTAURANT_SCENES_MANAGEMENT)"
                        @click="selectedNavigationTab = NavigationTabType.RESTAURANT_SCENES_MANAGEMENT"
                    >
                        {{$t("management.primaryUserNavigation.scenes")}}
                    </span>
                </div>
                <div>
                    <div class="flex flex--100 flex--direction-column separator">
                        <hr class="flex flex--100 separator__bar"/>
                        <hr class="flex flex--100 separator__bar separator__bar--two"/>
                        <span class="separator__text">{{$t("management.primaryUserNavigation.content")}}</span>
                    </div>
                    <div class="flex flex--100 navigation">
                        <span
                            class="flex flex--100 navigation__tab"
                            :class="getNavigationTabModifiers(NavigationTabType.CONTENT_PRODUCTS)"
                            @click="selectedNavigationTab = NavigationTabType.CONTENT_PRODUCTS"
                        >
                            {{$t("management.primaryUserNavigation.products")}}
                        </span>
                        <div class="flex flex--100 navigation">
                        <span
                            class="flex flex--100 navigation__tab"
                            :class="getNavigationTabModifiers(NavigationTabType.RESTAURANT_MEDIA)"
                            @click="selectedNavigationTab = NavigationTabType.RESTAURANT_MEDIA"
                        >
                            {{$t("management.primaryUserNavigation.media")}}
                        </span>
                        </div>
                        <div class="flex flex--100 navigation">
                        <span
                            class="flex flex--100 navigation__tab"
                            :class="getNavigationTabModifiers(NavigationTabType.RESTAURANT_CATEGORY_MANAGEMENT)"
                            @click="selectedNavigationTab = NavigationTabType.RESTAURANT_CATEGORY_MANAGEMENT"
                        >
                            {{$t("management.primaryUserNavigation.categories")}}
                        </span>
                        </div>
                        <div class="flex flex--100 navigation">
                        <span
                            class="flex flex--100 navigation__tab"
                            :class="getNavigationTabModifiers(NavigationTabType.CONTENT_ALLERGENS)"
                            @click="selectedNavigationTab = NavigationTabType.CONTENT_ALLERGENS"
                        >
                            {{$t("management.primaryUserNavigation.allergens")}}
                        </span>
                        </div>
                        <div class="flex flex--100 navigation">
                        <span
                            class="flex flex--100 navigation__tab"
                            :class="getNavigationTabModifiers(NavigationTabType.CONTENT_VARIATIONS)"
                            @click="selectedNavigationTab = NavigationTabType.CONTENT_VARIATIONS"
                        >
                            {{$t("management.primaryUserNavigation.variations")}}
                        </span>
                        </div>
                        <div class="flex flex--100 navigation">
                        <span
                            class="flex flex--100 navigation__tab"
                            :class="getNavigationTabModifiers(NavigationTabType.CONTENT_TABLES)"
                            @click="selectedNavigationTab = NavigationTabType.CONTENT_TABLES"
                        >
                            {{$t("management.primaryUserNavigation.tables")}}
                        </span>
                        </div>
                        <!--
                        <span
                            class="flex flex--100 navigation__tab"
                            :class="getNavigationTabModifiers(NavigationTabType.CONTENT_MENUS)"
                            @click="selectedNavigationTab = NavigationTabType.CONTENT_MENUS"
                        >
                            Menus
                        </span>
                        -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
import LanguageSelector from "@/components/inputs/LanguageSelector";
import {server} from "@/server";
import {user} from "@/user";

export const NavigationTabType = {
    USER: "user",
    USER_ORDERS: "userOrders",
    RESTAURANT_MANAGEMENT: "restaurantManagement",
    RESTAURANT_MEDIA: "restaurantMedia",
    RESTAURANT_CATEGORY_MANAGEMENT: "restaurantCategoryManagement",
    RESTAURANT_ORDER_LIST: "restaurantOrderList",
    RESTAURANT_ANALYTICS: "restaurantAnalytics",
    CONTENT_PRODUCTS: "contentProducts",
    CONTENT_CATEGORIES: "contentCategories",
    CONTENT_MENUS: "contentMenus",
    CONTENT_ALLERGENS: "contentAllergens",
    CONTENT_VARIATIONS: "contentVariations",
    CONTENT_TABLES: "contentTables",
    RESTAURANT_PRODUCT_STOCK_MANAGEMENT: "restaurantProductStockManagement",
    RESTAURANT_PRODUCTS_ANALYTICS: "restaurantProductsAnalytics",
    RESTAURANT_EVENTS_MANAGEMENT: "restaurantEventsManagement",
    RESTAURANT_SCENES_MANAGEMENT: "restaurantScenesManagement"
};

export default {
    name: "PrimaryUserNavigation",
    components: {
        SimpleSelectbox,
        LanguageSelector,
    },
    emits: [ "restaurant-change", "navigation-change" ],
    data () {
        return {
            NavigationTabType,
            globalRestaurants: [],
            userRestaurantsAccess: [],
            selectedRestaurant: null,
            restaurantSelectboxPlaceholder: "Loading...",
            selectedNavigationTab: null,
        };
    },
    methods: {
        onRestaurantSelectboxOptionsLoad () {
            const firstOption = this.restaurantSelectboxOptions[0];
            const sessionRestaurant = window.sessionStorage.getItem("FortuneRMS::ManagementRestaurantId");
            const selectedRestaurant = sessionRestaurant || firstOption?.value;
            if (firstOption) {
                this.$refs.restaurantSelectbox.selectOptionByValue(selectedRestaurant);
            }
            else {
                this.selectedRestaurant = selectedRestaurant;
            }
            const sessionTab = window.sessionStorage.getItem("FortuneRMS::ManagementNavigationTab");
            const selectedTab = sessionTab || NavigationTabType.USER;
            if (selectedTab) {
                this.selectNavigationTab(selectedTab);
            }
        },

        selectNavigationTab (name) {
            this.selectedNavigationTab = name;
        },

        getNavigationTabModifiers (name) {
            return {
                "navigation__tab--selected": name === this.selectedNavigationTab,
            };
        },
    },
    computed: {
        user () {
            return user;
        },

        restaurantSelectboxOptions () {
            const options = [];

            for (const restaurant of this.globalRestaurants) {
                options.push({
                    text: `${restaurant.name}, ${restaurant.address.split(",")[0]}`,
                    value: restaurant.id,
                });
            }

            return options.filter((option) => this.userRestaurantsAccess.includes(option.value));
        },
    },
    watch: {
        selectedRestaurant (value) {
            this.$emit("restaurant-change", value);
        },

        selectedNavigationTab (value) {
            this.$emit("navigation-change", { restaurantId: this.selectedRestaurant, value, });
        },
    },
    async created () {
        this.selectedNavigationTab = "";

        const [ globalRestaurants, userRestaurantsAccess, ] = await Promise.all([
            server.getRestaurants(),
            user.getRestaurants(),
        ]);

        this.globalRestaurants = globalRestaurants;
        this.userRestaurantsAccess = userRestaurantsAccess;
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.header {
    overflow-x: hidden;
    overflow-y: auto;

    width: 22vw;
    height: 100vh;
    margin: 0;
    padding: 0;

    background-color: rgb(70, 70, 70);
}

.navigation-box {
    // Silence is golden.
}

.navigation {
    &__tab {
        @extend  .--unselectable;

        cursor: pointer;
        position: relative;

        margin: 0;
        padding: 15px 20px;

        font-size: 14px;
        font-weight: 600;
        color: rgb(240, 240, 240);
    }
    &__tab:hover {
        background-color: rgb(60, 60, 60);
    }
    &__tab--selected,
    &__tab--selected:hover {
        background-color: rgb(50, 50, 50);
    }
    &__tab--disabled {
        cursor: default;
        opacity: 0.3;
    }
    &__tab--disabled:hover {
        background-color: transparent;
    }
}

.separator {
    margin: 15px 0;
    padding: 0;

    background-color: transparent;

    &__bar {
        height: 1px;
        margin: 0;
        padding: 0;

        background-color: rgb(60, 60, 60);
    }
    &__bar--two {
        background-color: rgb(80, 80, 80);
    }

    &__text {
        @extend  .--unselectable;

        cursor: default;

        margin: 10px 0 0 12px;

        font-size: 11px;
        font-weight: 400;
        color: rgb(150, 150, 150);
    }
}


.language-selector {
    width: 100%;

    background-color: rgba(0, 0, 0, 0.1);

    :deep(.language) {
        padding: 12px 14px;

        background-color: transparent;
        border-radius: 0;
    }
    :deep(.language.language--selected) {
        background-color: transparent;
    }
    :deep(.language .language__name) {
        display: none;
    }
    :deep(.language .language__icon) {
        margin: 0;

        width: 18px;

        opacity: 0.5;
    }
    :deep(.language.language--selected .language__icon) {
        opacity: 1;
    }
    :deep(.language + .language) {
        margin: 0;
    }
}
</style>

<style lang="scss">
.primary-user-navigation {
    & > .header > .restaurant-selectbox {
        & > .simple-selectbox {
            width: 100%;

            margin: 45px 30px 15px 30px;
            padding: 0;
        }
        & > .simple-selectbox .choices {
            color: rgb(255, 255, 255);
        }
        & > .simple-selectbox .choices::after {
            border-color: rgb(255, 255, 255) transparent transparent;
        }
        & > .simple-selectbox .choices.is-open::after {
            border-color: transparent transparent rgb(255, 255, 255);
        }
        & > .simple-selectbox .choices .choices__inner {
            min-height: initial;
            max-height: 46px;
            padding: 10px;

            background-color: rgba(0, 0, 0, 0.12);
            border: none;
            border-radius: 6px;

            vertical-align: initial;
        }
        & > .simple-selectbox .choices .choices__inner * {
            font-weight: 500;
            color: rgb(255, 255, 255);
        }
        & > .simple-selectbox .choices .choices__list.choices__list--dropdown {
            width: 100%;
            margin-top: 10px;

            background-color: rgb(255, 255, 255);
            border: none;
            border-radius: 6px;
        }
        & > .simple-selectbox .choices .choices__list:not(.choices__list--single) {
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.092), 0 0 6px 0 rgba(0, 0, 0, 0.104);
        }
        & > .simple-selectbox .choices .choices__list.choices__list--dropdown * {
            font-size: 16px;
            font-weight: 500;
            color: rgb(50, 50, 50);
        }
        & > .simple-selectbox .choices .choices__list.choices__list--dropdown .choices__item--selectable {
            padding: 14px;
        }
    }
}
</style>
