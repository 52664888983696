<template>
    <div class="flex flex--100 mobile-order-view" :class="getModifiers()">
        <div class="table-number" v-if="tableNumber">
            <span>{{ $t("order.tableAbb") }}: {{ tableNumber }}</span>
        </div>
        <language-selector />
        <mobile-restaurant-product-list
            :scene="scene"
            :restaurant-presentation="restaurantPresentation"
            :table-number="tableNumber"
            :inside-restaurant="true"
            v-model:selected-products="basket.selectedProducts"
            ref="productList"
        />
        <div class="flex flex--100 flex--x-align-center flex--y-align-center footer" :class="getFooterModifiers()" @click="showCheckoutModalBox">
            <div class="flex flex--80 flex--y-align-center basket-summary">
                <div class="flex flex--30">
                    <span class="basket-summary__products-length-text">{{ basketTotalSelectedVolume }}</span>
                </div>
                <div class="flex flex--40 flex--x-align-center">
                    <span class="basket-summary__continue-text">{{ $t("generic.continue") }}</span>
                </div>
                <div class="flex flex--30 flex--x-align-end">
                    <span class="basket-summary__total-price-text">€ {{ normalizePriceToDisplay(basketSubtotal) }}</span>
                </div>
            </div>
        </div>
        <modal-box class="checkout-modal-box" ref="checkoutModalBox" :show-close-button="false" :close-on-background-click="false">
            <customer-order-checkout
                :restaurant-presentation="restaurantPresentation"
                :skip-delivery-state="skipDeliveryState"
                :is-reservation="isReservation"
                :inside-restaurant="insideRestaurant"
                :table-number="tableNumber"
                :nickname="nickname"
                :scene="scene"
                :requested-persons="requestedPersons"
                :pas-order="isPASOrder"
                v-model:basket="basket"
                @cancel="hideCheckoutModalBox"
                @complete="onCheckoutComplete"
                @close="hideCheckoutModalBox"
            />
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import CustomerOrderCheckout from "@/components/CustomerOrderCheckout";
import MobileRestaurantProductList from "@/components/MobileRestaurantProductList";
import { server, } from "@/server";
import {
    MobileViewportSimulator,
    RestaurantProcessor,
    BasketProcessor,
} from "@/utilities";
import { cloneDeep } from "lodash";
import LanguageSelector from "@/components/inputs/LanguageSelector";

const preloadedData = {};

let isReservation = false;
let tableNumber = null;
let nickname = null;
let requestedPersons = null;
let scene;

export default {
    async beforeRouteEnter (to, from, next) {
        const qr = JSON.parse(window.localStorage.getItem("FortuneRMS::QR"));
        if (qr) {
            tableNumber = qr.tableNumber;
            nickname = qr.nickname;
            requestedPersons = qr.requestedPersons;
        }
        else {
            next({ name: "MobileHomeView", });
        }

        preloadedData.restaurantPresentation = await server.getRestaurantPresentation(6);
        const scenes = preloadedData.restaurantPresentation.scenes;
        const tables = preloadedData.restaurantPresentation.tables;
        scenes.forEach((s) => {
            s.maps.forEach((m) => {
                m.tables = m.tables.map((tableId) => {
                    return tables.find((t) => t.id === tableId);
                });
            });
        });
        scene = scenes.find((s) => s.maps.findIndex((m) => m.tables.findIndex((t) => t.name === tableNumber) !== -1) !== -1);
        preloadedData.orders = tables.find((t) => t.name === tableNumber).orders;
        if (!scene) {
            next({ name: "MobileHomeView", });
        }
        next();
    },
    name: "MobileOrderView",
    components: {
        MobileRestaurantProductList,
        ModalBox,
        CustomerOrderCheckout,
        LanguageSelector,
    },
    data () {
        return {
            basket: BasketProcessor.createEmpty(),
            isReservation,
            insideRestaurant: true,
            tableNumber,
            nickname,
            scene,
            requestedPersons,
            loadOrders: [],
        };
    },
    computed: {
        restaurantPresentation () {
            return preloadedData.restaurantPresentation;
        },

        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },
        // </restaurant>

        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                basket: this.basket,
                restaurantProcessor: this.restaurantProcessor,
            });
        },

        basketSelectedProducts () {
            return this.basketProcessor.selectedProducts;
        },

        basketTotalSelectedVolume () {
            return this.basketProcessor.totalSelectedVolume;
        },

        basketSubtotal () {
            return this.basketProcessor.subtotal;
        },

        basketIsEmpty () {
            return this.basketProcessor.isEmpty;
        },
        // </basket>

        skipDeliveryState () {
            return this.insideRestaurant;
        },

        orders () {
            return this.loadOrders;
        },

        isPASOrder () {
            return this.orders.filter((o) => o.paymentMethod === null).length > 0;
        },
        
    },
    methods: {
        getModifiers () {
            return {
                "mobile-order-view--basket-not-empty": !this.basketIsEmpty,
            };
        },

        getFooterModifiers () {
            return {
                "footer--active": !this.basketIsEmpty,
            };
        },

        onCheckoutComplete (confirmedOrder) {
            this.clearBasket();
            const orders = JSON.parse(window.localStorage.getItem("FortuneRMS::LatestOrders")) ?? [];
            window.localStorage.setItem("FortuneRMS::LatestOrders", JSON.stringify([confirmedOrder, ...orders,]));
            this.$refs.productList.updateLatestOrder();
            this.$refs.productList.selectFirstCategory();

            const path = `/orders`;
            this.$router.push({ path, });
        },

        clearBasket () {
            this.basket = BasketProcessor.createEmpty();
        },

        showCheckoutModalBox () {
            this.$refs.checkoutModalBox.show();
        },

        hideCheckoutModalBox () {
            this.$refs.checkoutModalBox.hide();
        },
    },
    watch: {
        basketSelectedProducts: {
            deep: true,
            handler (value) {
                // <products-variations>
                // Remove a variation if its product is no longer selected.
                for (const productId in this.basket.selectedProductsVariations) {
                    if (!value.find((product) => Number.parseInt(product.id) === Number.parseInt(productId))) {
                        delete this.basket.selectedProductsVariations[productId];
                    }
                    else {
                        const variationsCopy = cloneDeep(this.basket.selectedProductsVariations);
                        const productVariations = [];

                        for (let i = 0; i < this.basketProcessor.getProductSelectedVolume(productId); ++i) {
                            productVariations[i] = variationsCopy[productId][i] ?? [];
                        }

                        variationsCopy[productId] = productVariations;
                        this.basket.selectedProductsVariations = variationsCopy;
                    }
                }
                // </products-variations>
            },
        },
    },
    mounted () {
        MobileViewportSimulator.activate();
        MobileViewportSimulator.update();
        this.loadOrders = preloadedData.orders;
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.mobile-order-view {
    overflow: hidden;

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: rgb(243, 243, 243);

    :deep(.modal-box) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }
    :deep(.modal-box__slot) {
        width: 100%;
        height: 90vh;
        height: calc(var(--vh, 1vh) * 90);
        margin: 10vh 0 0 0;
        margin: calc(var(--vh, 1vh) * 10) 0 0 0;
        padding: 0;

        border-radius: 0;
    }

    :deep(> .mobile-restaurant-product-list) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }

    :deep(> .mobile-restaurant-product-list) {
        .main {
            padding-bottom: 20px;
        }

        .restaurant-product .restaurant-product__box,
        .restaurant-fixed-menu .restaurant-fixed-menu__box {
            border: none;
        }

        .restaurant-product--selected .restaurant-product__name,
        .restaurant-product--selected .restaurant-product__price,
        .restaurant-fixed-menu--selected .restaurant-fixed-menu__name,
        .restaurant-fixed-menu--selected .restaurant-fixed-menu__price {
            font-weight: 700;
            color: rgb(178, 109, 44);
        }

        .restaurant-product .selection-controls,
        .restaurant-fixed-menu .selection-controls {
            justify-content: center; // @extend not working here

            left: 50%;
            top: initial;
            bottom: 0;

            width: 169px;

            border-radius: 0;

            transform: translateX(-50%);
            background-color: rgb(87, 61, 28);

            &__volume {
                color: rgb(255, 255, 255);
            }

            &__minus {
                background-color: transparent;
            }

            &__plus {
                background-color: transparent;
            }
        }

        .restaurant-product .preview-horizontal {
            &__image {
                position: absolute;
                right: 0;
                top: 0;

                width: 88px;

                border-radius: 0 0 0 10px;
            }
        }
        .restaurant-product .allergen-list-box {
            right: 20px;
        }
    }

    &--basket-not-empty :deep(> .mobile-restaurant-product-list) {
        .main {
            padding-bottom: 18vh;
            padding-bottom: calc(var(--vh, 1vh) * 18);
        }
    }
}

.footer {
    pointer-events: none;

    height: 15vh;
    height: calc(var(--vh, 1vh) * 15);

    position: absolute;
    bottom: 0;

    background-color: transparent;
    border-top: 1px solid rgba(0, 0, 0, 0.1);

    opacity: 0;

    z-index: 100;

    &--active {
        pointer-events: initial;

        opacity: 1;
    }
}

.checkout-modal-box {
    :deep(.customer-order-checkout) {
        height: inherit;
        overflow: auto;

        .customer-basket {
            height: 65%;
        }

        .control-box {
            height: 35%;

            &__cancel-button {
                display: none;
            }

            &__confirm-button {
                margin: 10px 0 0 0;
                border-radius: 0;
                background-color: rgb(87, 61, 28);
                border: 0;
            }
            &__confirm-button .simple-button__text {
                font-size: 16px;
                font-weight: 800;
            }
        }

        .take-away {
            justify-content: center;

            width: 100%;

            margin: 0 0 16px 0;
        }

        .payment-method-list {
            flex-direction: column;
            flex-wrap: nowrap;
            min-height: 600px;
            row-gap: 20px;

            &__title {
                margin: 28px 24px 36px 24px;

                font-size: 2rem;
                text-align: center;
            }
        }

        .payment-method {
            width: 80vw;
            min-height: 145px;
            background-color: rgb(67, 44, 8);
            border: 0;
            border-radius: 0;
            color: rgb(255, 255, 255);

            padding: 18px 30px;

            &__icon {
                width: 46px;
                height: 46px;

                margin-bottom: 18px;
                fill:rgb(255, 255, 255);
            }

            &__name {
                font-size: 1.5rem;
                text-align: center;
                color: rgb(255, 255, 255);
            }

            &--secondary {
                margin-top: 0;
            }

            .wechat-pay-icon {
                height: 46px;
                margin-bottom: 18px;
            }

            .wechat-payment-text {
                margin-top: 0;
                text-align: center;
            }

            .arrow-icon {
                display: none;
            }
        }

        .payment-method + .payment-method {
            margin: 22px 0 0 0;
        }
    }

    :deep(.basket) {
        .summary .total {
            .total__text {
                width: 50%;
            }

            .total__price {
                text-align: right;
            }
        }
    }

}

.basket-summary {
    cursor: pointer;

    padding: 8px 20px;

    background-color: rgb(87, 61, 28);
    // border-radius: 6px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);

    &__products-length-text {
        @extend .--unselectable;

        min-width: 42px;

        padding: 8px;

        background-color: rgba(100, 100, 100, 0.16);
        border-radius: 6px;

        font-size: 18px;
        font-weight: 800;
        text-align: center;
        color: rgb(255, 255, 255);
    }

    &__continue-text {
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        color: rgb(255, 255, 255);
    }

    &__total-price-text {
        font-size: 18px;
        font-weight: 800;
        text-align: center;
        color: rgb(255, 255, 255);
    }
}

.customer-order-checkout {
    :deep(.customer-address-box),
    :deep(.customer-id-box),
    :deep(.order-message-box),
    :deep(.requested-delivery-timestamp-box) {
        width: 100%;
        padding: 25px 0 !important;
    }

    :deep(.street-textbox) {
        width: 100%!important;
    }

    $max-input-width: 400px;
    $two-input-space: 16px;

    :deep(.building-code-textbox) {
        width: calc(50% - #{$two-input-space / 2})!important;
        max-width: calc(#{$max-input-width} / 2 - #{$two-input-space / 2})!important;

        margin: 16px $two-input-space 0 0;
    }

    :deep(.postal-code-textbox) {
        width: calc(50% - #{$two-input-space / 2})!important;
        max-width: calc(#{$max-input-width} / 2 - #{$two-input-space / 2})!important;

        margin: 16px 0 0 0;
    }

    :deep(.full-name-textbox) {
        width: 100%;
    }

    :deep(.mobile-textbox) {
        width: 100%;
    }

    :deep(.persons-textbox) {
        width: 100%;
    }

    :deep(.delivery-location-button) {
        margin: 4px;

        border: none !important;
        border-radius: 12px !important;
    }

    :deep(.order-message-textbox) {
        width: 100%;
    }

    :deep(.times-selectbox) {
        width: 100%;
    }
}

:deep(.order-completed-box) {
    &__cashier-payment-notice-text {
        max-width: 80vw;
        margin-top: 20px;
        padding: 6px 25px;

        
        background-color: rgb(60, 46, 14);
        border-radius: 1000px;

        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: 0.06rem;
        text-transform: uppercase;
        text-align: center;
        color: rgb(255,255,255);
    }
}

.table-number {
    position: absolute;
    right: 0;
    top: 0;
    background-color: transparent;
    border-bottom-left-radius: 12px;

    z-index: 10;
    span {
        padding: 10px 10px 0 0;
        font-size: 2rem;
        font-weight: 600;
        color: rgb(60, 46, 14);
    }
}
.language-selector {
    position: absolute;
    left: 0;
    top: 0;

    background-color: transparent;
    border-bottom-right-radius: 12px;

    z-index: 10;

    :deep(.language) {
        background-color: transparent;
        border-radius: 0;
        padding: 6px 10px;
    }

    :deep(.language.language--selected) {
        background-color: transparent;
    }

    :deep(.language .language__name) {
        display: none;
    }

    :deep(.language .language__icon) {
        margin: 0;

        opacity: 0.5;
    }

    :deep(.language.language--selected .language__icon) {
        opacity: 1;
    }

    :deep(.language+.language) {
        margin: 0;
    }
}
</style>
