<template>
    <div class="flex flex--100 category-selector">
        <div class="flex flex--100">
            <simple-button :text="selectButtonText" @click="showCategoryModalBox"/>
        </div>
        <modal-box ref="modalBox" :append-to-body="true">
            <div class="flex flex--100 category-list">
                <restaurant-category
                    v-for="category in categories"
                    :key="category.id"
                    :category="category"
                    :selectable="true"
                    v-model:selected="selectedCategories[category.id]"
                />
            </div>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import RestaurantCategory from "@/components/RestaurantCategory";

export default {
    name: "CategorySelector",
    components: {ModalBox, SimpleButton, RestaurantCategory},
    props: {
        categories: {
            type: Array,
        },
        selected: {
            type: Array,
            default: () => {},
        },
        max: {
            type: Number,
            default: -1,
        },
    },
    emits: [ "update:selected", ],
    data () {
        return {
            selectedCategories: this.selected.reduce((selected, id) => {
              selected[id] = true;

              return selected;
            }, {}),
        };
    },
    methods: {
        getCategoryModifiers (category) {
            return {
                "category--selected": this.selected.includes(category.id),
            };
        },

        showCategoryModalBox () {
            this.$refs.modalBox.show();
        },

        toggleSelection (category) {
            const selectedCopy = JSON.parse(JSON.stringify(this.selected));
            const selectedIndex = this.selected.indexOf(category.id);

            if (selectedIndex !== -1) {
                selectedCopy.splice(selectedIndex, 1);
            }
            else if (this.max === -1 || this.selected.length < this.max) {
                selectedCopy.push(category.id);
            }

            this.$emit("update:selected", selectedCopy);
        },
    },
    computed: {
        normalizedSelectedCategories () {
            return Object.keys(this.selectedCategories).filter((id) => this.selectedCategories[id] === true);
        },

        selectButtonText () {
            let text;

            if (this.max === 1) {
                text = "Select category";
            }
            else {
                text = "Select categories";
            }

            if (this.selected.length > 0) {
                text = `${text} (${this.normalizedSelectedCategories.length})`;
            }

            return text;
        },
    },
    watch: {
        normalizedSelectedCategories: {
            deep: true,
            handler (value) {
                this.$emit("update:selected", value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.restaurant-category {
    margin: 24px;
}

.modal-box {
    :deep(.modal-box__slot) {
        height: auto;
        min-height: initial;
    }
}
</style>
