<template>
    <div class="flex flex--100 restaurant-order-list-tab" v-if="hasLoadedData">
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center common-timeframes-box">
                <simple-button
                    :text="$t(`management.generic.daily`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="setDailyTimeframe"
                />
                <simple-button
                    :text="$t(`management.generic.weekly`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="setWeeklyTimeframe"
                />
                <simple-button
                    :text="$t(`management.generic.monthly`)"
                    :is-loading="isWaitingServerResponseForOrders"
                    :is-disabled="isWaitingServerResponseForOrders"
                    @click="setMonthlyTimeframe"
                />
            </div>
            <div class="flex flex--100 flex--x-align-center date-picker-box">
                <v3-date-picker
                    class="flex flex--x-align-center date-picker-input"
                    input-format="dd/MM/yyyy"
                    :locale="calendarLanguage"
                    v-model:model-value="fromDate"
                    @input="updateOrders"
                />
                <span class="title">-</span>
                <v3-date-picker
                    class="flex flex--x-align-center date-picker-input"
                    input-format="dd/MM/yyyy"
                    :locale="calendarLanguage"
                    :disabled-dates="calendarToDisabledDates"
                    v-model:model-value="toDate"
                    @input="updateOrders"
                />
            </div>
            <div class="flex flex--100 flex--x-align-center scene-box">
                <simple-selectbox
                    class="scene-select-box"
                    :options="sceneSelectboxOptions"
                    :can-select-empty-value="false"
                    :can-search-options="false"
                    :select-placeholder="$t('generic.chooseScene')"
                    @options-load="onSceneSelectboxOptionsLoad"
                    ref="sceneSelectbox"
                    v-model:model-value="selectedScene"
                />
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 flex--x-align-center">
                <h2 class="title">
                    {{ closedOrders.length }} {{$t(`management.analyticsTab.closedOrders`)}}, {{ timeframeOrders.length - closedOrders.length }} {{$t(`management.analyticsTab.unclosedOrders`)}}
                </h2>
            </div>
            <div class="flex flex--100 separator">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.analyticsTab.revenue`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.totRevenue`).toLocaleUpperCase()}} /</span>
                    € {{ normalizePriceToDisplay(totalGrossProfit) }}
                </h2>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.analyticsTab.revenueByDay`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <apexchart width="1000" type="line" :options="profitByDayChartOptions" :series="profitByDaySeries"/>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.analyticsTab.averageOrders`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--50 flex--x-align-center">
                <h2 class="flex flex--y-align-center total-gross-profit">
                    <span class="total-gross-profit__title">{{$t(`management.analyticsTab.averageDailyOrders`).toLocaleUpperCase()}} /</span>
                    {{ (this.closedOrders.length / timeframeEffectiveDays).toFixed(0) }}
                </h2>
            </div>
            <div class="flex flex--50 flex--x-align-center">
                <h2 class="flex flex--y-align-center total-net-profit">
                    <span class="total-net-profit__title">{{$t(`management.analyticsTab.averageOrderTotal`).toLocaleUpperCase()}} /</span>
                    € {{ normalizePriceToDisplay(Number.isFinite(averageOrderTotal) ?  averageOrderTotal : 0) }}
                </h2>
            </div>
            <div class="flex flex--100 separator">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.analyticsTab.averageDailyOrders`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <h2 class="flex flex--y-align-center total-net-profit">
                    <span class="total-net-profit__title">{{$t(`management.analyticsTab.averageDailyRevenue`).toLocaleUpperCase()}} /</span>
                    € {{ normalizePriceToDisplay(Number.isFinite(averageDailyRevenue) ? averageDailyRevenue : 0) }}
                </h2>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.analyticsTab.ordersByDay`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <apexchart width="1000" type="line" :options="ordersByDayChartOptions" :series="ordersByDaySeries"/>
            </div>
        </div>
        <div class="flex flex--100">
            <div class="flex half-box">
                <div class="flex flex--100 separator separator--no-margin-top">
                    <hr class="flex flex--100 separator__bar"/>
                    <span class="separator__text">{{$t(`management.analyticsTab.ordersByOrigin`).toLocaleUpperCase()}}</span>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <apexchart width="500" type="pie" :options="JSON.parse($tm(`management.analyticsTab.originChartOptions`))" :series="originSeries"/>
                </div>
            </div>
            <div class="flex half-box">
                <div class="flex flex--100 separator separator--no-margin-top">
                    <hr class="flex flex--100 separator__bar"/>
                    <span class="separator__text">{{$t(`management.analyticsTab.ordersByPaymentMethod`).toLocaleUpperCase()}}</span>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <apexchart width="500" type="pie" :options="JSON.parse($tm(`management.analyticsTab.paymentMethodChartOptions`))" :series="paymentMethodSeries"/>
                </div>
            </div>
        </div>
        <div class="flex flex--100">
            <div class="flex half-box">
                <div class="flex flex--100 separator separator--no-margin-top">
                    <hr class="flex flex--100 separator__bar"/>
                    <span class="separator__text">{{$t(`management.analyticsTab.profitByPaymentMethod`).toLocaleUpperCase()}}</span>
                </div>
                <div class="flex flex--100 flex--x-align-center">
                    <apexchart width="500" type="pie" :options="JSON.parse($tm(`management.analyticsTab.paymentMethodChartOptions`))" :series="profitByPaymentMethodSeries"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SimpleButton from "@/components/inputs/SimpleButton";
import { server, } from "@/server";
import {OrderOrigin, OrderPaymentMethod, OrderStatus, ThirdPartyOrigin,} from "@/orders/RestaurantOrder";
import {enUS, zhCN, it} from "date-fns/locale";
import {notificationCenter} from "@/components/utilities/NotificationCenter";
import V3DatePicker from "@/components/utilities/V3DatePicker";
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
import { i18n, } from "@/main";

const DAY = 86400000;

function getMidnightDate () {
    const date = new Date();

    date.setUTCHours(0, 0, 0, 0);

    return date;
}

export default {
    name: "RestaurantAnalyticsTab",
    components: {
        SimpleButton,
        V3DatePicker,
        SimpleSelectbox,
    },
    data () {
        return {
            hasLoadedData: false,
            fromDate: getMidnightDate(),
            toDate: new Date(getMidnightDate().getTime() + DAY),
            nativeFromDate: undefined,
            nativeToDate: undefined,
            valueAddedTax: 10,
            restaurantId: -1,
            orders: [],
            products: [],
            fixedMenus: [],
            isWaitingServerResponseForOrders: false,

            calendarLanguageEN: enUS,
            calendarLanguageZH: zhCN,
            calendarLanguageIT: it,

            selectedScene: undefined,
            scenes: [],
        };
    },
    methods: {
        async load (restaurantId) {
            this.hasLoadedData = false;
            this.restaurantId = restaurantId;
            this.restaurantPresentation = await server.getRestaurantPresentation(restaurantId);
            this.orders = await server.getRestaurantOrders(restaurantId);
            this.products = this.restaurantPresentation.products;
            this.fixedMenus = this.restaurantPresentation.fixedMenus;
            this.scenes = this.restaurantPresentation.scenes;
            this.hasLoadedData = true;
        },

        async setDailyTimeframe () {
            this.fromDate = getMidnightDate();
            this.toDate = new Date(this.fromDate.getTime() + 86400000);

            await this.updateOrders();
        },

        async setWeeklyTimeframe () {
            const date = new Date();

            date.setUTCHours(0, 0, 0, 0);

            this.fromDate = new Date(date.setUTCDate(date.getUTCDate() - date.getUTCDay() + (date.getUTCDay() === 0 ? -6 : 1)));
            this.toDate = new Date(date.setUTCDate(date.getUTCDate() - date.getUTCDay() + 7));

            await this.updateOrders();
        },

        async setMonthlyTimeframe () {
            const date = new Date();

            this.fromDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
            this.toDate = new Date((new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)).getTime() + 86400000);

            await this.updateOrders();
        },

        getOrderDiscountPercentage (order) {
            return Math.min(order.discounts.reduce((percentage, discount) => percentage + discount.percentage, 0), 100);
        },

        getOrderSubtotal (order) {
            let subtotal = 0;

            const productsCopy = [ ...order.products, ];

            order.customProducts?.forEach((customProduct) => {
                const orderProduct = {};
                let totalPrice = 0;
                customProduct.products.forEach((product) => {
                    totalPrice += this.products.find((productToFind) => productToFind.id === product.id).price;
                })
                orderProduct.unitPrice = totalPrice;
                orderProduct.requestedVolume = 1;
                productsCopy.push(orderProduct);
            });

            subtotal = productsCopy.reduce((sub, product) => product.requestedVolume * product.unitPrice + sub, 0);

            subtotal += order.additions.reduce((sub, addition) => addition.value + sub, 0);
            subtotal += order.deductions.reduce((sub, deduction) => deduction.value + sub, 0);

            return subtotal;
        },

        getOrderDiscount (order) {
            const discountPercentage = this.getOrderDiscountPercentage(order);

            if (discountPercentage === 0) {
                return 0;
            }

            return discountPercentage * this.getOrderSubtotal(order) / 100;
        },

        getOrderTotal (order) {
            return Math.max(0, this.getOrderSubtotal(order) - this.getOrderDiscount(order));
        },

        async updateOrders (sceneId) {
            if (this.isWaitingServerResponseForOrders) {
                return false;
            }

            this.isWaitingServerResponseForOrders = true;

            this.orders = await server.getRestaurantOrdersByTimestamp({
                id: this.restaurantId,
                fromTimestamp: Date.parse(this.fromDate.toString()),
                toTimestamp: Date.parse(this.toDate.toString()),
                sceneId: sceneId,
            });

            const unexpectedOrders = this.closedOrders.filter((order) => order.origin === OrderOrigin.THIRD_PARTY && !Number.isFinite(order.totalPrice));
            if (unexpectedOrders.length > 0) {
                console.log("List of third party orders without price: ", unexpectedOrders);
                notificationCenter?.sendFailureNotification({ text: unexpectedOrders.length + " " + this.$t(`notification.skipErrorOrders`), });
            }

            this.isWaitingServerResponseForOrders = false;
        },

        onSceneSelectboxOptionsLoad () {
            const firstOption = this.sceneSelectboxOptions[0];
            const selectedScene = this.selectedScene || firstOption?.value;
            if (firstOption) {
                this.$refs.sceneSelectbox.selectOptionByValue(String(selectedScene));
            }
            else {
                this.selectedScene = String(selectedScene);
            }
        },
    },
    computed: {
        timeframeOrders () {
            return this.orders.filter((order) => new Date(order.creationTimestamp) >= this.fromDate && new Date(order.creationTimestamp) <= this.toDate);
        },

        timeframeThirdPartyOrders () {
            return this.timeframeOrders.filter((order) => order.origin === OrderOrigin.THIRD_PARTY);
        },

        timeframeStandardOrders () {
            return this.timeframeOrders.filter((order) => order.origin !== OrderOrigin.THIRD_PARTY);
        },

        timeframeDays () {
            return (this.toDate.getTime() - this.fromDate.getTime()) / DAY;
        },

        timeframeEffectiveDays () {
            return Object.keys(this.dailyProfits).length;
        },

        closedOrders () {
            return this.timeframeOrders.filter((order) => order.status === OrderStatus.CLOSED || order.status === OrderStatus.DELIVERED);
        },

        closedThirdPartyOrders () {
            return this.closedOrders.filter((order) => order.origin === OrderOrigin.THIRD_PARTY && Number.isFinite(order.totalPrice));
        },

        closedStandardOrders () {
            return this.closedOrders.filter((order) => order.origin !== OrderOrigin.THIRD_PARTY);
        },

        profitByDayChartOptions () {
            return {
                xaxis: {
                    categories: Object.keys(this.dailyProfits).map((key) => new Date(key).toLocaleDateString("it")),
                },
            };
        },

        ordersByDayChartOptions () {
            return {
                xaxis: {
                    categories: Object.keys(this.dailyOrders).map((key) => new Date(key).toLocaleDateString("it")),
                },
            };
        },

        profitByDaySeries () {
            return [{
                name: "GROSS PROFIT",
                data: Object.values(this.dailyProfits).map((value) => Number.parseFloat(value.toFixed(2))),
            }, {
                name: "NET PROFIT",
                data: Object.values(this.dailyProfits).map((value) => Number.parseFloat((value - value * this.valueAddedTax / 100).toFixed(2))),
            }];
        },

        ordersByDaySeries () {
            return [{
                name: "PAID ORDERS",
                data: Object.values(this.dailyOrders),
            }];
        },

        dailyProfits () {
            const dailyProfits = {};
            const closedThirdPartyOrders = this.closedThirdPartyOrders;
            const closedStandardOrders = this.closedStandardOrders;
            const timeframeDays = this.timeframeDays;
            const actualDate = new Date();

            for (let i = 0; i < timeframeDays; ++i) {
                const previousDate = new Date(this.fromDate.getTime() + i * DAY);
                const dayDate = new Date(this.fromDate.getTime() + ((i + 1) * DAY));

                if (actualDate < previousDate) {
                    break;
                }

                const standardOrders = closedStandardOrders.filter((order) => new Date(order.creationTimestamp) >= previousDate && new Date(order.creationTimestamp) <= dayDate);
                const thirdPartyOrders = closedThirdPartyOrders.filter((order) => new Date(order.creationTimestamp) >= previousDate && new Date(order.creationTimestamp) <= dayDate);
                let dailyProfit = 0;

                standardOrders.forEach((dailyOrder) => {
                    dailyProfit += this.getOrderTotal(dailyOrder);
                });

                thirdPartyOrders.forEach((dailyOrder) => {
                    dailyProfit += dailyOrder.totalPrice;
                });

                dailyProfits[previousDate.toISOString()] = dailyProfit;
            }

            return dailyProfits;
        },

        dailyOrders () {
            const dailyOrders = {};
            const orders = this.closedOrders;
            const timeframeDays = this.timeframeDays;
            const actualDate = new Date();

            for (let i = 0; i < timeframeDays; ++i) {
                const previousDate = new Date(this.fromDate.getTime() + i * DAY);
                const dayDate = new Date(this.fromDate.getTime() + ((i + 1) * DAY));

                if (actualDate < previousDate) {
                    break;
                }

                dailyOrders[previousDate.toISOString()] = orders.filter(
                    (order) => new Date(order.creationTimestamp) >= previousDate && new Date(order.creationTimestamp) <= dayDate
                ).length;
            }

            return dailyOrders;
        },

        totalGrossProfit () {
            const standardOrders = this.closedStandardOrders;
            const thirdPartyOrders = this.closedThirdPartyOrders;
            let grossProfit = 0;

            for (const order of standardOrders) {
                grossProfit += this.getOrderTotal(order);
            }

            for (const order of thirdPartyOrders) {
                grossProfit += order.totalPrice;
            }

            return grossProfit;
        },

        totalNetProfit () {
            return this.totalGrossProfit - this.valueAddedTax * this.totalGrossProfit / 100;
        },

        originSeries () {
            const standardOrders = this.closedStandardOrders;
            const thirdPartyOrders = this.closedThirdPartyOrders;
            const websiteOrders = standardOrders.filter((order) => order.origin === OrderOrigin.WEBSITE);
            const kioskOrders = standardOrders.filter((order) => order.origin === OrderOrigin.KIOSK);
            const cashierOrders = standardOrders.filter((order) => order.origin === OrderOrigin.CASHIER);
            const deliverooOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.DELIVEROO);
            const guaguaOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.GUAGUA);
            const glovoOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.GLOVO);
            const uberEatsOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.UBEREATS);
            const phoneCallOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.PHONECALL);
            const wechatOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.WECHAT);
            const justEatOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.JUSTEAT);
            const otherOrders = thirdPartyOrders.filter((order) => order.thirdPartyOrigin === ThirdPartyOrigin.OTHER);

            return [
                websiteOrders.length,
                kioskOrders.length,
                cashierOrders.length,
                deliverooOrders.length,
                guaguaOrders.length,
                glovoOrders.length,
                uberEatsOrders.length,
                phoneCallOrders.length,
                wechatOrders.length,
                justEatOrders.length,
                otherOrders.length,
            ];
        },

        paymentMethodSeries () {
            const orders = this.closedOrders;
            const cashPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.CASH);
            const cardPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.CARD);
            const deliverooPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.DELIVEROO);
            const guaguaPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.GUAGUA);
            const glovoPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.GLOVO);
            const uberEatsPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.UBEREATS);
            const phoneCallPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.PHONECALL);
            const wechatPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.WECHAT_PAY);
            const justEatPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.JUSTEAT);
            const otherPaymentOrders = orders.filter((order) => order.paymentMethod === OrderPaymentMethod.OTHER);

            return [
                cashPaymentOrders.length,
                cardPaymentOrders.length,
                deliverooPaymentOrders.length,
                guaguaPaymentOrders.length,
                glovoPaymentOrders.length,
                uberEatsPaymentOrders.length,
                phoneCallPaymentOrders.length,
                wechatPaymentOrders.length,
                justEatPaymentOrders.length,
                otherPaymentOrders.length,
            ];
        },

        profitByPaymentMethodSeries () {
            const thirdPartyOrders = this.closedThirdPartyOrders;
            const standardOrders = this.closedStandardOrders;
            const cashPaymentOrders = standardOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.CASH);
            const cardPaymentOrders = standardOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.CARD);
            const deliverooPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.DELIVEROO);
            const guaguaPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.GUAGUA);
            const glovoPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.GLOVO);
            const uberEatsPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.UBEREATS);
            const phoneCallPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.PHONECALL);
            const thirdPartyWechatPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.WECHAT_PAY);
            const standardWechatPaymentOrders = standardOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.WECHAT_PAY);
            const justEatPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.JUSTEAT);
            const otherPaymentOrders = thirdPartyOrders.filter((order) => order.paymentMethod === OrderPaymentMethod.OTHER);

            return [
                Number.parseFloat(cashPaymentOrders.reduce((total, order) => this.getOrderTotal(order) + total, 0).toFixed(2)),
                Number.parseFloat(cardPaymentOrders.reduce((total, order) => this.getOrderTotal(order) + total, 0).toFixed(2)),
                Number.parseFloat(deliverooPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(guaguaPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(glovoPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(uberEatsPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(phoneCallPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(
                    Number.parseFloat(
                        standardWechatPaymentOrders.reduce((total, order) => this.getOrderTotal(order) + total, 0).toFixed(2) +
                        thirdPartyWechatPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)
                    ).toFixed(2)
                ),
                Number.parseFloat(justEatPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
                Number.parseFloat(otherPaymentOrders.reduce((total, order) => order.totalPrice + total, 0).toFixed(2)),
            ];
        },

        calendarToDisabledDates () {
            return {
                predicate: (date) => date < this.fromDate,
            }
        },

        calendarLanguage () {
            return this[`calendarLanguage${this.$i18n.locale.toLocaleUpperCase()}`];
        },

        averageDailyRevenue () {
            return this.totalGrossProfit / this.timeframeEffectiveDays;
        },

        averageOrderTotal () {
            return (this.totalGrossProfit / this.timeframeEffectiveDays / Number.parseFloat((this.closedOrders.length / this.timeframeEffectiveDays).toFixed(0)))
        },

        sceneSelectboxOptions () {
            let options = [{
                text: this.$t("generic.allScene").toLocaleUpperCase(),
                value: "all",
            }];

            for (const scene of this.scenes) {
                options = [ ...options, {
                    text: scene.localizations?.find((localization) => localization.languageIso === i18n.global.locale).name.toLocaleUpperCase(),
                    value: scene.id,
                }, ];
            }

            return options;
        },

        selected () {
            return this.scenes.find((scene) => String(scene.id) === String(this.selectedScene));
        }
    },
    watch: {
        selectedScene (newValue) {
            if (newValue) {
                this.updateOrders(newValue);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.add-category-button {
    cursor: pointer;

    margin: 50px 0;
    padding: 10px 20px;

    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.022), 0 0 6px 0 rgba(0, 0, 0, 0.034);
    border-radius: 6px;

    &__text {
        font-size: 14px;
        font-weight: 500;
        color: rgb(40, 40, 40);
    }
    &:hover &__text {
        color: rgb(78, 199, 26);
    }

    &__icon {
        width: 12px;
        margin-left: 5px;

        fill: rgb(60, 60, 60);
    }
    &:hover &__icon {
        fill: rgb(78, 199, 26);
    }
}

.restaurant-order {
    margin: 25px;

    width: 45%;
}

.order-list {
    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);
}

.control-box {
    margin: 0 0 50px 0;
}

.no-orders {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.title {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0.06rem;
    color: rgb(33, 33, 33);
}

.total-gross-profit {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.06rem;
    color: rgb(33, 33, 33);

    &__title {
        margin-right: 5px;

        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);
    }
}

.total-net-profit {
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0.06rem;
    color: rgb(33, 33, 33);

    &__title {
        margin-right: 5px;

        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0.06rem;
        color: rgb(33, 33, 33);
    }
}

.half-box {
    width: calc(50% - 60px);
    margin: 30px;
    padding: 50px;

    background-color: rgb(255, 255, 255);
    border-radius: 6px;
    box-shadow: 0 0 50px 1px rgba(0, 0, 0, 0.05);

    & + & {
        width: calc(50% - 30px);
        margin-left: 0;
    }
}

.common-timeframes-box {
    :deep(.simple-button) {
        margin: 0;

        border-radius: 0;
        border-left: 1px solid rgb(48, 48, 48);
    }
    :deep(.simple-button:first-child) {
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        border-left: none;
    }
    :deep(.simple-button:last-child) {
        border-top-right-radius: 12px;
        border-bottom-right-radius: 12px;
    }

}

.date-picker-box {
    margin-top: 28px;
    padding: 0;
}

</style>

<style lang="scss">
.restaurant-order-list-tab {
    .apexcharts-toolbar {
        display: none !important;
    }
}
.date-picker-input {
    font-size: 24px;
    text-align: center;
}

.scene-select-box {
    min-width: 400px;
}

.scene-box {
    margin-top: 28px;
    padding: 0;
}
</style>
