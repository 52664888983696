<template>
    <div class="flex flex--100 management-view">
        <primary-user-navigation @restaurant-change="onRestaurantChange" @navigation-change="onNavigationTabChange"/>
        <div class="flex main">
            <primary-user-navigation-tab ref="nav">
                <component :is="activeTabComponent" :ref="assignRef"/>
            </primary-user-navigation-tab>
        </div>
    </div>
</template>

<script>
import PrimaryUserNavigation from "@/components/navigation/PrimaryUserNavigation";
import PrimaryUserNavigationTab from "@/components/tabs/PrimaryUserNavigationTab";
import RestaurantAnalyticsTab from "@/components/tabs/RestaurantAnalyticsTab";
import RestaurantCategoryManagementTab from "@/components/tabs/RestaurantCategoryManagementTab";
import RestaurantManagementTab from "@/components/tabs/RestaurantManagementTab";
import RestaurantMediaTab from "@/components/tabs/RestaurantMediaTab";
import RestaurantMenusTab from "@/components/tabs/RestaurantMenusTab";
import RestaurantOrderListTab from "@/components/tabs/RestaurantOrderListTab";
import RestaurantProductManagementTab from "@/components/tabs/RestaurantProductManagementTab";
import RestaurantAllergenManagementTab from "@/components/tabs/RestaurantAllergenManagementTab";
import RestaurantVariationsTab from "@/components/tabs/RestaurantVariationsTab";
import UserTab from "@/components/tabs/UserTab";
import RestaurantTableManagementTab from "@/components/tabs/RestaurantTableManagementTab";
import RestaurantProductStockManagementTab from "@/components/tabs/RestaurantProductStockManagementTab";
import RestaurantProductsAnalyticsTab from "@/components/tabs/RestaurantProductsAnalyticsTab";
import EventsManagementTab from "@/components/tabs/EventsManagementTab";
import ScenesManagementTab from "@/components/tabs/ScenesManagementTab";

export const NavigationTabComponents = {
    user: "UserTab",
    userOrders: "userOrders",
    restaurantManagement: "RestaurantManagementTab",
    restaurantMedia: "RestaurantMediaTab",
    restaurantCategoryManagement: "RestaurantCategoryManagementTab",
    restaurantOrderList: "RestaurantOrderListTab",
    restaurantAnalytics: "RestaurantAnalyticsTab",
    contentProducts: "RestaurantProductManagementTab",
    contentCategories: "RestaurantCategoryManagementTab",
    contentMenus: "RestaurantMenusTab",
    contentAllergens: "RestaurantAllergenManagementTab",
    contentVariations: "RestaurantVariationsTab",
    contentTables: "RestaurantTableManagementTab",
    restaurantProductStockManagement: "RestaurantProductStockManagementTab",
    restaurantProductsAnalytics: "RestaurantProductsAnalyticsTab",
    restaurantEventsManagement: "EventsManagementTab",
    restaurantScenesManagement: "ScenesManagementTab"
};

export default {
    name: "PrivateView",
    components: {
        EventsManagementTab,
        RestaurantProductStockManagementTab,
        RestaurantAnalyticsTab,
        RestaurantCategoryManagementTab,
        RestaurantMediaTab,
        RestaurantOrderListTab,
        RestaurantMenusTab,
        RestaurantProductManagementTab,
        RestaurantManagementTab,
        PrimaryUserNavigationTab,
        UserTab,
        PrimaryUserNavigation,
        RestaurantAllergenManagementTab,
        RestaurantVariationsTab,
        RestaurantTableManagementTab,
        RestaurantProductsAnalyticsTab,
        ScenesManagementTab,
    },
    data () {
        return {
            selectedRestaurantId: null,
            selectedNavigationTab: null,
            activeTabComponent: null,
            activeTabComponentRef: null,
        }
    },
    methods: {
        assignRef ($el) {
            this.activeTabComponentRef = $el;
        },

        onRestaurantChange (value) {
            if (value) {
                this.selectedRestaurantId = value;
                window.sessionStorage.setItem("FortuneRMS::ManagementRestaurantId", value);
                this.activeTabComponent = NavigationTabComponents[this.selectedNavigationTab];
            }
        },

        onNavigationTabChange ({ restaurantId, value, }) {
            if (value) {
                this.selectedRestaurantId = restaurantId;
                this.selectedNavigationTab = value;
                this.activeTabComponent = NavigationTabComponents[this.selectedNavigationTab];
                window.sessionStorage.setItem("FortuneRMS::ManagementNavigationTab", value);
            }
        },
    },
    watch: {
        activeTabComponentRef (actualValue) {
            if (actualValue) {
                const nav = this.$refs["nav"];
                if (this.activeTabComponent && this.activeTabComponentRef?.load) {
                    nav.load(() => actualValue.load(this.selectedRestaurantId));
                }
            }
        },

        selectedRestaurantId (actualValue, previousValue) {
            if (previousValue && actualValue) {
                const nav = this.$refs["nav"];

                if (this.activeTabComponent && this.activeTabComponentRef?.load) {
                    nav.load(() => this.activeTabComponentRef.load(this.selectedRestaurantId));
                }
            }
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.management-view {
    overflow: hidden;

    min-height: 100vh;
    height: 100vh;

    background-color: rgb(242, 242, 242);

    .main {

    }
}
</style>
