<template>
    <div class="flex flex--100 add-allergen-modal-box">
        <modal-box ref="window">
            <form class="flex flex--100 add-allergen-form">
                <div class="flex flex--100" v-for="requiredLocalization in requiredLocalizations" :key="requiredLocalization.iso">
                    <h2>{{ requiredLocalization.name }}</h2>
                    <div class="flex flex--100">
                        <simple-textbox placeholder="Name" v-model:model-value="localizations[requiredLocalization.iso].name" ref="enNameTextbox"/>
                    </div>
                    <hr class="separator"/>
                </div>
                <div class="flex flex--100">
                    <simple-button text="Add" @click="addAllergen" :is-loading="isWaitingServerResponse"/>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import { notificationCenter } from "@/components/utilities/NotificationCenter";
import { user } from "@/user";

export default {
    name: "AddAllergenModalBox",
    props: {
        restaurantId: {
            type: String,
        },
        closeOnAllergenAdd: {
            type: Boolean,
            default: true,
        },
    },
    emits: [ "allergen-add" ],
    components: {
        SimpleButton,
        SimpleTextbox,
        ModalBox,
    },
    data () {
        return {
            requiredLocalizations: [{
                name: "English",
                iso: "en",
            }, {
                name: "Chinese",
                iso: "zh",
            }, {
                name: "Italian",
                iso: "it",
            }],
            localizations: {
                "en": {},
                "zh": {},
                "it": {},
            },
            isWaitingServerResponse: false,
        };
    },
    methods: {
        validateAllergenCategoryForm () {/*
             const nameTextbox = this.$refs.nameTextbox;
             nameTextbox.validate(ProductAddErrorType.MISSING_NAME, this.name.length > 0);
             return nameTextbox.errors.length === 0;*/return true;
        },
        clearAllergenForm () {
            this.localizations = {
                "en": {},
                "zh": {},
                "it": {},
            };
        },
        async addAllergen () {
            if (!this.validateAllergenCategoryForm() || this.isWaitingServerResponse) {
                return;
            }
            this.isWaitingServerResponse = true;
            try {
                const allergen = await user.addAllergen({
                    restaurantId: this.restaurantId,
                    localizations: Object.keys(this.localizations).map((languageIso) => {
                        return {
                            languageIso,
                            ...this.localizations[languageIso],
                        };
                    }),
                });
                if (allergen) {
                    this.$emit("allergen-add", allergen);
                    if (this.closeOnAllergenAdd) {
                        this.$refs.window.hide();
                    }
                    this.clearAllergenForm();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.allergenAddedSuccess`), });
                }
                else{
                  notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.isWaitingServerResponse = false;
        },
    },
    computed: {
        window () {
            return this.$refs.window;
        },
    },
    async mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>
<style lang="scss" scoped>
.add-allergen-form {
    padding: 50px;
}
.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}
.add-allergen-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }
}
</style>
