import { isDesktop, isKiosk, } from "@/main";
import { server } from "@/server";

export const AUTHENTICATION_TOKEN_STORAGE_KEY = "FortuneRMS::AuthenticationToken";
export const USER_DESCRIPTOR_STORAGE_KEY = "FortuneRMS::User";

export const user = Object.freeze({
  get authenticationToken() {
    if (isDesktop || isKiosk) {
      return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNjM0MDUyOTA2fQ.Z7LSjl6Npiuh_IF_e4k4BkNeJoPobXp1tbHBt0h2aJE";
    }

    return localStorage.getItem(AUTHENTICATION_TOKEN_STORAGE_KEY);
  },

  set authenticationToken(token) {
    localStorage.setItem(AUTHENTICATION_TOKEN_STORAGE_KEY, token);
  },

  setAuthenticationToken(token) {
    this.authenticationToken = token;
  },

  get descriptor() {
    return JSON.parse(localStorage.getItem(USER_DESCRIPTOR_STORAGE_KEY));
  },

  set descriptor(descriptor) {
    localStorage.setItem(
      USER_DESCRIPTOR_STORAGE_KEY,
      JSON.stringify(descriptor)
    );
  },

  get isLoggedIn() {
    return this.authenticationToken !== null;
  },

  get isAnonymous() {
    return !this.isLoggedIn;
  },

  get authenticationHeaders() {
    const headers = {};

    if (this.authenticationToken) {
      headers["Authentication-Token"] = this.authenticationToken;
    }

    return headers;
  },

  async signup(username, password, fullName, email, mobile) {
    return (
      await server.post("user/signup", {
        username,
        password,
        fullName,
        email,
        mobile,
      })
    ).data;
  },

  async login(username, password) {
    const response = await server.post("user/login", { username, password });
    const responseData = response.data;

    if (responseData?.result?.token) {
      this.setAuthenticationToken(responseData.result.token);
      this.descriptor = responseData.result.user;
    }

    return responseData;
  },

  async changePassword(username, oldPassword, newPassword) {
    return (
      await server.post("user/change-password", {
        username,
        oldPassword,
        newPassword,
      })
    ).data;
  },

  async uploadImage(file) {
    const formData = new FormData();

    formData.append("image", file);

    const response = await server.post("user/upload", formData, {
      "Content-Type": "multipart/form-data",
    });

    return response?.data;
  },

  async getRestaurants() {
    return (await server.get("user/restaurants")).data?.result?.restaurants;
  },

  async addProduct(descriptor) {
    return (await server.post("product", descriptor)).data?.result?.product;
  },

  async updateProduct(descriptor) {
    return (await server.post("product/update", descriptor)).data?.result
      ?.product;
  },

  async deleteProduct(id) {
    return (await server.post("product/delete", { id })).data?.result?.id;
  },

  async archiveProduct(id) {
    return (await server.post("product/archive", { id })).data?.result?.id;
  },

  async unarchiveProduct(id) {
    return (await server.post("product/unarchive", { id })).data?.result?.id;
  },

  async addCategory(descriptor) {
    return (await server.post("category", descriptor)).data?.result?.category;
  },

  async removeCategory({ id }) {
    return (await server.post("category/delete", { id })).data;
  },

  async addAllergen(descriptor) {
    return (await server.post("allergen", descriptor)).data?.result?.allergen;
  },

  async updateAllergen(descriptor) {
    return (await server.post("allergen/update", descriptor)).data?.result
      ?.allergen;
  },

  async removeAllergen({ id }) {
    return (await server.post("allergen/remove", { id })).data?.result?.id;
  },

  async setActiveCategory(descriptor) {
    return (await server.post("restaurant/categories/active", descriptor)).data
      ?.result?.activeCategories;
  },

  async addVariation(descriptor) {
    return (await server.post("variation", descriptor)).data?.result?.variation;
  },

  async updateVariation(descriptor) {
    return (await server.post("variation/update", descriptor)).data?.result
      ?.variation;
  },

  async addScene(descriptor) {
    return (await server.post("scene", descriptor)).data?.result?.scene;
  },

  async updateScene(descriptor) {
    return (await server.post("scene/update", descriptor)).data?.result
      ?.scene;
  },

  async archiveVariation(descriptor) {
    return (await server.post("variation/archive", descriptor)).data?.result
      ?.variation;
  },

  async unarchiveVariation(descriptor) {
    return (await server.post("variation/unarchive", descriptor)).data?.result
      ?.variation;
  },

  async setConfiguration(descriptor) {
    return (await server.post("restaurant/configuration", descriptor)).data
      ?.result?.configuration;
  },

  async openRestaurant(descriptor) {
    return (await server.post("restaurant/open", descriptor)).data?.result
      ?.restaurant;
  },

  async closeRestaurant(descriptor) {
    return (await server.post("restaurant/close", descriptor)).data?.result
      ?.restaurant;
  },

  async createTable(descriptor) {
    return (await server.post("table", descriptor)).data?.result?.table;
  },

  async deleteTable(descriptor) {
    return (await server.post("table/delete", descriptor)).data?.result?.id;
  },

  async updateTable(descriptor) {
    return (await server.post("table/update", descriptor)).data?.result?.table;
  },

  async updateProductAvailableVolume(descriptor) {
    return (await server.post("product/update/available-volume", descriptor))
      .data?.result?.product;
  },

  async updateProductCategoryVisibilityAndIndex(descriptor) {
    return (await server.post("product/update/visibility", descriptor)).data;
  },

  async updateCategory(descriptor) {
    return (await server.post("category/update", descriptor)).data?.result
      ?.category;
  },

  async bulkCreateVariations(descriptor) {
    return (await server.post("variation/bulkCreate", descriptor)).data;
  },

  async bulkCreateCategories(descriptor) {
    return (await server.post("category/bulkCreate", descriptor)).data;
  },

  async bulkCreateAllergens(descriptor) {
    return (await server.post("allergen/bulkCreate", descriptor)).data;
  },

  logout() {
    localStorage.removeItem(AUTHENTICATION_TOKEN_STORAGE_KEY);
    localStorage.removeItem(USER_DESCRIPTOR_STORAGE_KEY);
  },
});
