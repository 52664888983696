<template>
    <div class="flex flex--100 add-product-special-request-modal-box">
        <modal-box ref="modalBox" :append-to-body="true" :show-close-button="false" :show-back-button="true" :close-on-background-click="true">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center header">
                <h2 class="title">{{ getProductLocalizedName(product) }} {{ product.price }} €</h2>
            </div>
            <div class="flex flex--100 request-box">
                <div class="flex flex--100 suggested-special-request-list">
                    <simple-button
                        class="suggested-special-request-button"
                        v-for="suggestedSpecialRequest in suggestedSpecialRequests"
                        :text="suggestedSpecialRequest"
                        :key="suggestedSpecialRequest"
                        @click="addSuggestedSpecialRequest(suggestedSpecialRequest)"
                    />
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center note-box">
                <simple-textbox :placeholder="$t('checkout.productMessage')" :is-multiline="true" v-model:model-value="specialRequest" ref="specialRequestTextbox"/>
                <div class="flex flex--100 flex--x-align-center btn-box">
                    <simple-button class="confirm-btn" :text='$t("generic.confirm")' @click="confirm"/>
                </div>
            </div>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";

export default {
    name: "AddProductSpecialRequestModalBox",
    components: {
        SimpleTextbox,
        SimpleButton,
        ModalBox,
    },
    data () {
        return {
            specialRequest: "",
            product: undefined,
            volume: 0,
        };
    },
    computed: {
        suggestedSpecialRequests () {
            return this.$i18n.tm("order.suggestedSpecialRequests");
        },
    },
    methods: {
        show ({ product, specialRequest, }) {
            this.product = product;
            this.specialRequest = specialRequest;

            this.$refs.modalBox.show();
        },

        hide () {
            this.specialRequest = "";
            this.product = undefined;
            this.volume = 0;

            this.$refs.modalBox.hide();
        },

        confirm () {
            this.$emit("confirm", {
                product: this.product,
                specialRequest: this.specialRequest,
            });

            this.hide();
        },

        getProductLocalization (product, languageIso) {
            return product.localizations.find((localization) => localization.languageIso === languageIso);
        },

        getProductLocalizedName (product) {
            return this.getProductLocalization(product, this.$i18n.locale)?.name ?? "";
        },

        addSuggestedSpecialRequest (text) {
            let normalizedText = "";

            if (this.specialRequest.length > 0) {
                normalizedText += ", ";
            }

            normalizedText += text;
            this.specialRequest += normalizedText;
        },
    },
    mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.add-product-special-request-modal-box {
    
}

.add-special-request-form {
    padding: 20px;
    height: 100%;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background-color: rgb(231, 231, 231);
}

.modal-box {
    :deep(.modal-box__slot) {
        height: auto;
        min-height: initial;
    }
}

.simple-textbox {
    width: 100%;

    :deep(.simple-textbox__text-input.simple-textbox__text-input--multiline) {
        min-height: 120px;
    }
}

.simple-button {
    background-color: rgb(87, 61, 28);
    & + & {
        margin-left: 16px;
    }
}

.suggested-special-request-list {
    padding: 14px;
    height: 100%;

    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);

    .simple-button {
        margin: 6px 10px;
    }
}

.title {
    text-align: center;
    font-weight: 600;
    font-size: 1.6rem;
    text-size-adjust: auto;

    color: rgb(11, 11, 11);
}

@media only screen and (max-width: 600px) {
    .modal-box {
        :deep(.modal-box__slot) {
            overflow: hidden;

            width: 100vw;
            min-height: 90vh;
            height: calc(var(--vh, 1vh) * 90);
            margin: 10vh 0 0 0;
            margin: calc(var(--vh, 1vh) * 10) 0 0 0;

            background-color: rgb(255, 255, 255);
            border-radius: 0!important;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.092), 0 0 6px 0 rgba(0, 0, 0, 0.104);
        }
    }

    .suggested-special-request-button {
        min-width: 30%;
    }

    .header {
        height: 10%;
        width: 100%;
        padding: 5px;
    }

    .request-box {
        height: 50%;
        padding: 0 20px;
    }
    .note-box {
        height: 30%;
        padding: 20px 20px 0px 20px;
    }

    .btn-box {
        margin-top: 25px;
    }

    .confirm-btn {
        border-radius: 0;
        :deep(.simple-button__text) {
            font-size: 16px;
            font-weight: 800;
        }
    }
}


</style>
