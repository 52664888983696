<template>
    <div class="flex flex--100 add-product-special-request-modal-box">
        <modal-box ref="modalBox" :append-to-body="true" :show-close-button="false" :show-back-button="true" :close-on-background-click="true">
            <div class="flex flex--100 flex--x-align-center flex--y-align-center header">
                <h2 class="title">{{ $t('checkout.categoryTimes').toLocaleUpperCase() }}</h2>
            </div>
            <div class="flex flex--100 request-box">
                <div class="flex flex--100 suggested-special-request-list">
                    <splide :options="splideOptions" ref="categorySlider" class="category-slider">
                        <splide-slide v-for="category in userListedCategories" :key="category.id">
                            <div class="flex flex--100 flex--x-align-center slide-category">
                                <span class="flex flex--100 flex--x-align-center slide-category__text"> {{ restaurantProcessor.getCategoryLocalizedName(category.id)}}</span>
                                <ul class="slide-category__product-list">
                                    <li class="slide-category__product" v-for="product in getThreeCategoryProducts(category.id)" :key="product.id">
                                        {{ restaurantProcessor.getProductLocalizedName(product.id) }}
                                    </li>
                                    <li class="slide-category__product" v-show="getCategoryProducts(category.id).length > 1">...</li>
                                </ul>
                                <div class="flex flex--100 flex--x-align-center flex--y-align-center slide-category__option-list">
                                    <span class="option-title">{{ $t("checkout.timeToWait")}}</span>
                                    <template v-for="option in options" :key="option.text">
                                        <simple-button class="option" :text="option.text" @click="selectOption(category, option)" :class="getOptionModifiers(categoryTimes[category.id] === option.value)"/>
                                    </template>
                                </div>
                            </div>
                        </splide-slide>
                    </splide>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center btn-box">
                <simple-button class="confirm-btn" :text='$t("generic.confirm")' @click="confirm"/>
            </div>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import { RestaurantProcessor, BasketProcessor, } from "@/utilities";

export default {
    name: "CategoryTimesModalBox",
    components: {
        SimpleButton,
        ModalBox,
    },
    props: {
        restaurantPresentation: {
            type: Object,
        },
        basket: {
            type: Object,
        },
    },
    data () {
        return {
            categoryTimes: {},
            product: undefined,
            volume: 0,
        };
    },
    computed: {
        // <restaurant>
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, })
        },
        // <restaurant>
        
        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                basket: this.basket,
                restaurantProcessor: this.restaurantProcessor,
            });
        },
        // <basket>

        listedCategories () {
            return this.restaurantProcessor.listedCategories;
        },

        userListedCategories () {
            const categories = this.basketProcessor.selectedProductsIds.map((id) => this.restaurantProcessor.getProductById(id)).flatMap((p) => p.categories);
            return this.listedCategories.filter((c) => {
                return categories.find((cc) => c.id === cc.id);
            });
        },

        suggestedSpecialRequests () {
            return this.$i18n.tm("order.suggestedSpecialRequests");
        },

        splideOptions () {
            return {
                type: "slide",
                width: "100%",
                arrows: true,
                pagination: true,
                drag: true,
                trimSpace: "move",
                rewind : true,
            };        },

        options () {
            return [ { text: this.$t("categoryTimes.0"), value: 0, }, { text: this.$t("categoryTimes.10"), value: 10, }, { text: this.$t("categoryTimes.20"), value: 20, }, { text: this.$t("categoryTimes.30"), value: 30, }];
        }
    },
    methods: {
        show () {
            this.$refs.modalBox.show();
        },

        hide () {
            this.specialRequest = "";
            this.product = undefined;
            this.volume = 0;

            this.$refs.modalBox.hide();
        },

        confirm () {
            this.$emit("confirm", this.categoryTimes);

            this.hide();
        },

        addSuggestedSpecialRequest (text) {
            let normalizedText = "";

            if (this.specialRequest.length > 0) {
                normalizedText += ", ";
            }

            normalizedText += text;
            this.specialRequest += normalizedText;
        },

        getCategoryLocalizedName (id) {
            return this.restaurantProcessor.getCategoryLocalizedName(id);
        },

        selectOption (category, option) {
            this.categoryTimes[category.id] = option.value;
            window.setTimeout(() => {
                this.$refs.categorySlider.go(">");
            }, 100);
        },

        getOptionModifiers (value) {
             return {
                "option--selected": value,
            };
        },

        getCategoryProducts (categoryId) {
            const productsObj = this.restaurantProcessor.getListedProductsByCategoryId(categoryId);
            const products = this.basketProcessor.selectedProductsIds.map((id) => productsObj.find((p) => p.id === id)).filter((p) => p);
            return products;
        }, 

        getThreeCategoryProducts (categoryId) {
            return this.getCategoryProducts(categoryId).slice(0, 1);
        },
    },
    mounted () {
        window.document.body.appendChild(this.$el);
    },
};
</script>

<style lang="scss" scoped>
.add-product-special-request-modal-box {
    
}

.add-special-request-form {
    padding: 20px;
    height: 100%;
}

.separator {
    width: 100%;
    height: 1px;
    margin: 10px 0;
    background-color: rgb(231, 231, 231);
}

.modal-box {
    :deep(.modal-box__slot) {
        height: auto;
        min-height: initial;
    }
}

.simple-textbox {
    width: 100%;

    :deep(.simple-textbox__text-input.simple-textbox__text-input--multiline) {
        min-height: 120px;
    }
}

.simple-button {
    background-color: rgb(87, 61, 28);
    & + & {
        margin-left: 16px;
    }
}

.suggested-special-request-list {
    padding: 14px;
    height: 100%;
    width: 100%;

    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);

    .simple-button {
        margin: 6px 10px;
    }
}

.title {
    text-align: center;
    font-weight: 600;
    font-size: 1.6rem;

    color: rgb(11, 11, 11);
}

@media only screen and (max-width: 600px) {
    .modal-box {
        :deep(.modal-box__slot) {
            overflow: hidden;

            width: 100vw;
            min-height: 90vh;
            height: calc(var(--vh, 1vh) * 90);
            margin: 10vh 0 0 0;
            margin: calc(var(--vh, 1vh) * 10) 0 0 0;

            background-color: rgb(255, 255, 255);
            border-radius: 0!important;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.092), 0 0 6px 0 rgba(0, 0, 0, 0.104);
        }
    }

    .suggested-special-request-button {
        min-width: 30%;
    }

    .header {
        height: 10%;
        width: 100%;
        padding: 5px;
    }

    .request-box {
        height: 70%;
        padding: 0 20px;
    }

    .btn-box {
        height: 20%;
        padding: 20px 20px 0px 20px;
        margin-top: 25px;
    }

    .confirm-btn {
        border-radius: 0;
        :deep(.simple-button__text) {
            font-size: 16px;
            font-weight: 800;
        }
    }
}

.slide-category {
    cursor: pointer;

    margin-right: 1rem;
    padding: 12px 20px;

    background-color: transparent;

    width: 100%;
    height: 60vh;

    align-content: space-around;
    &--selected {
        background-color: rgb(67, 44, 8);
    }
    &__text {
        font-size: 28px;
        font-weight: 700;
        color: rgb(7, 7, 7);
    }
    &__product {
        font-size: 1.4rem;
        font-weight: 500;
        color: rgb(7, 7, 7);
    }
    &__product-list {
        padding: 10px;
        max-height: 25vh;
    }
}

.category-slider {
    width: 100%;
}

.option {
    background-color: rgb(164, 114, 56);

    &--selected {
        background-color: rgb(67, 44, 8);
    }
}

.option-title {
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1.3;
    color: rgb(7, 7, 7);
    margin-bottom: 15px;
}

</style>
