<template>
    <div class="flex primary-user-navigation-tab" v-show="isActive">
        <div class="flex primary-user-navigation-tab__box">
            <slot/>
            <div class="flex flex--x-align-center flex--y-align-center primary-user-navigation-tab__loading-overlay" v-show="isLoading">
                <loading-spinner/>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingSpinner from "@/components/utilities/LoadingSpinner";

export default {
    name: "PrimaryUserNavigationTab",
    components: {
        LoadingSpinner,
    },
    data () {
        return {
            isActive: true,
            isLoading: false,
        };
    },
    methods: {
        show () {
            this.isActive = true;
        },

        hide () {
            this.isActive = false;
        },

        async load (fnTabLoading) {
            this.isLoading = true;
            await fnTabLoading();
            this.isLoading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.primary-user-navigation-tab {
    position: relative;

    width: 78vw;
    height: 100vh;
    background-color: rgb(242, 242, 242);

    &__box {
        overflow: auto;

        width: inherit;
        height: inherit;

        background-color: inherit;
    }

    &__loading-overlay {
        position: absolute;
        left: 0;
        top: 0;

        width: 100%;
        height: 100%;

        background-color: inherit;

        z-index: 10;
    }
}
</style>

<style lang="scss">
.primary-user-navigation-tab {
    & > &__box > &__loading-overlay > .loading-spinner {
        transform: scale(0.35);

        .spinner__bar {
            background-color: rgb(70, 70, 70);
        }
    }
}
</style>
