<template>
    <svg xmlns="http://www.w3.org/2000/svg" class="flex primary-logo" viewBox="0 0 901.000000 901.000000">


<g transform="translate(0.000000,901.000000) scale(0.100000,-0.100000)"
fill="#000000" stroke="none">
<path d="M5162 8829 c-443 -93 -759 -314 -848 -594 -51 -161 -42 -310 26 -403
l30 -42 -49 -93 c-110 -206 -210 -480 -281 -769 -22 -89 -40 -170 -40 -180 0
-41 -18 -12 -60 95 -104 269 -283 543 -476 728 -60 57 -208 179 -238 196 -14
8 -15 14 -6 37 57 151 -90 331 -355 436 -66 26 -266 80 -299 80 -14 0 1 -146
24 -234 64 -250 215 -407 407 -423 61 -5 78 -3 118 17 55 27 50 29 160 -59
309 -244 551 -622 655 -1023 l23 -88 -22 -177 c-38 -317 -42 -382 -48 -773 -6
-394 3 -648 33 -953 8 -87 14 -164 12 -171 -2 -8 -42 23 -98 76 -355 336 -777
590 -1244 748 l-109 37 -18 89 c-12 58 -33 118 -61 174 -36 70 -55 94 -113
143 -280 235 -779 294 -1343 158 -160 -39 -425 -122 -445 -140 -9 -9 120 -205
201 -306 86 -106 297 -314 390 -384 228 -171 436 -256 659 -268 229 -12 470
61 584 178 47 48 119 175 119 210 0 10 4 20 8 23 5 3 65 -14 133 -38 476 -167
888 -430 1246 -797 99 -101 243 -272 243 -288 0 -4 -18 -52 -40 -107 -23 -54
-66 -174 -96 -267 l-56 -167 -52 47 c-100 90 -282 191 -408 224 -42 11 -48 17
-48 39 0 44 -30 132 -62 183 -73 114 -171 166 -331 175 -218 11 -441 -62 -687
-226 -139 -93 -310 -231 -310 -250 0 -12 130 -97 230 -150 123 -65 253 -116
366 -143 118 -29 311 -32 398 -5 131 39 287 141 339 222 14 22 30 34 45 34 32
0 186 -64 257 -107 63 -38 215 -179 215 -200 0 -18 -235 -966 -274 -1104 -19
-68 -47 -160 -62 -204 -130 -390 -410 -608 -840 -654 -107 -12 -323 -9 -345 4
-16 10 -152 120 -199 161 -165 143 -289 268 -420 422 -111 131 -97 113 -175
221 -248 345 -424 698 -548 1101 -90 291 -143 598 -155 903 -7 178 -1 431 13
573 7 66 6 73 -16 93 -25 23 -66 27 -92 9 -25 -19 -38 -109 -46 -315 -31 -860
182 -1629 645 -2323 46 -69 92 -135 102 -146 11 -11 21 -25 23 -31 7 -20 225
-275 311 -364 311 -321 715 -612 1108 -797 168 -79 207 -92 236 -82 32 13 50
53 38 86 -10 29 -11 30 -199 119 -157 74 -325 165 -325 175 0 4 54 14 120 23
206 29 419 113 560 222 126 97 240 241 316 398 31 65 54 93 158 194 497 484
1055 753 1562 753 99 0 107 -4 117 -56 10 -53 56 -139 97 -180 45 -46 142 -97
232 -121 49 -14 100 -18 233 -17 156 1 180 3 285 31 208 55 520 186 520 218 0
24 -222 242 -324 318 -108 80 -262 158 -371 189 -121 33 -273 31 -380 -6 -147
-51 -234 -117 -275 -209 l-19 -41 -103 3 c-57 1 -105 5 -107 7 -3 3 15 24 40
47 75 71 207 218 242 269 26 39 38 48 62 48 122 1 221 106 271 287 33 120 25
503 -10 503 -6 0 -44 -16 -84 -35 -233 -111 -378 -283 -390 -459 -6 -98 4
-157 34 -201 13 -20 24 -39 24 -43 0 -24 -158 -201 -281 -316 -139 -129 -146
-133 -280 -170 -186 -51 -336 -114 -522 -220 -178 -100 -414 -271 -547 -395
-30 -28 -58 -51 -62 -51 -4 0 4 39 18 87 13 49 40 152 59 229 19 78 64 262
100 410 192 782 331 1151 570 1509 283 423 676 722 1207 919 l126 47 63 -46
c149 -109 324 -169 499 -168 123 0 201 18 314 71 95 44 248 149 342 234 38 35
43 37 75 27 19 -5 69 -10 112 -10 177 -2 334 105 398 271 25 64 36 197 21 252
l-10 37 72 54 c131 99 189 215 189 377 0 77 -6 111 -27 175 -15 44 -33 87 -39
95 -7 8 -20 31 -30 50 -33 66 -487 665 -542 716 -105 98 -259 151 -405 140
-122 -8 -227 -51 -345 -140 -7 -5 -26 1 -51 17 -196 130 -481 57 -592 -151
-34 -63 -62 -157 -62 -207 0 -25 -11 -39 -65 -82 -122 -99 -243 -272 -325
-463 -79 -184 -110 -315 -110 -465 0 -224 83 -401 275 -588 57 -55 67 -69 55
-77 -8 -5 -18 -10 -22 -10 -20 0 -315 -134 -398 -180 -315 -175 -599 -421
-801 -695 -66 -89 -179 -272 -224 -363 -22 -44 -43 -81 -46 -81 -15 -2 -63 75
-70 113 -11 57 -46 397 -61 596 -16 212 -16 860 0 1060 50 629 147 1075 324
1500 53 126 128 270 140 270 5 0 35 -7 66 -16 38 -11 79 -15 133 -12 68 4 85
9 146 46 262 155 429 521 469 1025 8 102 18 96 -124 66z m38 -91 c-1 -30 -30
-221 -45 -289 -38 -174 -113 -354 -190 -459 -50 -68 -162 -170 -209 -190 -23
-10 -60 -21 -83 -25 -48 -8 -153 11 -153 27 0 16 96 154 167 240 35 42 111
122 168 176 80 76 105 105 105 125 0 32 -24 57 -55 57 -59 0 -309 -257 -430
-442 -27 -43 -54 -76 -60 -74 -5 1 -18 18 -28 37 -26 48 -19 219 11 302 84
231 336 409 722 511 70 18 80 19 80 4z m-2417 -558 c50 -17 118 -45 151 -63
74 -39 171 -127 197 -178 19 -39 25 -94 11 -103 -5 -3 -51 17 -102 44 -107 55
-139 54 -140 -6 0 -17 17 -30 73 -59 39 -20 78 -43 86 -50 24 -24 -30 -29 -96
-10 -79 23 -128 54 -175 111 -50 61 -80 120 -106 209 -36 123 -37 135 -12 135
12 0 63 -14 113 -30z m4196 -645 c79 -19 150 -57 202 -107 22 -21 125 -153
229 -293 104 -140 202 -268 217 -285 78 -87 141 -226 150 -331 12 -143 -50
-253 -200 -353 -56 -38 -77 -46 -113 -46 -66 0 -103 20 -180 97 -64 65 -177
210 -188 243 -3 10 29 40 95 89 145 108 184 172 184 306 0 71 -4 88 -33 148
-60 122 -169 184 -306 175 -89 -6 -157 -39 -217 -106 -57 -63 -82 -134 -81
-225 2 -95 30 -149 184 -359 68 -91 141 -192 163 -224 58 -82 173 -194 236
-229 46 -25 65 -29 127 -30 68 0 73 -2 79 -23 10 -40 -4 -130 -30 -185 -29
-63 -81 -118 -140 -149 -47 -24 -166 -36 -206 -20 -22 8 -25 14 -22 58 6 121
-52 323 -145 505 -97 189 -236 384 -423 592 -103 116 -142 237 -121 374 31
195 172 343 365 383 62 12 104 11 174 -5z m-621 -80 c28 -13 52 -26 52 -28 0
-3 -11 -25 -25 -49 -43 -77 -66 -159 -72 -253 -10 -167 47 -317 171 -445 200
-207 401 -522 475 -746 31 -94 53 -212 40 -219 -4 -3 -47 15 -95 39 -104 50
-211 94 -253 102 -39 8 -45 17 -60 89 -7 33 -29 111 -48 174 -105 349 -280
659 -532 940 l-92 103 7 46 c31 217 237 335 432 247z m758 -400 c84 -25 146
-109 146 -197 0 -81 -33 -132 -140 -215 -51 -40 -97 -73 -102 -73 -13 0 -119
145 -147 199 -60 116 4 251 137 292 38 11 52 10 106 -6z m-1041 -271 c128
-175 230 -365 309 -577 35 -93 86 -268 86 -294 0 -9 -22 -13 -76 -13 -100 0
-204 -17 -287 -47 -38 -13 -71 -22 -74 -19 -17 16 -113 476 -139 666 -19 132
-26 449 -11 485 8 18 14 14 63 -40 30 -33 88 -106 129 -161z m-319 3 c2 -234
50 -547 138 -894 l33 -132 -51 -48 c-29 -26 -66 -65 -84 -85 -17 -21 -36 -38
-41 -38 -20 0 -207 198 -240 253 -84 142 -112 274 -91 421 22 159 118 406 210
542 42 61 110 144 118 144 4 0 7 -73 8 -163z m-3970 -1002 c247 -47 438 -157
502 -288 32 -64 57 -145 48 -154 -2 -3 -47 4 -98 16 -237 53 -482 80 -748 82
-168 1 -175 0 -192 -21 -22 -27 -22 -39 -2 -68 14 -20 23 -22 122 -22 242 0
550 -34 790 -86 140 -31 141 -32 96 -122 -40 -80 -98 -132 -190 -169 -129 -53
-201 -65 -349 -61 -114 4 -142 8 -225 37 -208 71 -371 180 -575 386 -120 120
-275 308 -275 332 0 12 169 65 316 98 93 21 229 43 364 59 72 9 338 -3 416
-19z m4739 -20 c38 -8 99 -24 135 -36 74 -24 287 -126 297 -142 12 -20 -189
-172 -305 -231 -74 -37 -192 -66 -271 -66 -87 0 -215 32 -304 75 -74 36 -208
131 -214 152 -7 21 133 149 204 185 66 35 143 63 193 71 59 10 200 6 265 -8z
m-3479 -1676 c71 -13 130 -45 174 -95 37 -42 70 -114 70 -151 0 -23 -3 -25
-32 -19 -130 24 -351 32 -482 17 -75 -8 -96 -24 -96 -71 0 -55 17 -59 235 -60
110 0 227 -5 260 -10 33 -5 63 -10 68 -10 39 0 -50 -89 -147 -149 -91 -55
-179 -73 -326 -68 -141 5 -240 30 -396 98 -104 46 -244 125 -244 139 0 5 30
33 68 63 306 244 613 359 848 316z m2749 -205 c19 -200 -47 -429 -140 -490
-35 -23 -40 -13 -17 33 27 51 52 139 52 179 0 46 -39 76 -82 64 -40 -12 -37
-8 -54 -79 -15 -65 -58 -161 -73 -161 -17 0 -34 90 -28 147 9 88 55 169 142
251 63 60 164 132 186 132 4 0 10 -34 14 -76z m300 -794 c102 -26 219 -83 323
-157 84 -61 272 -228 272 -243 0 -11 -104 -59 -213 -99 -205 -76 -426 -112
-570 -93 -164 21 -271 72 -328 157 -27 39 -52 105 -44 113 9 9 202 -41 289
-74 48 -19 98 -34 110 -34 47 0 76 69 46 106 -24 28 -209 94 -337 120 -111 22
-106 19 -89 52 19 37 74 86 129 114 115 59 272 74 412 38z"/>
<path d="M4010 8204 c-145 -20 -366 -66 -523 -110 -136 -37 -177 -61 -177
-104 0 -24 42 -70 64 -70 9 0 70 15 135 34 151 44 318 79 501 106 177 25 174
25 188 56 17 37 15 52 -13 79 -27 28 -30 28 -175 9z"/>
<path d="M5230 8140 c-25 -25 -26 -72 -1 -94 11 -10 68 -29 127 -42 156 -35
298 -78 453 -135 176 -66 184 -67 215 -36 54 54 20 102 -104 148 -233 85 -566
179 -637 179 -20 0 -41 -8 -53 -20z"/>
<path d="M2475 7644 c-235 -148 -456 -325 -656 -524 -169 -169 -259 -272 -393
-452 -80 -108 -226 -329 -226 -343 0 -3 -11 -23 -25 -45 -44 -70 -25 -130 43
-130 38 0 52 14 99 98 88 157 271 414 406 567 105 120 319 330 432 424 116 96
278 212 420 301 55 35 103 68 107 74 14 21 8 73 -10 89 -35 32 -71 20 -197
-59z"/>
<path d="M7942 5974 c-32 -22 -28 -57 19 -165 86 -201 181 -520 223 -749 114
-627 63 -1300 -144 -1898 -22 -62 -27 -89 -20 -107 17 -44 83 -61 112 -27 51
58 170 505 212 792 57 393 52 821 -15 1220 -35 214 -110 488 -187 691 -75 196
-84 218 -104 238 -22 25 -65 27 -96 5z"/>
<path d="M7715 2670 c-4 -12 -5 -22 -3 -24 1 -1 61 -28 131 -61 70 -32 130
-62 132 -65 3 -4 -18 -19 -47 -33 l-51 -25 -55 24 c-62 28 -120 31 -162 9 -30
-16 -65 -69 -56 -84 3 -5 36 -21 73 -37 38 -16 111 -49 163 -75 65 -32 97 -43
102 -35 11 16 10 36 -3 36 -21 0 -239 109 -239 119 0 6 4 11 9 11 5 0 24 12
41 26 l31 27 65 -27 c106 -43 159 -33 203 38 26 41 26 46 2 46 -15 0 -236 101
-323 148 -3 2 -9 -6 -13 -18z m305 -178 c0 -3 -7 -14 -17 -24 -19 -21 -87 -26
-81 -5 6 18 98 45 98 29z m-260 -7 c0 -12 -67 -45 -92 -45 -34 0 -36 13 -6 34
21 15 98 23 98 11z"/>
<path d="M7545 2299 c-73 -64 -68 -160 11 -236 99 -96 259 -77 292 35 14 45 6
101 -15 109 -22 9 -26 -4 -9 -29 43 -61 -5 -148 -82 -148 -37 0 -102 17 -102
26 0 3 10 15 21 27 28 29 59 96 59 129 0 26 -1 27 -31 16 -33 -11 -66 -55 -76
-101 -8 -39 -21 -43 -50 -16 -83 78 -65 179 36 195 47 8 55 24 11 24 -19 0
-42 -11 -65 -31z m155 -110 c0 -34 -49 -109 -72 -109 -24 0 -2 67 35 108 25
27 37 28 37 1z"/>
<path d="M7435 2035 c-5 -2 -22 -6 -38 -9 -32 -8 -66 -41 -125 -125 l-42 -60
22 -12 c13 -7 83 -54 156 -106 72 -51 134 -93 137 -93 2 0 9 9 15 20 9 17 7
22 -15 32 l-27 12 44 13 c24 7 55 24 71 38 23 22 27 34 27 81 0 84 -41 150
-115 186 -30 15 -92 28 -110 23z m74 -50 c74 -38 131 -108 131 -160 0 -22 -4
-25 -35 -25 -42 0 -94 -20 -114 -43 -7 -10 -17 -17 -22 -17 -16 0 -189 122
-189 133 0 15 65 108 88 125 21 17 98 10 141 -13z m111 -220 c0 -29 -99 -61
-114 -36 -6 9 20 35 44 44 31 11 70 7 70 -8z"/>
<path d="M7153 1753 c-11 -10 -44 -44 -72 -75 l-53 -57 39 -33 c21 -18 84 -77
140 -131 93 -91 102 -97 112 -79 8 16 6 23 -10 38 -44 40 -61 103 -39 144 22
40 109 32 161 -16 25 -24 26 -24 43 -5 16 18 15 20 -27 40 -28 14 -63 21 -99
21 l-56 0 15 30 c18 38 4 90 -34 120 -32 25 -94 26 -120 3z m99 -44 l31 -30
-36 -35 c-23 -23 -39 -50 -44 -75 l-8 -39 -63 56 -63 57 47 48 c59 60 91 64
136 18z m2 -116 c-35 -61 -34 -61 -34 -31 0 27 34 78 52 78 5 0 -2 -21 -18
-47z"/>
<path d="M6941 1542 c-50 -64 -71 -119 -71 -193 0 -56 5 -77 35 -135 39 -77
81 -132 91 -121 4 4 -2 17 -13 30 -57 62 -66 136 -22 183 28 30 34 30 71 -1
32 -27 61 -31 104 -15 27 11 36 6 47 -25 2 -5 12 -2 22 8 17 17 17 19 -2 40
-71 80 -173 198 -199 230 l-31 38 -32 -39z m93 -102 l55 -65 -54 -10 c-60 -12
-80 -25 -102 -68 -12 -23 -17 -27 -24 -16 -13 21 -10 109 5 152 15 41 44 79
57 75 5 -2 33 -32 63 -68z m82 -114 c18 -14 18 -15 -6 -24 -25 -10 -71 5 -85
28 -10 16 68 12 91 -4z"/>
<path d="M6666 1299 c-59 -17 -96 -72 -96 -144 0 -58 18 -101 63 -147 91 -92
236 -70 267 41 14 51 13 53 -21 101 -27 38 -33 41 -46 27 -13 -13 -11 -20 21
-62 27 -34 35 -53 30 -69 -29 -97 -124 -111 -207 -29 -70 67 -104 163 -77 213
9 16 15 18 36 10 38 -15 85 -11 123 9 l34 18 -24 15 c-33 21 -69 27 -103 17z
m69 -23 c18 -13 18 -14 -6 -22 -25 -8 -77 -6 -93 4 -14 8 25 32 54 32 14 0 35
-6 45 -14z"/>
<path d="M6274 1069 c-94 -27 -130 -150 -74 -259 37 -73 123 -119 196 -106 60
12 121 108 96 153 -9 15 -15 13 -51 -24 -23 -22 -41 -50 -41 -61 0 -52 -26
-65 -78 -38 -85 44 -145 192 -108 265 10 18 23 30 29 27 55 -22 91 -29 123
-20 43 11 47 21 14 44 -33 24 -68 30 -106 19z m77 -19 c26 -14 24 -28 -5 -36
-27 -6 -90 22 -81 36 8 13 61 13 86 0z m124 -249 c-8 -31 -33 -61 -52 -61 -16
0 -17 14 -2 43 30 58 67 70 54 18z"/>
<path d="M6044 964 c-7 -3 17 -70 64 -178 65 -149 78 -173 95 -168 11 3 17 11
14 21 -12 47 -143 331 -152 330 -5 0 -15 -3 -21 -5z"/>
<path d="M3706 908 c-2 -7 -29 -86 -60 -175 -31 -89 -56 -163 -56 -166 0 -3 9
-7 20 -10 19 -5 35 25 64 120 6 20 13 22 66 20 50 -1 63 2 85 23 14 13 25 27
25 31 0 15 -62 31 -100 25 -22 -3 -40 -3 -40 1 0 3 8 30 17 60 11 35 22 53 33
53 28 0 144 -49 158 -67 11 -15 12 -14 12 10 0 20 -5 27 -19 27 -11 0 -56 14
-101 30 -92 34 -99 35 -104 18z m112 -166 c8 -24 -78 -41 -108 -22 -13 9 -12
12 5 23 44 27 94 26 103 -1z"/>
<path d="M5980 910 c-8 -5 -25 -10 -37 -10 -51 0 -125 -23 -178 -56 -32 -19
-64 -34 -71 -34 -9 0 -14 -11 -14 -32 l0 -33 20 25 c11 14 20 28 20 31 0 3 20
17 45 30 39 20 47 22 55 10 7 -12 62 -159 112 -298 8 -24 16 -27 35 -15 8 5
-5 53 -48 171 -39 106 -57 167 -50 174 15 15 96 21 130 9 l31 -11 -16 25 c-9
13 -17 24 -18 24 0 0 -8 -5 -16 -10z"/>
<path d="M5517 783 c31 -3 50 -12 70 -33 15 -16 30 -27 35 -24 31 19 -44 65
-103 62 l-44 -1 42 -4z"/>
<path d="M4069 766 c-96 -28 -160 -93 -204 -210 -27 -71 -30 -86 -17 -86 4 0
13 20 20 44 22 84 85 130 145 107 24 -9 27 -16 27 -57 0 -40 5 -51 35 -80 30
-28 34 -37 26 -53 -9 -16 -7 -19 13 -23 23 -5 25 1 70 175 25 100 44 182 42
185 -11 10 -117 10 -157 -2z m87 -22 l22 -6 -20 -76 c-28 -114 -26 -111 -50
-79 -27 37 -68 57 -118 57 -46 0 -49 8 -17 37 35 33 98 61 157 72 3 0 15 -2
26 -5z m-50 -221 c7 -28 1 -38 -21 -29 -20 7 -37 53 -29 79 7 21 7 21 25 -2
11 -13 22 -34 25 -48z"/>
<path d="M5435 756 c-32 -32 -40 -69 -24 -107 16 -41 61 -59 141 -59 85 0 110
-17 106 -72 -2 -30 -10 -46 -28 -60 -31 -23 -100 -36 -100 -19 0 36 -99 97
-115 71 -11 -18 42 -78 80 -90 64 -21 146 10 172 65 14 29 15 41 4 75 -15 52
-53 70 -146 70 -77 0 -100 10 -109 45 -7 29 7 62 38 87 11 10 17 18 13 18 -4
0 -19 -11 -32 -24z m49 -277 c14 -11 26 -27 26 -35 0 -13 -4 -13 -28 2 -35 20
-59 54 -38 54 7 0 25 -9 40 -21z"/>
<path d="M4301 619 c-7 -74 -16 -158 -21 -186 -7 -50 -6 -53 15 -53 20 0 22 7
33 136 7 76 15 140 17 145 3 4 20 -6 37 -22 18 -16 38 -29 45 -29 9 0 12 -22
11 -82 -2 -99 16 -142 67 -164 67 -28 72 -23 79 69 4 45 11 123 17 174 12 96
9 123 -14 123 -10 0 -17 -35 -27 -142 -7 -79 -14 -148 -17 -154 -2 -7 -24 7
-50 32 l-45 44 7 60 c12 102 -26 167 -102 177 l-40 5 -12 -133z m87 95 c25
-17 57 -73 47 -82 -10 -10 -38 12 -62 48 -35 50 -28 65 15 34z m117 -281 c29
-43 31 -53 11 -53 -19 0 -56 47 -56 71 0 30 17 22 45 -18z"/>
<path d="M5247 736 c-90 -33 -163 -108 -187 -196 -12 -42 -13 -190 -2 -190 4
0 6 18 4 39 -8 83 38 151 102 151 28 0 33 -5 46 -42 12 -35 23 -47 57 -63 26
-12 43 -27 45 -40 2 -12 12 -21 27 -23 22 -3 23 -1 17 35 -10 67 -26 244 -26
294 0 44 -2 49 -22 49 -13 -1 -40 -7 -61 -14z m37 -68 c13 -109 16 -148 12
-148 -3 0 -21 9 -40 21 -37 21 -107 23 -149 4 -18 -7 -18 -6 1 31 25 49 76 98
122 118 48 21 48 21 54 -26z m-33 -151 c21 -17 53 -66 46 -73 -7 -8 -49 15
-64 35 -14 18 -17 51 -5 51 4 0 14 -6 23 -13z"/>
<path d="M4735 700 c-16 -6 -40 -8 -52 -5 -16 4 -23 1 -23 -9 0 -8 -3 -22 -6
-31 -5 -13 -1 -12 28 5 19 11 55 24 81 29 l46 8 7 -43 c3 -25 5 -105 2 -179
-4 -135 -4 -135 19 -135 l23 0 0 175 0 175 38 0 c21 0 56 -11 81 -25 33 -19
42 -21 37 -9 -3 9 -6 21 -6 28 0 7 -12 14 -27 14 -16 1 -71 3 -123 6 -52 3
-108 1 -125 -4z"/>
</g>

    </svg>
</template>

<script>
export default {
    name: "PrimaryLogo",
};
</script>

<style lang="scss" scoped>
.primary-logo {
    width: 126px;
    height: 126px;

    fill: rgb(0, 0, 0);
}
</style>
