<template>
    <div class="flex flex--100 update-product-category-visibility-modal-box">
        <modal-box ref="window" @hide="clear" :close-on-background-click="false" :append-to-body="true">
            <form class="flex flex--100">
                <div class="flex flex--100 flex--x-align-center no-categories" v-if="categories.length === 0">
                    <span>This product has no categories.</span>
                </div>
                <div v-else class="flex flex--100 categories-list">
                    <restaurant-category
                        v-for="categoryDescriptor of filteredCategories"
                        :key="categoryDescriptor.category.id"
                        :category="categoryDescriptor.category"
                        :show-editor="false"
                        :selectable="true"
                        :selected="selectedCategory?.category.id === categoryDescriptor.category.id"
                        @update:selected="onCategorySelect(categoryDescriptor)"
                    />
                </div>
                <div v-if="selectedCategory" class="flex flex--100 update-form">
                    <hr class="separator"/>
                    <div class="flex flex--100 update-form-options">
                        <div class="flex flex--100 flex--y-align-center">
                            <simple-textbox v-model:model-value="index" ref="indexTextbox" :placeholder="$t('management.restaurantProductManagementTab.index')"/>
                            <span class="input-descriptor-text">{{ $t("management.restaurantProductManagementTab.indexRule") }}</span>
                        </div>
                        <div class="flex flex--100 flex--y-align-center">
                            <span class="input-descriptor-text">{{ $t("management.restaurantProductManagementTab.visible") }}</span>
                            <simple-checkbox ref="visible" appearance="slider" v-model:model-value="visible"/>
                        </div>
                        <div class="flex flex--100 flex--y-align-center">
                            <span class="input-descriptor-text">{{ $t("management.restaurantProductManagementTab.excludeFromTopSeller") }}</span>
                            <simple-checkbox ref="excludeFromTopSeller" appearance="slider" v-model:model-value="excludeFromTopSeller" />
                        </div>
                    </div>
                    <hr class="separator">
                    <div class="flex flex--100 flex--y-align-end update-form-button">
                        <simple-button :is-loading="isWaitingServerResponseForUpdate" :is-disabled="!dataChanged" text="Update" @click="update"/>
                    </div>
                </div>
            </form>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import {RestaurantProcessor} from "@/utilities";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
import RestaurantCategory from "@/components/RestaurantCategory";
import SimpleButton from "@/components/inputs/SimpleButton";
import {user} from "@/user";
import {notificationCenter} from "@/components/utilities/NotificationCenter";

export default {
    name: "UpdateProductCategoryVisibilityModalBox",
    components: {
        RestaurantCategory,
        SimpleTextbox,
        ModalBox,
        SimpleCheckbox,
        SimpleButton,
    },
    props: {
        categories: {
            type: Array,
        }
    },
    data () {
        return {
            product: undefined,
            index: "",
            visible: false,
            excludeFromTopSeller: false,
            selectedCategory: null,
            isWaitingServerResponseForUpdate: false,
        }
    },
    computed: {
        window () {
            return this.$refs.window;
        },

        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        filteredCategories () {
            const filteredCategories = [];
            if (this.product !== undefined) {
                for (const categoryOption of this.product.categories) {
                    filteredCategories.push({
                        category: this.categories.find((category) => category.id === categoryOption.id),
                        index: String(categoryOption.index),
                        visible: Boolean(categoryOption.visible),
                        excludeFromTopSeller: Boolean(categoryOption.excludeFromTopSeller),
                    });
                }
            }
            return filteredCategories;
        },

        dataChanged () {
            return Number.parseInt(this.index) !== this.selectedCategory.index ||
                !this.visible !== !this.selectedCategory.visible ||
                !this.excludeFromTopSeller !== !this.selectedCategory.excludeFromTopSeller; // Cast Object in Boolean to compare their value
        }
    },
    methods: {
        clear () {
            this.selectedCategory = null;
        },

        load (product) {
            this.product = product;
            if (this.filteredCategories.length > 0) {
                this.selectedCategory = this.filteredCategories[0];
                this.index = String(this.selectedCategory.index);
                this.visible = Boolean(this.selectedCategory.visible);
            }
        },

        onCategorySelect (categoryDescriptor) {
            this.selectedCategory = categoryDescriptor;
            this.index = this.selectedCategory.index;
            this.visible = this.selectedCategory.visible;
            this.excludeFromTopSeller = this.selectedCategory.excludeFromTopSeller;
        },

        async update () {
            if (this.isWaitingServerResponseForUpdate) {
                return;
            }

            this.isWaitingServerResponseForUpdate = true;

            try {
                const response = await user.updateProductCategoryVisibilityAndIndex({
                    categoryId: this.selectedCategory.category.id,
                    productId: this.product.id,
                    index: Number.parseInt(this.index),
                    visible: this.visible,
                    excludeFromTopSeller: this.excludeFromTopSeller,
                });

                if (response.status === "ok") {
                    this.selectedCategory.index = this.index;
                    this.selectedCategory.visible = this.visible;
                    this.selectedCategory.excludeFromTopSeller = this.excludeFromTopSeller;
                    this.window.hide();
                    this.clear();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.productUpdatedSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.isWaitingServerResponseForUpdate = false;
        }
    },
}
</script>

<style lang="scss" scoped>

.categories-list {
    margin: 50px;
    background-color: rgb(245, 245, 245);
    border-radius: 12px;
    box-shadow: inset 0 0 40px 2px rgba(0, 0, 0, 0.01);

    .restaurant-category {
        margin: 24px;
    }
}

.separator {
    width: 100%;
    height: 1px;
    margin: 45px 0;
    background-color: rgb(231, 231, 231);
}

.input-descriptor-text {
    margin: 10px 20px;

    font-weight: 400;
    font-size: 14px;
}

.update-form {
    padding: 0 50px 50px 50px;
}

.no-categories {
    font-size: 20px;
    font-weight: 500;
    color: rgb(80, 80, 80);
}

.update-form-button {
}

.update-product-category-visibility-modal-box {
    .modal-box .modal-window__box {
        background-color: rgb(255, 255, 255);
    }

    .update-form .simple-textbox {
        margin: 17px 0;
        width: 40%;
    }
}
</style>
