<template>
    <div class="flex flex--100 allergen-selector">
        <div class="flex flex--100">
            <simple-button :text="selectButtonText" @click="showAllergenModalBox"/>
        </div>
        <modal-box ref="modalBox" :append-to-body="true">
            <div class="flex flex--100 allergen-list">
                <restaurant-allergen
                    v-for="allergen in allergens"
                    :key="allergen.id"
                    :allergen="allergen"
                    :selectable="true"
                    v-model:selected="selectedAllergens[allergen.id]"
                />
            </div>
        </modal-box>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import RestaurantAllergen from "@/components/RestaurantAllergen";

export default {
    name: "AllergenSelector",
    components: {
        ModalBox,
        SimpleButton,
        RestaurantAllergen,
    },
    props: {
        allergens: {
            type: Array,
        },
        selected: {
            type: Array,
            default: () => {},
        },
        max: {
            type: Number,
            default: -1,
        },
    },
    emits: [ "update:selected", ],
    data () {
        return {
            selectedAllergens: this.selected.reduce((selected, id) => {
                selected[id] = true;
                return selected;
            }, {}),
        };
    },
    methods: {
        getAllergenModifiers (allergen) {
            return {
                "allergen--selected": this.selected.includes(allergen.id),
            };
        },

        showAllergenModalBox () {
            this.$refs.modalBox.show();
        },

        toggleSelection (allergen) {
            const selectedCopy = JSON.parse(JSON.stringify(this.selected));
            const selectedIndex = this.selected.indexOf(allergen.id);
            if (selectedIndex !== -1) {
                selectedCopy.splice(selectedIndex, 1);
            }
            else if (this.max === -1 || this.selected.length < this.max) {
                selectedCopy.push(allergen.id);
            }
            this.$emit("update:selected", selectedCopy);
        },
    },
    computed: {
        normalizedSelectedCategories () {
            return Object.keys(this.selectedAllergens).filter((id) => this.selectedAllergens[id] === true);
        },

        selectButtonText () {
            let text;
            if (this.max === 1) {
                text = "Select allergen";
            }
            else {
                text = "Select allergens";
            }
            if (this.selected.length > 0) {
                text = `${text} (${this.normalizedSelectedCategories.length})`;
            }
            return text;
        },
    },
    watch: {
        normalizedSelectedCategories: {
            deep: true,
            handler (value) {
                this.$emit("update:selected", value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.restaurant-allergen {
    margin: 24px;
}
.modal-box {
    :deep(.modal-box__slot) {
        height: auto;
        min-height: initial;
    }
}
</style>
