<template>
    <div class="flex flex--100 user-tab" v-if="restaurant">
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.generic.restaurant`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--direction-column">
                <div class="flex flex--60 flex--y-align-center item">
                    <div class="flex flex--40">
                        <span class="item__key">{{$t(`management.generic.name`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--60 flex--x-align-end">
                        <simple-textbox v-model:model-value="restaurant.name" :is-disabled="true" class="item__value"/>
                    </div>
                </div>
                <div class="flex flex--60 flex--y-align-center item">
                    <div class="flex flex--40">
                        <span class="item__key">{{$t(`management.generic.address`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--60 flex--x-align-end">
                        <simple-textbox v-model:model-value="restaurant.address" :is-disabled="true" class="item__value"/>
                    </div>
                </div>
                <div class="flex flex--60 flex--y-align-center item">
                    <div class="flex flex--40">
                        <span class="item__key">{{$t(`management.generic.country`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--60 flex--x-align-end">
                        <simple-textbox v-model:model-value="restaurant.countryIso" :is-disabled="true" class="item__value"/>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 separator">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.generic.actions`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--direction-column">
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.generic.open`).toLocaleUpperCase()}} / {{$t(`management.generic.close`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-checkbox @update:modelValue="onRestaurantOpenClose" :disabled="restaurantOpenCloseLoading" appearance="slider" text="" :model-value="Boolean(restaurant.isOpen)"/>
                    </div>
                </div>
            </div>
            <div class="flex flex--100 separator">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.generic.configuration`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100 flex--direction-column">
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.websiteOrder`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-checkbox :disabled="websiteOrderEnabledLoading" @update:modelValue="onWebsiteOrderEnabledChanged" appearance="slider" text="" :model-value="Boolean(restaurant.configuration.websiteOrderEnabled)"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.kioskEnabled`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-checkbox :disabled="kioskEnabledLoading" @update:modelValue="onKioskEnabledChanged" appearance="slider" text="" :model-value="Boolean(restaurant.configuration.kioskEnabled)"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.paymentAfterService`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-checkbox :disabled="paymentAfterServiceLoading"  @update:modelValue="onPaymentAfterServiceChanged" appearance="slider" text="" :model-value="Boolean(restaurant.configuration.paymentAfterService)"/>
                    </div>
                </div>
                <div class="flex flex--40 flex--y-align-center item">
                    <div class="flex flex--50">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.skipDeliveryState`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--50 flex--x-align-end">
                        <simple-checkbox :disabled="skipDeliveryStateLoading"  @update:modelValue="onSkipDeliveryStateChanged" appearance="slider" text="" :model-value="Boolean(restaurant.configuration.skipDeliveryState)"/>
                    </div>
                </div>
                <div class="flex flex--60 flex--y-align-center item">
                    <div class="flex flex--40">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.maxDeliveryDistance`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--30">
                        <simple-textbox ref="maxDeliveryDistance" placeholder="UM: METER (> 0)" v-model:model-value="restaurant.configuration.maxDeliveryDistance" class="item__value"/>
                    </div>
                    <div class="flex flex--30 flex--x-align-end">
                        <simple-button @click="onMaxDeliveryDistanceConfirm" :is-loading="isWaitingServerResponse" :text="$t(`management.restaurantManagementTab.saveDistance`).toLocaleUpperCase()" />
                    </div>
                </div>
                <div class="flex flex--60 flex--y-align-center item">
                    <div class="flex flex--40">
                        <span class="item__key">{{$t(`management.restaurantManagementTab.cover`).toLocaleUpperCase()}}</span>
                    </div>
                    <div class="flex flex--30">
                        <simple-textbox ref="cover" placeholder="EUR €" v-model:model-value="restaurant.configuration.cover" class="item__value"/>
                    </div>
                    <div class="flex flex--30 flex--x-align-end">
                        <simple-button @click="onCoverConfirm" :is-loading="isWaitingServerResponse" :text="$t(`management.restaurantManagementTab.saveCover`).toLocaleUpperCase()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.restaurantManagementTab.pointOfWork`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100">
                <a :href="posLocation" target="_blank" class="flex flex--y-align-center point-of-work">POS</a>
                <a :href="pocLocation" target="_blank" class="flex flex--y-align-center point-of-work">POC</a>
                <a :href="podLocation" target="_blank" class="flex flex--y-align-center point-of-work">POD</a>
            </div>
        </div>
        <div class="flex flex--100 box">
            <div class="flex flex--100 separator separator--no-margin-top">
                <hr class="flex flex--100 separator__bar"/>
                <span class="separator__text">{{$t(`management.restaurantManagementTab.customCSS`).toLocaleUpperCase()}}</span>
            </div>
            <div class="flex flex--100">
                <prism-editor class="custom-css-textbox" v-model:model-value="restaurant.configuration.customStyle" :highlight="highlightCss"></prism-editor>
                <simple-button
                    class="custom-css-button"
                    :text="$t(`management.generic.update`).toLocaleUpperCase()"
                    :is-loading="isWaitingServerResponse"
                    @click="updateConfigurationCustomStyle"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";

import SimpleCheckbox from "@/components/inputs/SimpleCheckbox";
import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleButton from "@/components/inputs/SimpleButton";
import { user } from "@/user";
import { server } from "@/server";
import {notificationCenter} from "@/components/utilities/NotificationCenter";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-css";
import "prismjs/themes/prism-tomorrow.css";

export default {
    name: "RestaurantManagementTab",
    components: {
        SimpleCheckbox,
        SimpleTextbox,
        SimpleButton,
        PrismEditor,
    },
    data () {
        return {
            restaurantId: null,
            restaurant: null,
            isWaitingServerResponse: false,
            websiteOrderEnabledLoading: false,
            paymentAfterServiceLoading: false,
            skipDeliveryStateLoading: false,
            kioskEnabledLoading: false,
            restaurantOpenCloseLoading: false,
        };
    },
    computed: {
        posLocation () {
            return `/restaurant/${this.restaurantId}/pos`;
        },

        pocLocation () {
            return `/restaurant/${this.restaurantId}/poc`;
        },

        podLocation () {
            return `/restaurant/${this.restaurantId}/pod`;
        },
    },
    methods: {
        async onRestaurantOpenClose (value) {
            if (this.restaurantOpenCloseLoading) {
                return;
            }

            this.restaurantOpenCloseLoading = true;

            try {
                if (value) {
                    const restaurant = await user.openRestaurant({ id: this.restaurantId});
                    if (restaurant) {
                        this.restaurant.isOpen = !!restaurant.isOpen;
                        notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.restaurantOpenSuccess`), });
                    }
                    else {
                        notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                    }
                }
                else {
                    const restaurant = await user.closeRestaurant({ id: this.restaurantId});
                    if (restaurant) {
                        this.restaurant.isOpen = !!restaurant.isOpen;
                        notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.restaurantCloseSuccess`), });
                    }
                    else {
                        notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                    }
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.restaurantOpenCloseLoading = false;
        },

        highlightCss (code) {
            return highlight(code, languages.css, "css");
        },

        async load (restaurantId) {
            this.restaurantId = restaurantId;
            this.restaurant = await server.getRestaurant(restaurantId);

            const configuration = await user.setConfiguration({ id: this.restaurantId, });

            this.restaurant.configuration = {
                websiteOrderEnabled: !!configuration.websiteOrderEnabled,
                paymentAfterService: !!configuration.paymentAfterService,
                skipDeliveryState: !!configuration.skipDeliveryState,
                kioskEnabled: !!configuration.kioskEnabled,
                maxDeliveryDistance: configuration.maxDeliveryDistance.toString(),
                customStyle: configuration.customStyle,
                cover: configuration.cover?.toString() ?? "",
            };
        },

        async onWebsiteOrderEnabledChanged (value) {
            if (this.websiteOrderEnabledLoading) {
                return;
            }

            this.websiteOrderEnabledLoading = true;

            try {
              const configuration = await user.setConfiguration({id: this.restaurantId, websiteOrderEnabled: value});
              if (value) {
                  if (configuration) {
                    this.restaurant.configuration.websiteOrderEnabled = !!configuration.websiteOrderEnabled;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.websiteOrderEnabledSuccess`),});
                  } else {
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
              else {
                  if (configuration) {
                    this.restaurant.configuration.websiteOrderEnabled = !!configuration.websiteOrderEnabled;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.websiteOrderDisabledSuccess`),});
                  } else {
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
            this.websiteOrderEnabledLoading = false;
        },

        async onPaymentAfterServiceChanged (value) {
            if (this.paymentAfterServiceLoading) {
                return;
            }

            this.paymentAfterServiceLoading = true;

            try {
              const configuration = await user.setConfiguration({id: this.restaurantId, paymentAfterService: value});
              if (value) {
                  if (configuration) {
                    this.restaurant.configuration.paymentAfterService = !!configuration.paymentAfterService;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.paymentAfterServiceEnabledSuccess`),});
                  } else {
                    this.restaurant.configuration.paymentAfterService = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
              else {
                  if (configuration) {
                    this.restaurant.configuration.paymentAfterService = !!configuration.paymentAfterService;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.paymentAfterServiceDisabledSuccess`),});
                  } else {
                    this.restaurant.configuration.paymentAfterService = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
            }
            catch (error) {
                this.restaurant.configuration.paymentAfterService = !value;
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.paymentAfterServiceLoading = false;
        },

        async onKioskEnabledChanged (value) {
            if (this.kioskEnabledLoading) {
                return;
            }

            this.kioskEnabledLoading = true;

            try {
              const configuration = await user.setConfiguration({id: this.restaurantId, kioskEnabled: value});
              if (value) {
                  if (configuration) {
                    this.restaurant.configuration.kioskEnabled = !!configuration.kioskEnabled;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.kioskEnabledSuccess`),});
                  } else {
                    this.restaurant.configuration.kioskEnabled = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
              else {
                  if (configuration) {
                    this.restaurant.configuration.kioskEnabled = !!configuration.kioskEnabled;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.kioskDisabledSuccess`),});
                  } else {
                    this.restaurant.configuration.kioskEnabled = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
            }
            catch (error) {
                this.restaurant.configuration.kioskEnabled = !value;
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.kioskEnabledLoading = false;
        },

        async onMaxDeliveryDistanceConfirm () {
            const value = this.restaurant.configuration.maxDeliveryDistance;
            if (this.isWaitingServerResponse && value !== "" && Number.parseInt(value) > 0) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const configuration = await user.setConfiguration({ id: this.restaurantId, maxDeliveryDistance: value});

                if (configuration) {
                    this.restaurant.configuration.maxDeliveryDistance = configuration.maxDeliveryDistance.toString();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.maxDeliveryDistanceChangeSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }

            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },

        async onCoverConfirm () {
            const value = this.restaurant.configuration.cover;
            if (this.isWaitingServerResponse && value !== "" && Number.parseFloat(value) > 0) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const configuration = await user.setConfiguration({ id: this.restaurantId, cover: value});

                if (configuration) {
                    this.restaurant.configuration.cover = configuration.cover.toString();
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.coverChangeSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }

            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },

        async onSkipDeliveryStateChanged (value) {
            if (this.skipDeliveryStateLoading) {
                return;
            }

            this.skipDeliveryStateLoading = true;
            try {
              const configuration = await user.setConfiguration({id: this.restaurantId, skipDeliveryState: value});
              if (value) {
                  if (configuration) {
                    this.restaurant.configuration.skipDeliveryState = !!configuration.skipDeliveryState;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.skipDeliveryStateEnabledSuccess`),});
                  } else {
                    this.restaurant.configuration.paymentAfterService = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
              else {
                  if (configuration) {
                    this.restaurant.configuration.skipDeliveryState = !!configuration.skipDeliveryState;
                    notificationCenter?.sendSuccessNotification({text: this.$t(`notification.skipDeliveryStateDisabledSuccess`),});
                  } else {
                    this.restaurant.configuration.paymentAfterService = !value;
                    notificationCenter?.sendFailureNotification({text: this.$t(`notification.serverError`),});
                  }
              }
            }
            catch (error) {
                this.restaurant.configuration.skipDeliveryState = !value;
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.skipDeliveryStateLoading = false;
        },

        async updateConfigurationCustomStyle () {
            if (this.isWaitingServerResponse) {
                return;
            }

            this.isWaitingServerResponse = true;

            try {
                const configuration = await user.setConfiguration({ id: this.restaurantId, customStyle: this.restaurant.configuration.customStyle, });

                if (configuration) {
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.customStyleChangeSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }

            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }

            this.isWaitingServerResponse = false;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/primary-user-navigation-tab.scss";

.point-of-work {
    padding: 12px 20px;

    background-color: rgba(64, 102, 255, 0.1);
    border-radius: 6px;

    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    color: rgb(64, 102, 255);

    & + & {
        margin-left: 24px;
    }

    &:hover {
        background-color: rgba(64, 102, 255, 0.18);
    }
}

.custom-css-button {
    margin: 20px 0;
}

.custom-css-textbox {
    width: 100%;

    padding: 20px;

    background-color: rgb(240, 240, 240);
    border-radius: 6px;

    :deep(*) {
        font-family: "Fira code", "Fira Mono", "Consolas", "Menlo", "Courier", "monospace";
        font-size: 14px;
        line-height: 1.5;
    }
}

.simple-textbox {
    width: 90%;
}
</style>
