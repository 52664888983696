<template>
    <div class="flex flex--100 mobile-home-view">
        <div class="flex flex--100 flex--x-align-center flex--y-align-center header">
            <primary-logo/>
            <div class="flex flex--100 flex--x-align-center">
                <h2 class="flex flex--100 flex--x-align-center restaurant-name">
                     FANTASTIC GARDEN
                </h2>
                <span class="flex flex--100 flex--x-align-center restaurant-address">{{ restaurant.address }}</span>
                <span class="flex flex--100 flex--x-align-center restaurant-address">Cel. +(39) 377 389 5001</span>
                <span class="flex flex--100 flex--x-align-center restaurant-address">Tel. +(39) 055 398 5001</span>
                <div class="flex flex--100 flex--x-align-center restaurant-social">
                    <a href="https://www.facebook.com/profile.php?id=100087769871395" class="restaurant-social__link">
                        <svg class="restaurant-social__icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                            <path d="M25,3C12.85,3,3,12.85,3,25c0,11.03,8.125,20.137,18.712,21.728V30.831h-5.443v-5.783h5.443v-3.848 c0-6.371,3.104-9.168,8.399-9.168c2.536,0,3.877,0.188,4.512,0.274v5.048h-3.612c-2.248,0-3.033,2.131-3.033,4.533v3.161h6.588 l-0.894,5.783h-5.694v15.944C38.716,45.318,47,36.137,47,25C47,12.85,37.15,3,25,3z"></path>
                        </svg>
                    </a>
                    <a href="https://www.instagram.com/fantastic_garden_official/" class="restaurant-social__link">
                        <svg class="restaurant-social__icon" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100" height="100" viewBox="0 0 50 50">
                            <path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"></path>
                        </svg>
                    </a>
                </div>
            </div>
        </div>
        <div class="flex flex--100 flex--x-align-center flex--y-align-start main">
            <div class="flex flex--100 flex--x-align-center">
                <!-- <simple-textbox class="main__input" :placeholder="$t('generic.nickname')" v-model:model-value="nickname" ref="nicknameTextbox"/> -->
                <simple-selectbox
                    class="guests-selectbox"
                    ref="guestsSelectbox"
                    :options="guestsSelectboxOptions"
                    :can-search-options="false"
                    @options-load="onGuestsSelectboxOptionsLoad"
                    :select-placeholder="$t('generic.guests')"
                    :can-select-empty-value="false"
                    :set-options-later="true"
                    v-model:model-value="requestedPersons"
                />
            </div>
            <div class="flex flex--100 flex--x-align-center">
                <simple-button class="flex flex--100 main__btn" :text="$t('homepage.orderNow')" :is-disabled="requestedPersons.length === 0" @click="toOrderView"></simple-button>
            </div>
        </div>
        <modal-box class="rescan" ref="errorModalBox" :show-close-button="false" :close-on-background-click="false">
            <div class="rescan__box flex flex--100 flex--x-align-center flex--y-align-center">
                <h2 class="rescan__text">{{ $t("generic.rescan") }}</h2>
            </div>
        </modal-box>
    </div>
</template>

<script>
import SimpleSelectbox from "@/components/inputs/SimpleSelectbox";
// import SimpleTextbox from "@/components/inputs/SimpleTextbox";
import SimpleButton from "@/components/inputs/SimpleButton";
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import { server, } from "@/server";
import { MobileViewportSimulator, } from "@/utilities";
import ModalBox from "@/components/containers/ModalBox";

const preloadedData = {};
let hasPreloadedData = false;
let tableNumber = null;

export default {
    async beforeRouteEnter (to, from, next) {
        if (!hasPreloadedData) {
            preloadedData.restaurant = await server.getRestaurant(6);
            hasPreloadedData = true;
        }

        if (to.query.tableNumber) {
            tableNumber = to.query.tableNumber;
        }

        next();
    },

    name: "MobileHomeView",
    components: {
        // SimpleTextbox,
        SimpleSelectbox,
        SimpleButton,
        PrimaryLogo,
        ModalBox,
    },
    data () {
        return {
            nickname: "",
            requestedPersons: "",
            tableNumber,
        };
    },
    computed: {
        restaurant () {
            return preloadedData.restaurant;
        },

        restaurantId () {
            return this.restaurant.id;
        },

        orderViewLocation () {
            return `/place-order`;
        },

        guestsSelectboxOptions () {
            let options = [];

            for (let i = 1; i < 21; i++) {
                options = [ ...options, {
                    text: i.toString(),
                    value: i,
                }, ];
            }

            return options;
        },
    },
    methods: {
        saveInfo () {
            window.localStorage.setItem("FortuneRMS::QR", JSON.stringify({
                nickname: this.nickname.length === 0 ? "Guest" + Math.floor(100000 + Math.random() * 900000) : this.nickname,
                tableNumber: this.tableNumber,
                requestedPersons: this.requestedPersons,
            }));
        },

        toOrderView () {
            if (!this.tableNumber || this.tableNumber.length === 0) {
                this.$refs.errorModalBox.show();
                return;
            }
            this.saveInfo();
            this.$router.push({
                path: this.orderViewLocation,
            });
        },

        onGuestsSelectboxOptionsLoad () {
            // const firstOption = this.sceneSelectboxOptions[0];
            // const selectedScene = this.selectedScene || firstOption?.value;
            // if (firstOption) {
            //     this.$refs.sceneSelectbox.selectOptionByValue(String(selectedScene));
            // }
            // else {
            //     this.selectedScene = String(selectedScene);
            // }
        },
    },
    mounted () {
        MobileViewportSimulator.activate();
        MobileViewportSimulator.update();
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";
@import "~@/css/flex.scss";

.mobile-home-view {
    overflow: hidden;

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
}

.header {
    overflow: hidden;

    height: 50%;

    background-color: rgb(251, 243, 225);
}

.main {
    overflow: hidden;
    cursor: pointer;

    height: 50%;

    background-color: rgb(251, 243, 225);

    &:active > .order-button {
        transform: scale(0.9);
    }

    &__input {
        margin: 22px 12px 0 12px;
        padding: 0;

        width: 300px;
    }

    &__btn {
        margin-top: 36px;

        width: 300px;
    }

    :deep(.simple-textbox) {
        background-color: rgb(251, 243, 225)!important;

        &__text-input {
            border-color: rgb(29, 24, 24)!important;
            border-radius: 0;
            background-color: rgb(251, 243, 225)!important;
        }

        &--focused .label__box,
        &--not-empty .label__box {
            background-color: rgb(251, 243, 225)!important;
        }
    }

    :deep(.simple-button) {
        background-color: rgb(79, 63, 32)!important;

        border-radius: 0;
    }
}

.primary-logo {
    width: 154px;
    height: auto;

    fill: rgb(255, 255, 255);
}

.restaurant-name {
    margin: 22px 12px 0 12px;
    padding: 0;

    text-align: center;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    color: rgb(97, 59, 22);
}

.restaurant-address {
    margin: 5px 12px 0 5px;
    padding: 0;

    text-align: center;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    color: rgb(158, 106, 34);
}

.restaurant-social {
    margin: 8px 15px 0 15px;

    &__link {
        margin: 0 8px;
        text-decoration: none;
        color: rgb(158, 106, 34);
    }

    &__icon {
        width: 28px;
        height: 28px;
        fill: currentColor;
        color: currentColor;
    }
}

.rescan {
    overflow: hidden;

    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);

    background-color: rgb(251, 243, 225)!important;

    :deep(.modal-box) {
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
    }

    :deep(.modal-box__slot) {
        width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        margin: 0 0 0 0;
        margin: calc(var(--vh, 1vh) * 0) 0 0 0;
        padding: 0;

        border-radius: 0;
    }

    &__box {
        background-color: rgb(251, 243, 225);
        height: 100%;
    }

    &__text {
        text-align: center;
        font-size: 26px;
        font-weight: 600;
        letter-spacing: 1px;
        color: rgb(97, 59, 22);
    }
}

.guests-selectbox {
    margin-top: 24px;
    width: 300px;
}
</style>
