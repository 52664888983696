export const zh = {
  brandName: "餃姣",
  generic: {
    totalItem: "总数",
    totalPage: "总页数",
    add: "添加",
    cash: "现金",
    cashier: "收银台",
    order: "订单",
    card: "刷卡",
    thirdParty: "第三方",
    cancel: "取消",
    clear: "清除",
    continue: "继续",
    confirm: "确认",
    selectPaymentMethod: "选择付款方式",
    products: "产品",
    rest: "找零",
    date: "日期",
    takeAway: "打包",
    exit: "关闭",
    back: "返回",
    close: "关闭",
    table: "桌",
    times: "时间",
    today: "今天",
    print: "打印",
    notApplicable: "N/A",
    paymentThroughCashier: "点击前往收银台付款",
    paymentLater: "先用餐，后结账",
    paymentThroughWeChatPay: "点击使用微信支付",
    invalidDate: "日期不可选取",
    hasMissingChoices: "还差一样 | 还需要选择{choices}样",
    press: "点击",
    toShowAllergenTab: "查看菜品过敏原",
    popular: "最受欢迎",
    update: "更新",
    unavailable: "售空",
    chooseScene: "选择场景",
    allScene: "全场景",
    save: "保存",
    cover: "座位费人数",
    paid: "已付款",
    openFileBrowser: "打开文件目录",
    nickname: "昵称",
    rescan: "连接超时，请重新扫描二维码",
    guests: "用餐人数",
    payFirst: "如需多次下单，请不要提前付款",
  },
  notification: {
    networkError: "网络异常。",
    serverError: "内部错误。",
    productAddedSuccess: "产品添加成功。",
    productRemoveSuccess: "产品删除成功。",
    productRemoveFail: "产品移除失败，已销售产品不可移除。",
    productArchivingSuccess: "产品归档成功。",
    productUnarchivingSuccess: "产品取消归档成功。",
    productUpdatedSuccess: "产品更新成功。",
    productStockChangedSuccess: "产品库存更新成功。",
    restaurantOpenSuccess: "餐厅开门成功。",
    restaurantCloseSuccess: "餐厅关门成功。",
    websiteOrderEnabledSuccess: "网页点单系统启动成功。",
    websiteOrderDisabledSuccess: "网页点单系统关闭成功。",
    paymentAfterServiceEnabledSuccess: "先服务后结账启动成功。",
    paymentAfterServiceDisabledSuccess: "先服务后结账关闭成功。",
    kioskEnabledSuccess: "点餐机后台启动成功。",
    kioskDisabledSuccess: "点餐机后台关闭成功。",
    maxDeliveryDistanceChangeSuccess: "配送距离修改成功。",
    coverChangeSuccess: "座位费修改成功。",
    skipDeliveryStateEnabledSuccess: "跳过配送步骤启动成功。",
    skipDeliveryStateDisabledSuccess: "跳过配送步骤关闭成功。",
    customStyleChangeSuccess: "自定义样式修改成功。",
    passwordChangeSuccess: "密码修改成功。",
    passwordChangeFail: "密码修改失败。",
    allergenAddedSuccess: "过敏原添加成功。",
    allergenUpdatedSuccess: "过敏原更新成功。",
    allergenDeletedSuccess: "过敏原删除成功。",
    categoryAddedSuccess: "类别添加成功。",
    categoryUpdatedSuccess: "类别更新成功。",
    tableAddedSuccess: "餐桌添加成功。",
    tableUpdatedSuccess: "餐桌修改成功。",
    tableRemovedSuccess: "餐桌删除成功。",
    variationAddedSuccess: "变化添加成功。",
    variationUpdatedSuccess: "变化更新成功。",
    variationArchivedSuccess: "变化归档成功。",
    variationUnarchivedSuccess: "变化取消归档成功。",
    skipErrorOrders: "个订单未能成功读取。详细信息请查看控制台。",
  },
  error: {
    wrongPassword: "密码错误。",
    fileSizeLimitExceeded:
      "文件上传超限, 最大支持 { size } kb，请压缩后再尝试。",
  },
  customerBasket: {
    subtotal: "合计",
    discount: "折扣",
    total: "总计",
    emptyBasket: "您的篮子是空的",
  },
  productList: {
    searchBarPlaceholder: "搜索",
    allergenList: "过敏原列表:",
    productHasNoAllergens: "菜品没有过敏原。",
    description: "详情",
  },
  cashierPos: {
    activeOrders: "订单",
    tables: "座位",
    orderId: "订单号",
    bindOrder: "绑定",
    freeTable: "清桌",
    addProduct: "添加",
    toPay: "支付",
    ordersToPay: "待付款的订单",
    ordersInPreparation: "准备中的订单",
    ordersInDelivery: "派送中的订单",
    completedOrders: "已完成的订单",
    confirmAfterPayment: "仅在支付全额订单金额后才按下此按钮。",
    select: "选择",
    maps: "显示座位图",
  },
  kitchen: {
    noOrdersToPrepare: "没有订单",
    completedOrders: "已完成的订单",
    stock: "库存",
  },
  pod: {
    dailyOrders: "当日订单",
  },
  kiosk: {
    touchToOrder: "触摸订餐",
  },
  checkout: {
    orderReceiveNowWait: "您将很快收到您的订单,如有任何问题,请致电我们",
    weAreWaitingForYou: "我们正在等你!",
    whereToPay: "支付方式",
    takeAway: "打包",
    preOrder: "预定",
    completePaymentToCashier: "前往收银台完成付款",
    message: "备注",
    productMessage: "菜品备注",
    categoryTimes: "安排上菜时间",
    timeToWait: "您想等待多久?",
    checkoutDeliveryErrorType: {
      MISSING_STREET: "请输入地址。",
      MISSING_BUILDING_CODE: "请输入门牌号。",
      MISSING_POSTAL_CODE: "请输入邮编。",
      INCORRECT_POSTAL_CODE: "请检查邮编格式",
      MISSING_FULL_NAME: "请输入姓名。",
      MISSING_MOBILE_PHONE: "请输入电话号码。",
      MISSING_PERSONS: "请输入人数。",
      UNSUPPORTED_ZONE: "您所在地区暂不支持配送。",
      MESSAGE_LENGTH_OVERFLOW: "已超出字数限制",
    },
  },
  order: {
    verifyDeliveryAddress: "验证送货到您的地址",
    suggestedSpecialRequests: ["分餐", "打包"],
    uniqueCode: "唯一代码",
    paymentDate: "支付日期",
    deliveryDate: "交餐日期",
    origin: "订单来源",
    originMap: {
      0: "Web",
      1: "Kiosk",
      2: "收银员",
      3: "二维码",
      4: "第三方",
    },
    statusMap: {
      0: "等待支付",
      1: "在筹备",
      2: "已派送",
      3: "派送中",
      4: "已结束",
    },
    paymentMethod: "付款方法",
    paymentMethodMap: {
      0: "现金",
      1: "刷卡",
      2: "其他支付方式",
      3: "微信支付",
      4: "户户送",
      5: "呱呱到家",
      6: "Glovo",
      7: "优食",
      8: "电话呼叫",
      9: "Just Eat",
      10: "其他",
    },
    deliveryLocation: "交餐地点",
    deliveryLocationMap: {
      0: "餐厅",
      1: "打包",
      2: "送货地址",
    },
    order: "订单",
    discounts: "折扣",
    products: "产品",
    restaurant: "餐厅",
    total: "总计",
    subtotal: "合计",
    discount: "折扣",
    noOrdersToDeliver: "没有配送订单",
    lastOrder: "点击查看已下单",
    stateAlert: "您很快就会收到订单。",
    orderAgain: "新的订单",
    receiver: {
      address: "地址",
      name: "姓名",
      mobilePhone: "手机号",
    },
    table: "桌号",
    tableAbb: "桌号",
    additions: "添加费用",
    noOrders: "订单列表空空如也。",
    refresh: "刷新",
  },
  delivery: {
    checkAddress: "检查送货是否到达您的地址",
    checkLater: "稍后检查",
    check: "一探究竟",
    addressTooFar: "我们很抱歉，但送货没有到达指定的地址",
    addressReachable: "我们送货到您所在的地区！ 您可以立即订购",
    checkDifferentAddress: "检查另一个地址",
    deliveryToAddress: "派送到家",
    pickupInRestaurant: "到店自取",
    address: "地址",
    streetNumber: "门牌号",
    postalCode: "邮编",
    fullName: "姓名",
    phoneNumber: "电话号码",
    notes: "备注",
    timePlaceholder: "请选择到达时间",
    numberOfPeople: "人数",
    endOfDay: "今日已关门，请选择其他日期",
    pickDate: "请选择日期",
  },
  payment: {
    payNow: "现在付款",
    paidConfirmation: "我付了",
    notPaid: "你还没有付款",
  },
  management: {
    generic: {
      daily: "当日",
      weekly: "本周",
      monthly: "本月",
      restaurant: "餐厅",
      name: "名字",
      address: "地址",
      country: "国家",
      vat: "税",
      actions: "操作",
      open: "开",
      close: "关",
      configuration: "设置",
      update: "更新",
      missingValue: "必须填写。",
      invalidValue: "填写错误。",
      origin: "来源",
      amount: "数量",
      total: "总计",
      unitPrice: "单价",
      dailyStatistics: "当日数据",
      revenue: "收入",
      orderOrigin: ["网站", "点餐机", "前台", "二维码", "第三方"],
      export: "导出",
      cancel: "取消",
      import: "导入",
      selectAll: "全选",
      eventManagement: "活动管理",
      date: "日期",
      save: "保存",
      description: "简介",
      categories: "分类",
      price: "价格",
      previewImages: "照片",
      allergens: "过敏源",
      variations: "菜品选项",
    },
    primaryUserNavigation: {
      user: "用户",
      management: "管理",
      analytics: "分析",
      productsAnalytics: "菜品分析",
      orders: "订单",
      stock: "库存",
      content: "内容",
      products: "菜品",
      media: "媒体",
      categories: "分类",
      allergens: "过敏原",
      variations: "菜品选项",
      tables: "餐桌",
      events: "活动",
      scenes: "场景",
    },
    userTab: {
      username: "用户名",
      password: "密码",
      fullName: "全名",
      role: "职位",
      changePassword: "更改密码",
      oldPassword: "旧密码",
      newPassword: "新密码",
      logout: "登出",
      secure: "安全",
    },
    analyticsTab: {
      closedOrders: "已完成订单",
      unclosedOrders: "未完成订单",
      revenue: "收入",
      totRevenue: "总收入",
      revenueByDay: "收入图",
      averageOrders: "平均订单",
      averageDailyOrders: "平均订单数每日",
      averageOrderTotal: "平均订单收入",
      averageDailyProfit: "平均利润每日",
      averageDailyRevenue: "平均收入每日",
      ordersByDay: "订单图",
      ordersByOrigin: "订单（订单来源）",
      ordersByPaymentMethod: "订单（支付方式）",
      profitByPaymentMethod: "利润（支付方式）",
      originChartOptions: JSON.stringify({
        labels: [
          "网站",
          "点餐机",
          "收银台",
          "戶戶送",
          "呱呱到家",
          "Glovo",
          "优食",
          "电话呼叫",
          "微信",
          "Just Eat",
          "其他",
        ],
      }),
      paymentMethodChartOptions: JSON.stringify({
        labels: [
          "现金",
          "银行卡",
          "户户送",
          "呱呱到家",
          "Glovo",
          "优食",
          "电话呼叫",
          "微信",
          "Just Eat",
          "其他",
        ],
      }),
    },
    restaurantManagementTab: {
      websiteOrder: "线上订单",
      kioskEnabled: "点餐机",
      paymentAfterService: "用餐后付款",
      skipDeliveryState: "跳过配送",
      maxDeliveryDistance: "配送距离",
      pointOfWork: "工作点",
      customCSS: "自定义样式",
      saveDistance: "保存距离",
      cover: "座位费",
      saveCover: "保存座位费",
    },
    restaurantVariationsTab: {
      newVariation: "新变化",
      noVariations: "此餐厅没有变化数据。",
      active: "活跃变化",
      archived: "归档变化",
    },
    restaurantTableManagementTab: {
      newTable: "新餐桌",
      noTables: "此餐厅没有餐桌数据。",
      update: "修改",
      remove: "删除",
      seats: "座位 (人数)",
      name: "编号",
    },
    restaurantAllergenManagementTab: {
      newAllergen: "新过敏原",
      noAllergens: "此餐厅没有过敏原数据。",
    },
    restaurantCategoryManagementTab: {
      newCategory: "新分类",
      noCategories: "此餐厅没有分类。",
      modifyActiveCategory: "分类激活与排序",
    },
    restaurantProductManagementTab: {
      newProduct: "新产品",
      noProducts: "此餐厅没有产品。",
      active: "活跃产品",
      archived: "归档产品",
      visible: "点餐可见",
      indexRule: "数字越大，菜品越靠前, 默认为所选菜品类型的最后",
      index: "数值",
      visibility: "点餐可见",
      excludeFromTopSeller: "不参与最受欢迎排名",
      modify: "修改名称价格",
      archive: "归档",
      unarchive: "复原",
    },
    restaurantProductsStockManagementTab: {
      availableVolume: "数量",
      unavailable: "售空",
    },
    restaurantOrderListTab: {
      noOrders: "此餐厅没有订单数据。",
    },
    restaurantProductsAnalyticsTab: {
      noOrders: "此餐厅没有订单数据。",
    },
    restaurantSceneManagementTab: {
      title: "场景管理",
      list: "场景列表",
      newScene: "新场景",
      name: "名称",
      configuration: "配置",
      category: "分类",
      product: "菜品",
      fixedMenu: "套餐",
      customProduct: "自选菜品",
      table: "桌位",
      tableMap: "桌位图",
      active: "显示",
      disable: "隐藏",
      noScenes: "此餐厅没有场景。",
      noMaps: "此场景没有桌位图。",
      noProducts: "此餐厅没有菜品。",
      noCustomProducts: "此餐厅没有自选菜品。",
      noFixedMenus: "此餐厅没有套餐。",
      modify: "修改",
    },
  },
  homepage: {
    home: "首页",
    ourIdea: "关于我们",
    orderNow: "即刻点单",
    logIn: "登录",
    findOutMore: "开始探索",
    slogan: "体验为您设计的菜品",
    firstSectionTitle: "全新的外观。熟悉的味道。",
    firstSectionParagraph_1:
      "giaogiao via dei Servi 店的前身是佛罗伦萨甚至是整个托斯卡纳大区最古老的中餐厅，创立于1961年，当时的名字叫做《荷香园》《Fior di Loto》 。",
    firstSectionParagraph_2:
      "而六十年后的今天，giaogiao，一个新式中餐，坚守传统中餐美味配方，改进制作和出餐流程，用更智能化的设备和流程让中餐重焕青春。",
    firstSectionParagraph_3:
      "我们的愿景是：把有滋味的新式（智慧）中餐开遍全世界。",
    firstSectionParagraph_4:
      "我们的使命是：重新定义海外的中餐， 用科技赋能中餐， 让中餐成为真正的现代便捷美食。",
    firstSectionParagraph_5: "口号: 让每一个有文化的城市都有 giaogiao!",
    secondSectionTitle: "为什么叫 giaogiao",
    secondSectionParagraph_1: "我们要做海外中餐的佼佼者！",
    secondSectionParagraph_2: "更年轻（Giovani）的中餐文化传播者。",
    secondSectionParagraph_3: "Giao的缩写来自于下面四个英文单词",
    secondSectionParagraph_4: "Good 好吃",
    secondSectionParagraph_5: "Innovative 创新",
    secondSectionParagraph_6: "Authentic 正宗",
    secondSectionParagraph_7: "Original 原味",
    serviceTimesTitle: "营业时间",
    serviceTimes: "11:00 - 22:00 全天",
    homeDeliveryTitle: "送餐上门",
    homeDelivery: "5公里以内, 点击点单即刻查看",
    deliveryChargesTitle: "送餐费用",
    deliveryCharges: "1€/km",
    contact: "联系我们",
    addressesTitle: "地址",
    history: "历史",
    present: "今日",
    future: "未来",
    vision: "愿景",
    restaurantSectionTitle: "+3 餐厅",
    restaurantSectionParagraph: "还有更多，敬请期待",
    orderSectionTitle: "+20k 订单",
    orderSectionParagraph: "来自我们的自动系统",
  },
  restaurantList: {
    chooseRestaurant: "请选择用餐地点",
  },
  categoryTimes: {
    0: "马上要",
    10: "10分钟",
    20: "20分钟",
    30: "30分钟",
  },
};
